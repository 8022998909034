import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Image,
  Container,
  Tab,
  Nav,
  Button,
  Form,
  InputGroup,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import DeleteTheLinkModal from "./DeleteTheLinkModal";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  linkTreeSaveStart,
  linkTreeStatusUpdateStart,
} from "../../store/slices/LinkTreeSlice";

const AddLinkCard = ({ link }) => {
  const dispatch = useDispatch();
  const linkSave = useSelector((state) => state.linkTree.linkSave);
  const linkStatus = useSelector((state) => state.linkTree.linkStatus);

  const [deleteTheLink, setDeleteTheLink] = useState(false);

  const [profileImage, setProfileImage] = useState({
    preview: null,
    src: null,
  });

  const [btnDisabled, setBtnDisabled] = useState({
    name: true,
    website_link: true,
  });

  const [skipRender, setSkipRender] = useState(true);
  const [linkShow, setLinkShow] = useState(link.status ? true : false);

  const closeDeleteTheLinkModal = () => {
    setDeleteTheLink(false);
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    website_link: Yup.string().required("Required"),
  });

  const imgPreview = (e) => {
    if (e.target.files) {
      setProfileImage({
        preview: URL.createObjectURL(e.target.files[0]),
        src: e.target.files[0],
      });

      dispatch(
        linkTreeSaveStart({
          ...link,
          picture: profileImage.src,
        })
      );
    }
  };

  const handleSubmit = (values) => {
    dispatch(linkTreeSaveStart(values));
  };

  useEffect(() => {
    if (
      !skipRender &&
      !linkSave.loading &&
      Object.keys(linkSave.data).length > 0
    ) {
      setBtnDisabled({
        ...btnDisabled,
        name: true,
        website_link: true,
      });
    }
    setSkipRender(false);
  }, [linkSave]);

  useEffect(() => {
    if (
      !skipRender &&
      !linkStatus.loading &&
      Object.keys(linkStatus.data).length > 0 && 
			linkStatus.data.link_tree_id == link.link_tree_id
    ) {
      setLinkShow(linkStatus.data.status ? true : false);
    }
    setSkipRender(false);
  }, [linkStatus]);

  return (
    <>
      <div className="add-link-card">
        <div className="add-link-card-left-sec">
          <div className="add-link-card-left-static-icon-sec">
            <Image
              className="add-link-card-left-static-icon"
              src={
                window.location.origin +
                "/assets/img/add-link/add-link-card-left-static-icon.svg"
              }
            />
            <div className="add-link-card-static-logo-edit-icon-sec">
              <Image
                className="add-link-card-left-static-link-logo-edit-icon"
                src={
                  link.picture
                    ? link.picture
                    : profileImage.preview
                    ? profileImage.preview
                    : window.location.origin +
                      "/assets/img/add-link/ravi's-link-icon.png"
                }
              />
              <div className="add-link-card-static-logo-edit-options-sec">
                <label>
                  <input
                    type="file"
                    size="60"
                    onChange={(e) => imgPreview(e)}
                  />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    data-name="Layer 1"
                    viewBox="0 0 24 24"
                    width="25"
                    height="25"
                    fill="#00c75e"
                  >
                    <path d="M13.75 11.664l-3.457 3.457A3.02 3.02 0 018.172 16H8v-.172c0-.789.32-1.562.879-2.121l3.457-3.457 1.414 1.414zm1.043-3.871L13.75 8.836l1.414 1.414 1.043-1.043a1 1 0 00-1.414-1.414zM24 12c0 6.617-5.383 12-12 12S0 18.617 0 12 5.383 0 12 0s12 5.383 12 12zm-6.379-5.621a3.002 3.002 0 00-4.242 0l-5.914 5.914A4.968 4.968 0 006 15.828V17a1 1 0 001 1h1.172a4.968 4.968 0 003.535-1.465l5.914-5.914a3.002 3.002 0 000-4.242z"></path>
                  </svg>
                </label>
              </div>
            </div>
          </div>
          <div className="add-link-card-form-sec">
            <div>
              <Formik
                initialValues={{
                  link_tree_id: link.link_tree_id,
                  name: link.name,
                  website_link: link.website_link,
                }}
                onSubmit={(values) => handleSubmit(values)}
                validationSchema={validationSchema}
              >
                {({ touched, errors, isSubmitting, setFieldValue, values }) => (
                  <FORM className="add-link-form-box">
                    <div className="add-link-form-card">
                      <InputGroup className="mb-0">
                        <Field
                          placeholder="Title"
                          className={`form-control ${
                            touched.password && errors.password
                              ? "is-invalid"
                              : ""
                          }`}
                          name="name"
                          disabled={btnDisabled.name}
                        />
                        {btnDisabled.name ? (
                          <InputGroup.Text
                            id="basic-addon2"
                            onClick={() =>
                              setBtnDisabled({
                                ...btnDisabled,
                                name: false,
                              })
                            }
                          >
                            <Image
                              className="add-link-link-edit-icon"
                              src={
                                window.location.origin +
                                "/assets/img/add-link/link-edit-icon.svg"
                              }
                            />
                          </InputGroup.Text>
                        ) : (
                          <Button
                            className="delete-the-link-modal-yes-btn"
                            type="submit"
                            onClick={() => handleSubmit(values)}
                            disabled={linkSave.buttonDisable}
                          >
                            {linkSave.buttonDisable ? (
                              <Image
                                className="add-link-link-edit-icon link-loader"
                                src={
                                  window.location.origin +
                                  "/assets/img/loader.svg"
                                }
                              />
                            ) : (
                              <Image
                                className="add-link-link-edit-icon"
                                src={
                                  window.location.origin +
                                  "/assets/img/add-link/success-tick-icon.svg"
                                }
                              />
                            )}
                          </Button>
                        )}
                      </InputGroup>
                      <ErrorMessage
                        component={"div"}
                        name="name"
                        className="text-right"
                      />
                    </div>
                  {/* </FORM>
                )}
              </Formik>
              <Formik
                initialValues={{
                  link_tree_id: link.link_tree_id,
                  name: link.name,
                  website_link: link.website_link,
                }}
                onSubmit={(values) => handleSubmit(values)}
                validationSchema={validationSchema}
              >
                {({ touched, errors, isSubmitting, setFieldValue, values }) => (
                  <FORM> */}
                    <div className="add-link-form-card">
                      <InputGroup className="mb-0">
                        <Field
                          className={`form-control ${
                            touched.password && errors.password
                              ? "is-invalid"
                              : ""
                          }`}
                          placeholder="URL"
                          name="website_link"
                          disabled={btnDisabled.website_link}
                        />
                        {btnDisabled.website_link ? (
                          <InputGroup.Text
                            id="basic-addon2"
                            onClick={() =>
                              setBtnDisabled({
                                ...btnDisabled,
                                website_link: false,
                              })
                            }
                          >
                            <Image
                              className="add-link-link-edit-icon"
                              src={
                                window.location.origin +
                                "/assets/img/add-link/link-edit-icon.svg"
                              }
                            />
                          </InputGroup.Text>
                        ) : (
                          <Button
                            className="delete-the-link-modal-yes-btn"
                            type="submit"
                            onClick={() => handleSubmit(values)}
                            disabled={linkSave.buttonDisable}
                          >
                            {linkSave.buttonDisable ? (
                              <Image
                                className="add-link-link-edit-icon link-loader"
                                src={
                                  window.location.origin +
                                  "/assets/img/loader.svg"
                                }
                              />
                            ) : (
                              <Image
                                className="add-link-link-edit-icon"
                                src={
                                  window.location.origin +
                                  "/assets/img/add-link/success-tick-icon.svg"
                                }
                              />
                            )}
                          </Button>
                        )}
                      </InputGroup>
                      <ErrorMessage
                        component={"div"}
                        name="website_link"
                        className="text-right"
                      />
                    </div>
                  </FORM>
                )}
              </Formik>
            </div>
          </div>
        </div>
        <div className="add-link-card-right-sec">
          <div className="add-link-card-right-btn-sec">
            <Button
              className="add-link-view-btn"
              onClick={() =>
                dispatch(
                  linkTreeStatusUpdateStart({
                    link_tree_id: link.link_tree_id,
                  })
                )
              }
            >
              {linkStatus.buttonDisable ? (
                <Image
                  className="add-link-link-edit-icon link-loader"
                  src={window.location.origin + "/assets/img/loader.svg"}
                />
              ) : (
                <Image
                  className="add-link-view-icon"
                  src={
                    linkShow
                      ? window.location.origin +
                        "/assets/img/add-link/add-link-eye-icon.svg"
                      : window.location.origin +
                        "/assets/img/other-profile/eye-close-icon-profile.svg"
                  }
                />
              )}
            </Button>
          </div>
          <hr />
          <div className="add-link-card-right-btn-sec">
            <Button
              className="add-link-remove-btn"
              onClick={() => setDeleteTheLink(true)}
            >
              <Image
                className="add-link-view-icon"
                src={
                  window.location.origin +
                  "/assets/img/add-link/add-link-delete-icon.svg"
                }
              />
            </Button>
          </div>
        </div>
      </div>
      {deleteTheLink && (
        <DeleteTheLinkModal
          deleteTheLink={deleteTheLink}
          closeDeleteTheLinkModal={closeDeleteTheLinkModal}
          setDeleteTheLink={setDeleteTheLink}
					link={link}
        />
      )}
    </>
  );
};

export default AddLinkCard;
