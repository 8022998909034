import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Image,
  Container,
  Button,
  Nav,
  Tab,
  Form,
  InputGroup,
  FormGroup,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import OnBoardingHeader from "../OnBoarding/OnBoardingHeader";
import Skeleton from "react-loading-skeleton";
import NoDataFound from "../Helper/NoDataFound";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Persona from "persona";
import configuration from "react-global-configuration";
import {
  onboardingUpdateStart,
  usernameValidationStart,
} from "../../store/slices/OnBoardingSlice";
import { updateUserDetailsStart } from "../../store/slices/UserSlice";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/Helper/NotificationMessage";
import ChangePasswordModel from "./ChangePasswordModel";
const auth0 = window.auth0;

const AccountIndex = (props) => {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.user.profile);
  const profileInputData = useSelector((state) => state.user.profileInputData);
  const onboardingUpdate = useSelector(
    (state) => state.onboard.onboardingUpdate
  );
  const validation = useSelector((state) => state.onboard.usernameValidation);

  const [isvalidUserName, setIsValidUserName] = useState(false);
  const [userName, setUserName] = useState(profile.data.username);
  const [btnLoading, setBtnLoading] = useState(false);
  const [profileImage, setProfileImage] = useState({
    preview: null,
    src: null
  });

  const [changePasswordModel, setChangePasswordModel] = useState(null);

  const closeChangePasswordModel = () => {
    setChangePasswordModel(null);
  };

  const auth0_options = {
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required *"),
    username: Yup.string().required("Username is required *"),
  });

  const handleUsernameValidation = (username) => {
    if (username && username.length > 3) {
      if (username.replace(" ", "") === username) {
        if (username !== userName) {
          setUserName(username);
          setIsValidUserName(true);
          dispatch(usernameValidationStart({ username: username }));
          return "";
        }
      } else {
        setIsValidUserName(false);
        return "No white space allowed";
      }
    } else {
      setIsValidUserName(false);
      return "Must Contain 4 Characters";
    }
  };

  const handleSubmit = (values) => {
    let newValues = values;
    if (profileImage.src) {
      newValues = {
        ...values,
        picture: profileImage.src,
      };
    }
    dispatch(updateUserDetailsStart(newValues));
  };

  const [options, setOptions] = useState({
    templateId: configuration.get("configData.withdrawal_kyc_template_id"),
    environment: configuration.get("configData.withdrawal_kyc_environment"),
    host: configuration.get("configData.withdrawal_kyc_environment"),
  });

  const embeddedClientRef = useRef(null);

  const createClient = () => {
    const client = new Persona.Client({
      ...options,
      onLoad: (error) => {
        if (error) {
          console.error(
            `Failed with code: ${error.code} and message ${error.message}`
          );
        }

        client.open();
      },
      onStart: (inquiryId) => {
        console.log(`Started inquiry ${inquiryId}`);
      },
      onComplete: (data) => {
        console.log(
          `Sending finished inquiry ${data.inquiryId} to backend`,
          data
        );
        dispatch(
          updateUserDetailsStart({
            is_document_verified: 2,
          })
        );
      },
      onEvent: (name, meta) => {
        switch (name) {
          case "start":
            console.log(`Received event: start`);
            break;
          default:
            console.log(
              `Received event: ${name} with meta: ${JSON.stringify(meta)}`
            );
        }
      },
    });
    embeddedClientRef.current = client;

    window.exit = (force) =>
      client ? client.exit(force) : alert("Initialize client first");
  };

  if (!options.templateId) {
    return null;
  }

  const handleKycModal = () => {
    if (embeddedClientRef.current != null) {
      embeddedClientRef.current.open();
    } else {
      createClient();
    }
  };

  const handleChangePassword = async (values) => {
    setBtnLoading(true);
    const webAuth = new auth0.WebAuth(auth0_options);
    webAuth.changePassword(
      {
        connection: "Username-Password-Authentication",
        email: profile.data.email ?? "",
      },
      function (err, resp) {
        if (err) {
          console.log(err.message);
          getErrorNotificationMessage(err.message ?? "Something went wrong");
        } else {
          console.log(resp);
          getSuccessNotificationMessage(
            resp ?? "Sent reset pasword link to your mail, please check"
          );
          closeChangePasswordModel()
        }
        setBtnLoading(false);
      }
    );
  };

  const openChangePasswordModel = () => {
    setChangePasswordModel(true);
  };

  const imgPreview = (e) => {
    setProfileImage({
      preview: URL.createObjectURL(e.target.files[0]),
      src: e.target.files[0]
    });
  };

  return (
    <>
      <div className="account-sec">
        <Container>
          {profile.loading ? (
            <Skeleton className="account-box" height={500} />
          ) : Object.keys(profile.data).length > 0 ? (
            <>
              <div className="account-back-btn-sec">
                <Link to="/profile" className="account-back-btn">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-chevron-left"
                  >
                    <polyline points="15 18 9 12 15 6"></polyline>
                  </svg>
                  Back to Profile
                </Link>
              </div>
              <div className="account-box">
                <div className="account-left-sec">
                  <div className="account-user-details">
                    <Image
                      src={
                        profile.data.picture
                          ? profile.data.picture
                          : window.location.origin +
                            "/assets/img/account/profile-img.png"
                      }
                      className="account-user-img"
                    />
                    <h4>{profile.data.name}</h4>
                  </div>
                  <div className="account-sidebar-list-sec">
                    <ul className="list-unstyled account-sidebar-list">
                      <li>
                        <Link to="/account" className="active">
                          <span>Account</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/account-onboarding">
                          <span>Onboarding</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="account-right-sec">
                  <div className="account-tab-sec">
                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey="profile"
                    >
                      <Row>
                        <Col sm={12}>
                          <Nav variant="pills">
                            <Nav.Item>
                              <Nav.Link eventKey="profile">Profile</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="social-media">
                                Social Media
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="change-password">
                                Change Password
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="kyc">KYC</Nav.Link>
                            </Nav.Item>
                          </Nav>
                        </Col>
                        <Col sm={12}>
                          <Tab.Content>
                            <Tab.Pane eventKey="profile">
                              <Formik
                                initialValues={{
                                  name: profile.data.name
                                    ? profile.data.name
                                    : "",
                                  email: profile.data.email
                                    ? profile.data.email
                                    : "",
                                  username: profile.data.username
                                    ? profile.data.username
                                    : "",
                                }}
                                validationSchema={validationSchema}
                                onSubmit={(values) => handleSubmit(values)}
                              >
                                {({
                                  touched,
                                  errors,
                                  isSubmitting,
                                  setFieldValue,
                                }) => (
                                  <FORM>
                                    <div className="account-tab-content">
                                      <Row className="justify-content-md-center">
                                        <Col md={10} xl={6} lg={6}>
                                          <div className="link-your-account-profile-sec">
                                            <div className="profile-view-img-sec">
                                              <Image
                                                src={
                                                  profileImage.preview ? profileImage.preview
                                                  : profile.data.picture
                                                    ? profile.data.picture
                                                    : window.location.origin +
                                                      "/assets/img/account/profile-img.png"
                                                }
                                                className="profile-view-img"
                                              />
                                              <div className="account-profile-img-upload-sec">
                                                <label>
                                                  <input type="file" size="60" onChange={(e) => imgPreview(e)}/>
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    data-name="Layer 1"
                                                    viewBox="0 0 24 24"
                                                    width="30"
                                                    height="30"
                                                    fill="#00c75e"
                                                  >
                                                    <path d="M13.75 11.664l-3.457 3.457A3.02 3.02 0 018.172 16H8v-.172c0-.789.32-1.562.879-2.121l3.457-3.457 1.414 1.414zm1.043-3.871L13.75 8.836l1.414 1.414 1.043-1.043a1 1 0 00-1.414-1.414zM24 12c0 6.617-5.383 12-12 12S0 18.617 0 12 5.383 0 12 0s12 5.383 12 12zm-6.379-5.621a3.002 3.002 0 00-4.242 0l-5.914 5.914A4.968 4.968 0 006 15.828V17a1 1 0 001 1h1.172a4.968 4.968 0 003.535-1.465l5.914-5.914a3.002 3.002 0 000-4.242z"></path>
                                                  </svg>
                                                </label> 
                                              </div>
                                            </div>
                                            <div className="profile-details-edit">
                                              <FormGroup>
                                                <Form.Group
                                                  className="mb-4"
                                                  controlId="exampleForm.ControlInput1"
                                                >
                                                  <Form.Label>Name</Form.Label>
                                                  <InputGroup
                                                    className={`mb-0 ${
                                                      touched.name &&
                                                      errors.name
                                                        ? "is-invalid"
                                                        : ""
                                                    }`}
                                                  >
                                                    <Field
                                                      className={`form-control
                                                      ${
                                                        touched.name &&
                                                        errors.name
                                                          ? "is-invalid"
                                                          : ""
                                                      }`}
                                                      name="name"
                                                    />
                                                  </InputGroup>
                                                  <ErrorMessage
                                                    component={"div"}
                                                    name="name"
                                                    className="text-danger text-right"
                                                  />
                                                </Form.Group>
                                              </FormGroup>
                                              <FormGroup>
                                                <Form.Group
                                                  className="mb-4"
                                                  controlId="exampleForm.ControlInput1"
                                                >
                                                  <Form.Label>Email</Form.Label>
                                                  <InputGroup
                                                    className={`mb-0 ${
                                                      touched.email &&
                                                      errors.email
                                                        ? "is-invalid"
                                                        : ""
                                                    }`}
                                                  >
                                                    <Field
                                                      className={`form-control
                                                      ${
                                                        touched.email &&
                                                        errors.email
                                                          ? "is-invalid"
                                                          : ""
                                                      }`}
                                                      name="email"
                                                      disabled={true}
                                                    />
                                                  </InputGroup>
                                                  <ErrorMessage
                                                    component={"div"}
                                                    name="email"
                                                    className="text-danger text-right"
                                                  />
                                                </Form.Group>
                                              </FormGroup>
                                              <FormGroup>
                                                <Form.Label>
                                                  User Name
                                                </Form.Label>
                                                <InputGroup
                                                  className={`mb-0 ${
                                                    touched.username &&
                                                    errors.username
                                                      ? "is-invalid"
                                                      : ""
                                                  }`}
                                                >
                                                  <Field
                                                    className={`form-control
                                                      ${
                                                        touched.username &&
                                                        errors.username
                                                          ? "is-invalid"
                                                          : ""
                                                      }`}
                                                    name="username"
                                                    validate={
                                                      handleUsernameValidation
                                                    }
                                                    autoComplete="off"
                                                  />
                                                </InputGroup>
                                                <ErrorMessage
                                                  component={"div"}
                                                  name="username"
                                                  className="text-danger text-right"
                                                />
                                                {validation.isInValid &&
                                                isvalidUserName ? (
                                                  <div class="text-danger text-right">
                                                    Name is already used.
                                                  </div>
                                                ) : null}
                                                {validation.isValid &&
                                                isvalidUserName ? (
                                                  <div class="text-success text-right">
                                                    Name is available
                                                  </div>
                                                ) : null}
                                              </FormGroup>
                                            </div>
                                            <div className="login-btn mt-4">
                                              <Button
                                                type="submit"
                                                className="default-btn"
                                                disabled={
                                                  // !(
                                                  //   // (validation.isValid &&
                                                  //   //   isvalidUserName) ||
                                                  //   profile.data.username ===
                                                  //     userName
                                                  // ) ||
                                                  profileInputData.buttonDisable
                                                }
                                              >
                                                {profileInputData.buttonDisable
                                                  ? "Loading"
                                                  : "Save"}
                                              </Button>
                                            </div>
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>
                                  </FORM>
                                )}
                              </Formik>
                            </Tab.Pane>
                            <Tab.Pane eventKey="social-media">
                              <Formik
                                initialValues={{
                                  insta_username: profile.data.insta_username
                                    ? profile.data.insta_username
                                    : "",
                                  twitter_username: profile.data
                                    .twitter_username
                                    ? profile.data.twitter_username
                                    : "",
                                  snap_username: profile.data.snap_username
                                    ? profile.data.snap_username
                                    : "",
                                }}
                                // validationSchema={validationSchema}
                                onSubmit={(values) => handleSubmit(values)}
                              >
                                {({
                                  touched,
                                  errors,
                                  isSubmitting,
                                  setFieldValue,
                                }) => (
                                  <FORM>
                                    <div className="account-tab-content">
                                      <div className="account-tab-content-title-sec">
                                        <h4>Social Media Settings</h4>
                                        <p>Manage your social media account</p>
                                      </div>
                                      <Row className="justify-content-md-center">
                                        <Col md={10} xl={6} lg={6}>
                                          <div className="link-your-account-sec">
                                            <ul className="list-unstyled list-unstyled">
                                              <label>Instagram</label>
                                              <li>
                                                <FormGroup>
                                                  <InputGroup className="mb-3">
                                                    <InputGroup.Text id="basic-addon1">
                                                      <span>
                                                        <Image
                                                          src={
                                                            window.location
                                                              .origin +
                                                            "/assets/img/social-media/instagram.svg"
                                                          }
                                                        />
                                                      </span>
                                                    </InputGroup.Text>
                                                    <Field
                                                      type="text"
                                                      placeholder="biancaabbottwellness"
                                                      className="form-control"
                                                      name="insta_username"
                                                    />
                                                  </InputGroup>
                                                </FormGroup>
                                              </li>
                                              <label>Twitter</label>
                                              <li>
                                                <FormGroup>
                                                  <InputGroup className="mb-3">
                                                    <InputGroup.Text id="basic-addon1">
                                                      <span>
                                                        <Image
                                                          src={
                                                            window.location
                                                              .origin +
                                                            "/assets/img/social-media/twitter.svg"
                                                          }
                                                        />
                                                      </span>
                                                    </InputGroup.Text>
                                                    <Field
                                                      type="text"
                                                      placeholder="Link your account"
                                                      className="form-control"
                                                      name="twitter_username"
                                                    />
                                                  </InputGroup>
                                                </FormGroup>
                                              </li>
                                              <label>Snap Chat</label>
                                              <li>
                                                <FormGroup>
                                                  <InputGroup className="mb-3">
                                                    <InputGroup.Text id="basic-addon1">
                                                      <span>
                                                        <Image
                                                          src={
                                                            window.location
                                                              .origin +
                                                            "/assets/img/social-media/snapchat.svg"
                                                          }
                                                        />
                                                      </span>
                                                    </InputGroup.Text>
                                                    <Field
                                                      type="text"
                                                      placeholder="Link your account"
                                                      className="form-control"
                                                      name="snap_username"
                                                    />
                                                  </InputGroup>
                                                </FormGroup>
                                              </li>
                                            </ul>
                                            <div className="login-btn mt-4">
                                              <Button
                                                type="submit"
                                                className="default-btn"
                                                disabled={
                                                  profileInputData.buttonDisable
                                                }
                                              >
                                                {profileInputData.buttonDisable
                                                  ? "Loading"
                                                  : "Save"}
                                              </Button>
                                            </div>
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>
                                  </FORM>
                                )}
                              </Formik>
                            </Tab.Pane>
                            <Tab.Pane eventKey="change-password">
                              <div className="account-tab-content">
                                <div className="account-tab-content-title-sec">
                                  <h4>Change Password</h4>
                                  <p>
                                    Change your account password, Resent link
                                    will be sent to your registered mail.
                                  </p>
                                </div>
                                <Row className="justify-content-md-center">
                                  <Col md={10} xl={6} lg={6}>
                                    <div className="link-your-account-sec">
                                      <div className="login-btn mt-4">
                                        <Button
                                          className="default-btn"
                                          // onClick={handleChangePassword}
                                          onClick={() => openChangePasswordModel()}
                                          disabled={btnLoading}
                                        >
                                          {btnLoading
                                            ? "Loading"
                                            : "Change Password"}
                                        </Button>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="kyc">
                              <div className="account-tab-content">
                                <div className="account-tab-content-title-sec">
                                  <h4>Persona KYC integration</h4>
                                  <p>Verify your account</p>
                                </div>
                                <Row className="justify-content-md-center">
                                  <Col md={10} xl={6} lg={6}>
                                    <div className="link-your-account-sec">
                                      <div className="login-btn mt-4">
                                        {profile.data.is_document_verified ? (
                                          <div>
                                            <h4>Account Verified!</h4>
                                          </div>
                                        ) : (
                                          <Button
                                            type="submit"
                                            className="default-btn"
                                            onClick={handleKycModal}
                                          >
                                            Verify
                                          </Button>
                                        )}
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </Tab.Pane>
                          </Tab.Content>
                        </Col>
                      </Row>
                    </Tab.Container>
                  </div>
                </div>
              </div>
              {changePasswordModel && (
                <ChangePasswordModel 
                  changePasswordModel={changePasswordModel}
                  closeChangePasswordModel = {closeChangePasswordModel}
                  handleChangePassword = {handleChangePassword}
                  btnLoading = {btnLoading}
                />
              )}
            </>
          ) : (
            <NoDataFound />
          )}
        </Container>
      </div>
    </>
  );
};

export default AccountIndex;
