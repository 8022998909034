import React, { useState, useEffect } from "react";
import { Row, Col, Image, Container, Tab, Nav, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const OtherProfileReviewTestimonials = (props) => {


  return (
    <>
      <div className="other-profile-nft-store-font-sec other-profile-review-testimonials-sec resp-mrg-btm-xl">
        <div className="other-profile-nft-store-font-box">
          <Tab.Container id="left-tabs-example" defaultActiveKey="sponsored-reviews">
            <Row>
              <Col sm={12}>
                <div className="other-profile-nft-store-font-header-sec other-profile-review-testimonials-header-sec">
                  <Nav variant="pills">
                    <Nav.Item className="border-right-line-theme">
                      <Nav.Link eventKey="sponsored-reviews">
                        <Image
                          className="other-profile-nft-store-font-icon"
                          src={
                            window.location.origin +
                            "/assets/img/other-profile/nft-storefront-icon.png"
                          }
                        />
                        Sponsored Reviews
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="testimonials">Testimonials</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <div className="other-profile-hashtags-more-btn-sec">
                    <Link to="#">
                      More
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        fill="none"
                        viewBox="0 0 6 9"
                      >
                        <path
                          stroke="#000"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.3"
                          d="M1.168 1.42l3.331 3.262-3.331 3.26"
                        ></path>
                      </svg>
                    </Link>
                  </div>
                </div>
              </Col>
              <Col sm={12}>
                <Tab.Content>
                  <Tab.Pane eventKey="sponsored-reviews">
                    <div className="other-profile-sponsored-reviews-box">
                      <div className="other-profile-sponsored-reviews-card">
                        <div className="other-profile-sponsored-reviews-header-sec">
                          <div className="other-profile-sponsored-reviews-company-info">
                            <Image
                              className="other-profile-sponsored-reviews-company-icon"
                              src={
                                window.location.origin +
                                "/assets/img/other-profile/logo-company.png"
                              }
                            />
                            <span>Nike</span>
                            <Image
                              className="other-profile-sponsored-reviews-company-icon"
                              src={
                                window.location.origin +
                                "/assets/img/other-profile/eye-icon-profile.svg"
                              }
                            />
                          </div>
                          <div className="other-profile-sponsored-reviews-rating-star">
                            <ul className="other-profile-sponsored-review-rating-star-list list-unstyled">
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="other-profile-sponsored-reviews-body-sec">
                          “Lorem ipsum dolar si amet lorem ipsum dolar si amet
                        </div>
                      </div>
                      <div className="other-profile-sponsored-reviews-card">
                        <div className="other-profile-sponsored-reviews-header-sec">
                          <div className="other-profile-sponsored-reviews-company-info">
                            <Image
                              className="other-profile-sponsored-reviews-company-icon"
                              src={
                                window.location.origin +
                                "/assets/img/other-profile/logo-company.png"
                              }
                            />
                            <span>Nike</span>
                            <Image
                              className="other-profile-sponsored-reviews-company-icon"
                              src={
                                window.location.origin +
                                "/assets/img/other-profile/eye-icon-profile.svg"
                              }
                            />
                          </div>
                          <div className="other-profile-sponsored-reviews-rating-star">
                            <ul className="other-profile-sponsored-review-rating-star-list list-unstyled">
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="other-profile-sponsored-reviews-body-sec">
                          “Lorem ipsum dolar si amet lorem ipsum dolar si amet
                        </div>
                      </div>
                      <div className="other-profile-sponsored-reviews-card">
                        <div className="other-profile-sponsored-reviews-header-sec">
                          <div className="other-profile-sponsored-reviews-company-info">
                            <Image
                              className="other-profile-sponsored-reviews-company-icon"
                              src={
                                window.location.origin +
                                "/assets/img/other-profile/logo-company.png"
                              }
                            />
                            <span>Nike</span>
                            <Image
                              className="other-profile-sponsored-reviews-company-icon"
                              src={
                                window.location.origin +
                                "/assets/img/other-profile/eye-icon-profile.svg"
                              }
                            />
                          </div>
                          <div className="other-profile-sponsored-reviews-rating-star">
                            <ul className="other-profile-sponsored-review-rating-star-list list-unstyled">
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="other-profile-sponsored-reviews-body-sec">
                          “Lorem ipsum dolar si amet lorem ipsum dolar si amet
                        </div>
                      </div>
                      <div className="other-profile-sponsored-reviews-card">
                        <div className="other-profile-sponsored-reviews-header-sec">
                          <div className="other-profile-sponsored-reviews-company-info">
                            <Image
                              className="other-profile-sponsored-reviews-company-icon"
                              src={
                                window.location.origin +
                                "/assets/img/other-profile/logo-company.png"
                              }
                            />
                            <span>Nike</span>
                            <Image
                              className="other-profile-sponsored-reviews-company-icon"
                              src={
                                window.location.origin +
                                "/assets/img/other-profile/eye-icon-profile.svg"
                              }
                            />
                          </div>
                          <div className="other-profile-sponsored-reviews-rating-star">
                            <ul className="other-profile-sponsored-review-rating-star-list list-unstyled">
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="other-profile-sponsored-reviews-body-sec">
                          “Lorem ipsum dolar si amet lorem ipsum dolar si amet
                        </div>
                      </div>
                      <div className="other-profile-sponsored-reviews-card">
                        <div className="other-profile-sponsored-reviews-header-sec">
                          <div className="other-profile-sponsored-reviews-company-info">
                            <Image
                              className="other-profile-sponsored-reviews-company-icon"
                              src={
                                window.location.origin +
                                "/assets/img/other-profile/logo-company.png"
                              }
                            />
                            <span>Nike</span>
                            <Image
                              className="other-profile-sponsored-reviews-company-icon"
                              src={
                                window.location.origin +
                                "/assets/img/other-profile/eye-icon-profile.svg"
                              }
                            />
                          </div>
                          <div className="other-profile-sponsored-reviews-rating-star">
                            <ul className="other-profile-sponsored-review-rating-star-list list-unstyled">
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="other-profile-sponsored-reviews-body-sec">
                          “Lorem ipsum dolar si amet lorem ipsum dolar si amet
                        </div>
                      </div>
                      <div className="other-profile-sponsored-reviews-card">
                        <div className="other-profile-sponsored-reviews-header-sec">
                          <div className="other-profile-sponsored-reviews-company-info">
                            <Image
                              className="other-profile-sponsored-reviews-company-icon"
                              src={
                                window.location.origin +
                                "/assets/img/other-profile/logo-company.png"
                              }
                            />
                            <span>Nike</span>
                            <Image
                              className="other-profile-sponsored-reviews-company-icon"
                              src={
                                window.location.origin +
                                "/assets/img/other-profile/eye-icon-profile.svg"
                              }
                            />
                          </div>
                          <div className="other-profile-sponsored-reviews-rating-star">
                            <ul className="other-profile-sponsored-review-rating-star-list list-unstyled">
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="other-profile-sponsored-reviews-body-sec">
                          “Lorem ipsum dolar si amet lorem ipsum dolar si amet
                        </div>
                      </div>
                      <div className="other-profile-sponsored-reviews-card">
                        <div className="other-profile-sponsored-reviews-header-sec">
                          <div className="other-profile-sponsored-reviews-company-info">
                            <Image
                              className="other-profile-sponsored-reviews-company-icon"
                              src={
                                window.location.origin +
                                "/assets/img/other-profile/logo-company.png"
                              }
                            />
                            <span>Nike</span>
                            <Image
                              className="other-profile-sponsored-reviews-company-icon"
                              src={
                                window.location.origin +
                                "/assets/img/other-profile/eye-icon-profile.svg"
                              }
                            />
                          </div>
                          <div className="other-profile-sponsored-reviews-rating-star">
                            <ul className="other-profile-sponsored-review-rating-star-list list-unstyled">
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="other-profile-sponsored-reviews-body-sec">
                          “Lorem ipsum dolar si amet lorem ipsum dolar si amet
                        </div>
                      </div>
                      <div className="other-profile-sponsored-reviews-card">
                        <div className="other-profile-sponsored-reviews-header-sec">
                          <div className="other-profile-sponsored-reviews-company-info">
                            <Image
                              className="other-profile-sponsored-reviews-company-icon"
                              src={
                                window.location.origin +
                                "/assets/img/other-profile/logo-company.png"
                              }
                            />
                            <span>Nike</span>
                            <Image
                              className="other-profile-sponsored-reviews-company-icon"
                              src={
                                window.location.origin +
                                "/assets/img/other-profile/eye-icon-profile.svg"
                              }
                            />
                          </div>
                          <div className="other-profile-sponsored-reviews-rating-star">
                            <ul className="other-profile-sponsored-review-rating-star-list list-unstyled">
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="other-profile-sponsored-reviews-body-sec">
                          “Lorem ipsum dolar si amet lorem ipsum dolar si amet
                        </div>
                      </div>
                      <div className="other-profile-sponsored-reviews-card">
                        <div className="other-profile-sponsored-reviews-header-sec">
                          <div className="other-profile-sponsored-reviews-company-info">
                            <Image
                              className="other-profile-sponsored-reviews-company-icon"
                              src={
                                window.location.origin +
                                "/assets/img/other-profile/logo-company.png"
                              }
                            />
                            <span>Nike</span>
                            <Image
                              className="other-profile-sponsored-reviews-company-icon"
                              src={
                                window.location.origin +
                                "/assets/img/other-profile/eye-icon-profile.svg"
                              }
                            />
                          </div>
                          <div className="other-profile-sponsored-reviews-rating-star">
                            <ul className="other-profile-sponsored-review-rating-star-list list-unstyled">
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="other-profile-sponsored-reviews-body-sec">
                          “Lorem ipsum dolar si amet lorem ipsum dolar si amet
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="testimonials">
                  <div className="other-profile-sponsored-reviews-box">
                      <div className="other-profile-sponsored-reviews-card">
                        <div className="other-profile-sponsored-reviews-header-sec">
                          <div className="other-profile-sponsored-reviews-company-info">
                            <Image
                              className="other-profile-sponsored-reviews-company-icon"
                              src={
                                window.location.origin +
                                "/assets/img/other-profile/logo-company.png"
                              }
                            />
                            <span>Nike</span>
                            <Image
                              className="other-profile-sponsored-reviews-company-icon"
                              src={
                                window.location.origin +
                                "/assets/img/other-profile/eye-icon-profile.svg"
                              }
                            />
                          </div>
                          <div className="other-profile-sponsored-reviews-rating-star">
                            <ul className="other-profile-sponsored-review-rating-star-list list-unstyled">
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="other-profile-sponsored-reviews-body-sec">
                          “Lorem ipsum dolar si amet lorem ipsum dolar si amet
                        </div>
                      </div>
                      <div className="other-profile-sponsored-reviews-card">
                        <div className="other-profile-sponsored-reviews-header-sec">
                          <div className="other-profile-sponsored-reviews-company-info">
                            <Image
                              className="other-profile-sponsored-reviews-company-icon"
                              src={
                                window.location.origin +
                                "/assets/img/other-profile/logo-company.png"
                              }
                            />
                            <span>Nike</span>
                            <Image
                              className="other-profile-sponsored-reviews-company-icon"
                              src={
                                window.location.origin +
                                "/assets/img/other-profile/eye-icon-profile.svg"
                              }
                            />
                          </div>
                          <div className="other-profile-sponsored-reviews-rating-star">
                            <ul className="other-profile-sponsored-review-rating-star-list list-unstyled">
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="other-profile-sponsored-reviews-body-sec">
                          “Lorem ipsum dolar si amet lorem ipsum dolar si amet
                        </div>
                      </div>
                      <div className="other-profile-sponsored-reviews-card">
                        <div className="other-profile-sponsored-reviews-header-sec">
                          <div className="other-profile-sponsored-reviews-company-info">
                            <Image
                              className="other-profile-sponsored-reviews-company-icon"
                              src={
                                window.location.origin +
                                "/assets/img/other-profile/logo-company.png"
                              }
                            />
                            <span>Nike</span>
                            <Image
                              className="other-profile-sponsored-reviews-company-icon"
                              src={
                                window.location.origin +
                                "/assets/img/other-profile/eye-icon-profile.svg"
                              }
                            />
                          </div>
                          <div className="other-profile-sponsored-reviews-rating-star">
                            <ul className="other-profile-sponsored-review-rating-star-list list-unstyled">
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="other-profile-sponsored-reviews-body-sec">
                          “Lorem ipsum dolar si amet lorem ipsum dolar si amet
                        </div>
                      </div>
                      <div className="other-profile-sponsored-reviews-card">
                        <div className="other-profile-sponsored-reviews-header-sec">
                          <div className="other-profile-sponsored-reviews-company-info">
                            <Image
                              className="other-profile-sponsored-reviews-company-icon"
                              src={
                                window.location.origin +
                                "/assets/img/other-profile/logo-company.png"
                              }
                            />
                            <span>Nike</span>
                            <Image
                              className="other-profile-sponsored-reviews-company-icon"
                              src={
                                window.location.origin +
                                "/assets/img/other-profile/eye-icon-profile.svg"
                              }
                            />
                          </div>
                          <div className="other-profile-sponsored-reviews-rating-star">
                            <ul className="other-profile-sponsored-review-rating-star-list list-unstyled">
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="other-profile-sponsored-reviews-body-sec">
                          “Lorem ipsum dolar si amet lorem ipsum dolar si amet
                        </div>
                      </div>
                      <div className="other-profile-sponsored-reviews-card">
                        <div className="other-profile-sponsored-reviews-header-sec">
                          <div className="other-profile-sponsored-reviews-company-info">
                            <Image
                              className="other-profile-sponsored-reviews-company-icon"
                              src={
                                window.location.origin +
                                "/assets/img/other-profile/logo-company.png"
                              }
                            />
                            <span>Nike</span>
                            <Image
                              className="other-profile-sponsored-reviews-company-icon"
                              src={
                                window.location.origin +
                                "/assets/img/other-profile/eye-icon-profile.svg"
                              }
                            />
                          </div>
                          <div className="other-profile-sponsored-reviews-rating-star">
                            <ul className="other-profile-sponsored-review-rating-star-list list-unstyled">
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="other-profile-sponsored-reviews-body-sec">
                          “Lorem ipsum dolar si amet lorem ipsum dolar si amet
                        </div>
                      </div>
                      <div className="other-profile-sponsored-reviews-card">
                        <div className="other-profile-sponsored-reviews-header-sec">
                          <div className="other-profile-sponsored-reviews-company-info">
                            <Image
                              className="other-profile-sponsored-reviews-company-icon"
                              src={
                                window.location.origin +
                                "/assets/img/other-profile/logo-company.png"
                              }
                            />
                            <span>Nike</span>
                            <Image
                              className="other-profile-sponsored-reviews-company-icon"
                              src={
                                window.location.origin +
                                "/assets/img/other-profile/eye-icon-profile.svg"
                              }
                            />
                          </div>
                          <div className="other-profile-sponsored-reviews-rating-star">
                            <ul className="other-profile-sponsored-review-rating-star-list list-unstyled">
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="other-profile-sponsored-reviews-body-sec">
                          “Lorem ipsum dolar si amet lorem ipsum dolar si amet
                        </div>
                      </div>
                      <div className="other-profile-sponsored-reviews-card">
                        <div className="other-profile-sponsored-reviews-header-sec">
                          <div className="other-profile-sponsored-reviews-company-info">
                            <Image
                              className="other-profile-sponsored-reviews-company-icon"
                              src={
                                window.location.origin +
                                "/assets/img/other-profile/logo-company.png"
                              }
                            />
                            <span>Nike</span>
                            <Image
                              className="other-profile-sponsored-reviews-company-icon"
                              src={
                                window.location.origin +
                                "/assets/img/other-profile/eye-icon-profile.svg"
                              }
                            />
                          </div>
                          <div className="other-profile-sponsored-reviews-rating-star">
                            <ul className="other-profile-sponsored-review-rating-star-list list-unstyled">
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="other-profile-sponsored-reviews-body-sec">
                          “Lorem ipsum dolar si amet lorem ipsum dolar si amet
                        </div>
                      </div>
                      <div className="other-profile-sponsored-reviews-card">
                        <div className="other-profile-sponsored-reviews-header-sec">
                          <div className="other-profile-sponsored-reviews-company-info">
                            <Image
                              className="other-profile-sponsored-reviews-company-icon"
                              src={
                                window.location.origin +
                                "/assets/img/other-profile/logo-company.png"
                              }
                            />
                            <span>Nike</span>
                            <Image
                              className="other-profile-sponsored-reviews-company-icon"
                              src={
                                window.location.origin +
                                "/assets/img/other-profile/eye-icon-profile.svg"
                              }
                            />
                          </div>
                          <div className="other-profile-sponsored-reviews-rating-star">
                            <ul className="other-profile-sponsored-review-rating-star-list list-unstyled">
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="other-profile-sponsored-reviews-body-sec">
                          “Lorem ipsum dolar si amet lorem ipsum dolar si amet
                        </div>
                      </div>
                      <div className="other-profile-sponsored-reviews-card">
                        <div className="other-profile-sponsored-reviews-header-sec">
                          <div className="other-profile-sponsored-reviews-company-info">
                            <Image
                              className="other-profile-sponsored-reviews-company-icon"
                              src={
                                window.location.origin +
                                "/assets/img/other-profile/logo-company.png"
                              }
                            />
                            <span>Nike</span>
                            <Image
                              className="other-profile-sponsored-reviews-company-icon"
                              src={
                                window.location.origin +
                                "/assets/img/other-profile/eye-icon-profile.svg"
                              }
                            />
                          </div>
                          <div className="other-profile-sponsored-reviews-rating-star">
                            <ul className="other-profile-sponsored-review-rating-star-list list-unstyled">
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="none"
                                  viewBox="0 0 22 22"
                                >
                                  <path
                                    fill="#FFD952"
                                    d="M11.452 17.95l5.183 3.292a.87.87 0 001.3-.977l-1.5-5.9a.895.895 0 01.3-.9l4.648-3.877a.892.892 0 00-.494-1.573l-6.067-.391a.854.854 0 01-.751-.555l-2.272-5.705a.854.854 0 00-1.6 0l-2.262 5.7a.854.854 0 01-.751.555l-6.07.388A.892.892 0 00.624 9.58l4.649 3.877a.896.896 0 01.3.9l-1.39 5.476a1.041 1.041 0 001.542 1.174l4.823-3.054a.844.844 0 01.9 0l.005-.002z"
                                  ></path>
                                </svg>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="other-profile-sponsored-reviews-body-sec">
                          “Lorem ipsum dolar si amet lorem ipsum dolar si amet
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </div>
    </>
  )
}

export default OtherProfileReviewTestimonials;
