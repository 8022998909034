import React, { useState, useEffect } from "react";
import { Row, Col, Image, Container, Tab, Nav, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const OtherProfileLinkIndex = (props) => {


  return (
    <>
      <div className="other-profile-link-tree-sec">
        <div className="other-profile-link-tree-box">
            <div className="other-profile-hashtags-header-sec">
                <div className="other-profile-hashtags-header-title-sec">
                    <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="27"
                    height="27"
                    fill="none"
                    viewBox="0 0 27 27"
                    >
                    <mask
                        id="mask0_2_28620"
                        style={{ maskType: "luminance" }}
                        width="27"
                        height="27"
                        x="0"
                        y="0"
                        maskUnits="userSpaceOnUse"
                    >
                        <path fill="#fff" d="M26.125.824h-26v26h26v-26z"></path>
                    </mask>
                    <g
                        stroke="#000"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.8"
                        mask="url(#mask0_2_28620)"
                    >
                        <path d="M12.482 6.929l2.238-2.407a4.731 4.731 0 016.884.172 5.671 5.671 0 01.16 7.413l-3.2 3.433a4.958 4.958 0 01-1.616 1.164 4.635 4.635 0 01-3.807 0 4.957 4.957 0 01-1.612-1.164"></path>
                        <path d="M13.77 20.713l-2.239 2.413a4.785 4.785 0 01-3.473 1.4 4.82 4.82 0 01-3.406-1.572 5.671 5.671 0 01-.16-7.413l3.196-3.428A4.957 4.957 0 019.3 10.944a4.635 4.635 0 013.806 0c.611.277 1.16.674 1.613 1.169"></path>
                    </g>
                    </svg>
                    <span>Ravi’s Links</span>
                </div>
                <div className="other-profile-hashtags-more-btn-sec">
                    <Link to="#">
                        More
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            fill="none"
                            viewBox="0 0 6 9"
                            >
                            <path
                                stroke="#000"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.3"
                                d="M1.168 1.42l3.331 3.262-3.331 3.26"
                            ></path>
                        </svg>
                    </Link>
                </div>
            </div>
            <div className="other-profile-link-tree-body-sec">
                <div className="other-profile-link-tree-card">
                    <Link to="#" className="other-profile-link-tree-btn">
                        <Image
                            className="other-profile-link-tree-link-icon"
                            src={window.location.origin + "/assets/img/other-profile/ravi's-link-icon.png"}
                        />
                        <span>Fav Song of Oct! 🎤</span>
                    </Link>
                    <div className="other-profile-link-tree-important-count">
                        <span>1</span>
                        <Image
                            className="other-profile-link-tree-link-important-icon"
                            src={window.location.origin + "/assets/img/other-profile/ravi's-link-icon-important.png"}
                        />
                    </div>
                </div>
                <div className="other-profile-link-tree-card">
                    <Link to="#" className="other-profile-link-tree-btn">
                        <Image
                            className="other-profile-link-tree-link-icon"
                            src={window.location.origin + "/assets/img/other-profile/ravi's-link-icon.png"}
                        />
                        <span>Skincare 🛍️ Picked For You From All New Products…</span>
                    </Link>
                    <div className="other-profile-link-tree-important-count">
                        <span>1</span>
                        <Image
                            className="other-profile-link-tree-link-important-icon"
                            src={window.location.origin + "/assets/img/other-profile/ravi's-link-icon-important.png"}
                        />
                    </div>
                </div>
                <div className="other-profile-link-tree-card">
                    <Link to="#" className="other-profile-link-tree-btn">
                        <Image
                            className="other-profile-link-tree-link-icon"
                            src={window.location.origin + "/assets/img/other-profile/ravi's-link-icon.png"}
                        />
                        <span>Netflix Watchlist 👀</span>
                    </Link>
                    <div className="other-profile-link-tree-important-count">
                        <span>2</span>
                        <Image
                            className="other-profile-link-tree-link-important-icon"
                            src={window.location.origin + "/assets/img/other-profile/ravi's-link-icon-important.png"}
                        />
                    </div>
                </div>
                <div className="other-profile-link-tree-card">
                    <Link to="#" className="other-profile-link-tree-btn">
                        <Image
                            className="other-profile-link-tree-link-icon"
                            src={window.location.origin + "/assets/img/other-profile/ravi's-link-icon.png"}
                        />
                        <span>My Latest Travel Vlog - COPENHAGEN PART I</span>
                    </Link>
                    <div className="other-profile-link-tree-important-count">
                        <span>3</span>
                        <Image
                            className="other-profile-link-tree-link-important-icon"
                            src={window.location.origin + "/assets/img/other-profile/ravi's-link-icon-important.png"}
                        />
                    </div>
                </div>
                <div className="other-profile-link-tree-card">
                    <Link to="#" className="other-profile-link-tree-btn">
                        <Image
                            className="other-profile-link-tree-link-icon"
                            src={window.location.origin + "/assets/img/other-profile/ravi's-link-icon.png"}
                        />
                        <span>Skincare 🛍️ Picked For You From All New Products…</span>
                    </Link>
                    <div className="other-profile-link-tree-important-count">
                        <span>1</span>
                        <Image
                            className="other-profile-link-tree-link-important-icon"
                            src={window.location.origin + "/assets/img/other-profile/ravi's-link-icon-important.png"}
                        />
                    </div>
                </div>
                <div className="other-profile-link-tree-card">
                    <Link to="#" className="other-profile-link-tree-btn">
                        <Image
                            className="other-profile-link-tree-link-icon"
                            src={window.location.origin + "/assets/img/other-profile/ravi's-link-icon.png"}
                        />
                        <span>Netflix Watchlist 👀</span>
                    </Link>
                    <div className="other-profile-link-tree-important-count">
                        <span>1</span>
                        <Image
                            className="other-profile-link-tree-link-important-icon"
                            src={window.location.origin + "/assets/img/other-profile/ravi's-link-icon-important.png"}
                        />
                    </div>
                </div>
                <div className="other-profile-link-tree-card">
                    <Link to="#" className="other-profile-link-tree-btn">
                        <Image
                            className="other-profile-link-tree-link-icon"
                            src={window.location.origin + "/assets/img/other-profile/ravi's-link-icon.png"}
                        />
                        <span>My Latest Travel Vlog - COPENHAGEN PART I</span>
                    </Link>
                    <div className="other-profile-link-tree-important-count">
                        <span>1</span>
                        <Image
                            className="other-profile-link-tree-link-important-icon"
                            src={window.location.origin + "/assets/img/other-profile/ravi's-link-icon-important.png"}
                        />
                    </div>
                </div>
                <div className="other-profile-link-tree-card">
                    <Link to="#" className="other-profile-link-tree-btn">
                        <Image
                            className="other-profile-link-tree-link-icon"
                            src={window.location.origin + "/assets/img/other-profile/ravi's-link-icon.png"}
                        />
                        <span>My Latest Travel Vlog - BERLIN PART I</span>
                    </Link>
                    <div className="other-profile-link-tree-important-count">
                        <span>1</span>
                        <Image
                            className="other-profile-link-tree-link-important-icon"
                            src={window.location.origin + "/assets/img/other-profile/ravi's-link-icon-important.png"}
                        />
                    </div>
                </div>
                <div className="other-profile-link-tree-card">
                    <Link to="#" className="other-profile-link-tree-btn">
                        <Image
                            className="other-profile-link-tree-link-icon"
                            src={window.location.origin + "/assets/img/other-profile/ravi's-link-icon.png"}
                        />
                        <span>My Latest Travel Vlog - AMSTERDAM PART I</span>
                    </Link>
                    <div className="other-profile-link-tree-important-count">
                        <span>1</span>
                        <Image
                            className="other-profile-link-tree-link-important-icon"
                            src={window.location.origin + "/assets/img/other-profile/ravi's-link-icon-important.png"}
                        />
                    </div>
                </div>
                <div className="other-profile-link-tree-card">
                    <Link to="#" className="other-profile-link-tree-btn">
                        <Image
                            className="other-profile-link-tree-link-icon"
                            src={window.location.origin + "/assets/img/other-profile/ravi's-link-icon.png"}
                        />
                        <span>Netflix Watchlist 👀</span>
                    </Link>
                    <div className="other-profile-link-tree-important-count">
                        <span>2</span>
                        <Image
                            className="other-profile-link-tree-link-important-icon"
                            src={window.location.origin + "/assets/img/other-profile/ravi's-link-icon-important.png"}
                        />
                    </div>
                </div>
                <div className="other-profile-link-tree-card">
                    <Link to="#" className="other-profile-link-tree-btn">
                        <Image
                            className="other-profile-link-tree-link-icon"
                            src={window.location.origin + "/assets/img/other-profile/ravi's-link-icon.png"}
                        />
                        <span>My Latest Travel Vlog - COPENHAGEN PART I</span>
                    </Link>
                    <div className="other-profile-link-tree-important-count">
                        <span>2</span>
                        <Image
                            className="other-profile-link-tree-link-important-icon"
                            src={window.location.origin + "/assets/img/other-profile/ravi's-link-icon-important.png"}
                        />
                    </div>
                </div>
            </div>
        </div>
      </div>
    </>
  )
}

export default OtherProfileLinkIndex;
