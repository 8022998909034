import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="auth-right-footer-sec">
      <ul className="list-unstyled auth-footer-link-list">
        <li>
          <Link to="#">About</Link>
        </li>
        <li>
          <Link to="#">Contact</Link>
        </li>
        <li>
          <Link to="#">Privacy</Link>
        </li>
        <li>
          <Link to="#">Terms</Link>
        </li>
        <li>
          <Link to="#">Help</Link>
        </li>
      </ul>
    </div>
  );
};

export default Footer;
