import { takeLatest, all } from "redux-saga/effects";
import { getCommonSaga } from "../sagaUtils";
import store from "../index";
import {
  verifyMobileSuccess,
  verifyMobileFailure,
  regenerateMobileVerificationSuccess,
  regenerateMobileVerificationFailure,
  onboardingUpdateSuccess,
  onboardingUpdateFailure,
  usernameValidationSuccess,
  usernameValidationFailure,
  onboardingCategoriesSuccess,
  onboardingCategoriesFailure,
  updateOnboardingCategoriesSuccess,
  updateOnboardingCategoriesFailure,
  sendEmailInvitationSuccess,
  sendEmailInvitationFailure,
} from "../slices/OnBoardingSlice";
import {
	fetchUserDetailsSuccess
} from "../slices/UserSlice";
import { walletAppBaseUrl } from "../../Environment";

function* verifyMobileAPI(action) {
  yield getCommonSaga({
    apiUrl: "verify_mobile",
    baseUrl: walletAppBaseUrl,
    payload: action.payload,
    successCallback: true,
    success: (data) => {
			localStorage.setItem("userId", data.user.user_id);
      localStorage.setItem("accessToken", data.user.token);
      localStorage.setItem("userLoginStatus", true);
      localStorage.setItem("user_picture", data.user.picture);
      localStorage.setItem("name", data.user.name);
      localStorage.setItem("user_unique_id", data.user.user_unique_id);
      localStorage.removeItem("email");
      localStorage.removeItem("mobile");
      store.dispatch(verifyMobileSuccess(data));
      store.dispatch(fetchUserDetailsSuccess(data.user));
    },
    failure: verifyMobileFailure,
  });
}
function* regenerateMobileVerificationAPI(action) {
  yield getCommonSaga({
    apiUrl: "regenerate_mobile_verification_code",
    baseUrl: walletAppBaseUrl,
    payload: action.payload,
    successCallback: true,
    success: (data) =>
      store.dispatch(regenerateMobileVerificationSuccess(data)),
    failure: regenerateMobileVerificationFailure,
  });
}

function* onboardingUpdateAPI(action) {
  yield getCommonSaga({
    apiUrl: "update_onboarding",
    baseUrl: walletAppBaseUrl,
    payload: action.payload,
		successCallback: true,
    success: (data) => {
      store.dispatch(onboardingUpdateSuccess(data));
      store.dispatch(fetchUserDetailsSuccess(data));
    },
    failure: onboardingUpdateFailure,
  });
}

function* usernameValidationAPI(action) {
  yield getCommonSaga({
    apiUrl: "username_validation",
    baseUrl: walletAppBaseUrl,
    payload: action.payload,
		successNotify: false,
		errorNotify: false,
    success: usernameValidationSuccess,
    failure: (data) => store.dispatch(usernameValidationFailure(data)),
  });
}

function* onboardingCategoriesAPI(action) {
  yield getCommonSaga({
    apiUrl: "onboarding_categories",
    baseUrl: walletAppBaseUrl,
    payload: action.payload,
    success: onboardingCategoriesSuccess,
    failure: onboardingCategoriesFailure,
  });
}

function* updateOnboardingCategoriesAPI(action) {
  yield getCommonSaga({
    apiUrl: "onboarding_categories_update",
    baseUrl: walletAppBaseUrl,
    payload: action.payload,
		successCallback: true,
    success: (data) => {
      store.dispatch(updateOnboardingCategoriesSuccess(data));
      store.dispatch(fetchUserDetailsSuccess(data));
    },
    failure: updateOnboardingCategoriesFailure,
  });
}

function* sendEmailInvitationAPI(action) {
  yield getCommonSaga({
    apiUrl: "send_email_invitation",
    baseUrl: walletAppBaseUrl,
    payload: action.payload,
    success: sendEmailInvitationSuccess,
    failure: sendEmailInvitationFailure,
  });
}

export default function* OnBoardSaga() {
  yield all([
    yield takeLatest("onboard/verifyMobileStart", verifyMobileAPI),
    yield takeLatest(
      "onboard/regenerateMobileVerificationStart",
      regenerateMobileVerificationAPI
    ),
    yield takeLatest("onboard/onboardingUpdateStart", onboardingUpdateAPI),
    yield takeLatest(
      "onboard/usernameValidationStart",
      usernameValidationAPI
    ),
    yield takeLatest(
      "onboard/onboardingCategoriesStart",
      onboardingCategoriesAPI
    ),
    yield takeLatest(
      "onboard/updateOnboardingCategoriesStart",
      updateOnboardingCategoriesAPI
    ),
    yield takeLatest(
      "onboard/sendEmailInvitationStart",
      sendEmailInvitationAPI
    ),
  ]);
}
