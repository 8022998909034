import React from "react";
import Base from "./components/Base";
import { Provider } from "react-redux";
import store from "./store";
import { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import "./components/Auth/Auth.css";
import "./components/OnBoarding/OnBoarding.css";
import "./components/Profile/Profile.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./components/Account/Account.css";
import configuration from "react-global-configuration";
import { settingsUrl } from "./Environment";
import TooManyRequest from "./components/404/TooManyRequest";
import 'react-loading-skeleton/dist/skeleton.css'

function App() {
  const [configLoading, setConfigLoading] = useState(true);
  const [configError, setConfigError] = useState(null);

  useEffect(() => {
    fetchConfig();
  }, []);

  const fetchConfig = async () => {
    try {
      const response = await fetch(settingsUrl);

      const configValue = await response.json();

      configuration.set({ configData: configValue.data }, { freeze: false });
      setConfigLoading(false);
      setConfigError(false)
    } catch (error) {
      configuration.set({ configData: [] }, { freeze: false });
      setConfigLoading(false);
      setConfigError(true)
    }
  };

  return (
    <Provider store={store}>
      <ToastContainer />
      {configLoading ? null : configError == null ? null : configError ? (
        <TooManyRequest />
      ) : (
        <Base />
      )}
    </Provider>
  );
}

export default App;
