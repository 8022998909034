import React, { useEffect, useState } from "react";
import { Form, Image, Button, Modal } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import OtpInput from "react-otp-input";
import {
  verifyMobileStart,
  regenerateMobileVerificationStart,
} from "../../store/slices/OnBoardingSlice";
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const PhoneVerificationModal = (props) => {
  const t = useTranslation("phone_verify");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const verifyMobile = useSelector((state) => state.onboard.verifyMobile);
  const regenerateMobileVerification = useSelector(
    (state) => state.onboard.regenerateMobileVerification
  );
  const [mobile, setMobile] = useState(props.mobile);
  const [skipRender, setSkipRender] = useState(true);

  const handleChange = (otp) => setOtp(otp);

  const resendMobileVerificationCode = () => {
    dispatch(
      regenerateMobileVerificationStart({
        mobile: mobile,
      })
    );
  };

  const verifyMobileCode = (e) => {
    e.preventDefault();
    dispatch(
      verifyMobileStart({
        mobile: mobile,
        mobile_verification_code: otp,
      })
    );
  };

  useEffect(() => {
    if (
      !skipRender &&
      !verifyMobile.loading &&
      Object.keys(verifyMobile.data).length > 0
    ) {
      navigate("/view-private-key");
    }
    setSkipRender(false);
  }, [verifyMobile]);

  return (
    <Modal
      className="modal-dialog-center phone-number-verification"
      size="md"
      centered
      show={true}
      onHide={props.closeNumberVerificationModal}
    >
      <Modal.Body>
        <h2>{t("your_verification_code")}</h2>
        <p>{t("sent_para")}</p>

        <Button
          className="modal-close"
          onClick={() => props.closePhoneVerificationModal()}
        >
          <Image
            className="close-icon"
            src={window.location.origin + "/assets/img/modal-close.svg"}
          />
        </Button>
        <div className="phone-number-verification-sec">
          <Form onSubmit={verifyMobileCode}>
            <div className="verification-item">
              <h4>{t("enter_your_code_here")}</h4>
              <OtpInput
                value={otp}
                onChange={handleChange}
                numInputs={6}
                separator={<span>-</span>}
                renderInput={(props) => <input {...props} />}
              />
            </div>
            <div className="verification-btn-sec">
              <span>{t("havent_received_the_code")}</span>
              <Link
                to="#"
                onClick={resendMobileVerificationCode}
                className="resend-link"
              >
                {regenerateMobileVerification.buttonDisable
                  ? t("loading")
                  : t("resend")}
              </Link>
            </div>
            <div className="verify-btn-sec">
              <Button
                type="submit"
                className="default-btn"
                disabled={!otp || otp.length < 6 || verifyMobile.buttonDisable}
              >
                {verifyMobile.buttonDisable ? t("loading") : t("verify")}
              </Button>
            </div>
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PhoneVerificationModal;
