import UserSlice from "./UserSlice";
import OnBoardingSlice from "./OnBoardingSlice";
import NftSlice from "./NftSlice";
import LinkTreeSlice from "./LinkTreeSlice";

export const reducers = {
    user: UserSlice,
    onboard: OnBoardingSlice,
    nft: NftSlice,
    linkTree: LinkTreeSlice,
};