import React, { useEffect, useState } from "react";
import { Form, Image, Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

const ChangePasswordModel = (props) => {
  return (
    <Modal
      className="modal-dialog-center phone-number-verification"
      size="md"
      centered
      show={props.changePasswordModel}
      onHide={() => props.closeChangePasswordModel()}
    >
      <Modal.Body>
        <h2>Change Password Confirmation</h2>
        <p>
          You will receive a reset password link to your registered email, where
          you can reset your password
        </p>

        <Button
          className="modal-close"
          onClick={() => props.closeChangePasswordModel()}
        >
          <Image
            className="close-icon"
            src={window.location.origin + "/assets/img/modal-close.svg"}
          />
        </Button>
        <div className="phone-number-verification-sec">
          <div className="verification-btn-sec">
            <span>Are you sure you want to change password?</span>
          </div>
          <div className="verify-btn-sec">
            <Button
              className="default-btn"
              disabled={props.btnLoading}
              onClick={() => props.handleChangePassword()}
            >
              {props.btnLoading ? "Loading" : "Change Password"}
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ChangePasswordModel;
