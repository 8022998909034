import React, { useEffect, useState } from "react";
import { Row, Col, Image, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { getSuccessNotificationMessage } from "../Helper/NotificationMessage";
import CopyToClipboard from "react-copy-to-clipboard";
import { useSelector } from "react-redux";
import Footer from "../Layout/Footer/Footer";

const ViewPrivateKey = () => {
  const navigate = useNavigate();
  const [key, setKey] = useState("");
  const register = useSelector((state) => state.user.register);
  const verifyMobile = useSelector((state) => state.onboard.verifyMobile);
  const auth0Login = useSelector((state) => state.user.auth0Login);

  // useEffect(() => {
  //   if (
  //     (Object.keys(verifyMobile.data).length > 0 &&
  //       verifyMobile.data.private_passphrase) ||
  //     (Object.keys(register.data).length > 0 &&
  //       register.data.data.private_passphrase)
  //   ) {
  //     setKey(
  //       Object.keys(verifyMobile.data).length > 0
  //         ? verifyMobile.data.private_passphrase
  //         : register.data.data.private_passphrase
  //     );
  //   } else {
  //     navigate("/");
  //   }
  // }, []);

  useEffect(() => {
      if (
        (Object.keys(auth0Login.data).length > 0 &&
          auth0Login.data.private_passphrase && auth0Login.data.private_passphrase != "")
      ) {
        setKey(
          Object.keys(auth0Login.data).length > 0
            ? auth0Login.data.private_passphrase
            : ""
        );
      } else {
        navigate("/");
      }
    }, []);

  const onCopy = () => {
    getSuccessNotificationMessage("Private key copied");
  };

  const onNext = () => {
    navigate("/profile");
  };

  return (
    <>
      <div className="auth-sec">
        <div className="auth-left-sec">
          <div className="auth-left-img-sec">
            <Image
              className="auth-left-img"
              src={
                window.location.origin + "/assets/img/auth/login-left-img.png"
              }
            />
          </div>
        </div>
        <div className="auth-right-sec">
          <div className="auth-right-box">
            <div className="auth-right-card">
              <div className="auth-form-sec">
                <Row className="justify-content-md-center">
                  <Col md={12} xl={8} lg={8}>
                    <div className="auth-logo-img-sec">
                      <Image
                        className="auth-logo-img"
                        src={
                          window.location.origin + "/assets/img/auth/logo.svg"
                        }
                      />
                    </div>
                    <div className="sign-in-sec mt-4">
                      <div className="view-privte-key-sec">
                        <div className="onboarding-section-title">
                          <h2>Private Key</h2>
                        </div>
                        <p className="private-key-warning">
                          <span>Warning: </span> Never disclose this key. Anyone
                          with your private keys can steal any assets held in
                          your account. Kindly store it in a secure location.
                        </p>
                      </div>
                      <div className="private-key-content">
                        <h5>Copy your key:</h5>
                        <div className="private-key-box">
                          <p>{key}</p>
                          <div className="private-key-btn">
                            <CopyToClipboard text={key} onCopy={() => onCopy()}>
                              <Button>
                                <Image
                                  src={
                                    window.location.origin +
                                    "/assets/img/copy-icon.svg"
                                  }
                                />
                                <span>Copy</span>
                              </Button>
                            </CopyToClipboard>
                          </div>
                        </div>
                      </div>

                      <div className="login-btn mt-4">
                        <Button
                          className="default-btn"
                          onClick={() => onNext()}
                        >
                          Next
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewPrivateKey;
