import { createSlice } from "@reduxjs/toolkit";
import {
  getCommonInitialState,
  getCommonStart,
  getCommonSuccess,
  getCommonFailure,
} from "../utils";

const initialState = {
  login: getCommonInitialState(),
  auth0Login: getCommonInitialState(),
  register: getCommonInitialState(),
  forgotPassword: getCommonInitialState(),
  verifyForgotPassword: getCommonInitialState(),
  resetPassword: getCommonInitialState(),
  profile: getCommonInitialState(),
  profileInputData: getCommonInitialState(),
};

const UserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    //Login
    userLoginStart: (state) => {
      state.login = getCommonStart();
    },
    userLoginSuccess: (state, action) => {
      state.login = getCommonSuccess(action.payload);
    },
    userLoginFailure: (state, action) => {
      state.login = getCommonFailure(action.payload);
    },

    //Auth0Login
    userAuth0LoginStart: (state) => {
      state.auth0Login = getCommonStart();
    },
    userAuth0LoginSuccess: (state, action) => {
      state.auth0Login = getCommonSuccess(action.payload);
    },
    userAuth0LoginFailure: (state, action) => {
      state.auth0Login = getCommonFailure(action.payload);
    },

    //Register
    userRegisterStart: (state) => {
      state.register = getCommonStart();
    },
    userRegisterSuccess: (state, action) => {
      state.register = getCommonSuccess(action.payload);
    },
    userRegisterFailure: (state, action) => {
      state.register = getCommonFailure(action.payload);
    },

    // Forgot password
    forgotPasswordStart: (state) => {
      state.forgotPassword = getCommonStart();
    },
    forgotPasswordSuccess: (state, action) => {
      state.forgotPassword = getCommonSuccess(action.payload);
    },
    forgotPasswordFailure: (state, action) => {
      state.forgotPassword = getCommonFailure(action.payload);
    },

    // Verify Forgot password
    verifyForgotPasswordStart: (state) => {
      state.verifyForgotPassword = getCommonStart();
    },
    verifyForgotPasswordSuccess: (state, action) => {
      state.verifyForgotPassword = getCommonSuccess(action.payload);
    },
    verifyForgotPasswordFailure: (state, action) => {
      state.verifyForgotPassword = getCommonFailure(action.payload);
    },

    // Reset Password
    resetPasswordStart: (state) => {
      state.resetPassword = getCommonStart();
    },
    resetPasswordSuccess: (state, action) => {
      state.resetPassword = getCommonSuccess(action.payload);
    },
    resetPasswordFailure: (state, action) => {
      state.resetPassword = getCommonFailure(action.payload);
    },

    // Get user Details
    fetchUserDetailsStart: (state) => {
      state.profile = getCommonStart();
    },
    fetchUserDetailsSuccess: (state, action) => {
      state.profile = getCommonSuccess(action.payload);
    },
    fetchUserDetailsFailure: (state, action) => {
      state.profile = getCommonFailure(action.payload);
    },

    // Update profile
    updateUserDetailsStart: (state) => {
      state.profileInputData = getCommonStart();
    },
    updateUserDetailsSuccess: (state, action) => {
      state.profileInputData = getCommonSuccess(action.payload);
    },
    updateUserDetailsFailure: (state, action) => {
      state.profileInputData = getCommonFailure(action.payload);
    },
  },
});

export const {
  userLoginStart,
  userLoginSuccess,
  userLoginFailure,
  userAuth0LoginStart,
  userAuth0LoginSuccess,
  userAuth0LoginFailure,
  userRegisterStart,
  userRegisterSuccess,
  userRegisterFailure,
  forgotPasswordStart,
  forgotPasswordSuccess,
  forgotPasswordFailure,
  verifyForgotPasswordStart,
  verifyForgotPasswordSuccess,
  verifyForgotPasswordFailure,
  resetPasswordStart,
  resetPasswordSuccess,
  resetPasswordFailure,
  fetchUserDetailsStart,
  fetchUserDetailsSuccess,
  fetchUserDetailsFailure,
  updateUserDetailsStart,
  updateUserDetailsSuccess,
  updateUserDetailsFailure,
} = UserSlice.actions;

export default UserSlice.reducer;
