import React, { useState, useEffect } from "react";
import { Row, Col, Image, Container, Tab, Nav, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { linkTreeListStart } from "../../store/slices/LinkTreeSlice";
import LinksSkeleton from "../Account/LinksSkeleton";
import NoDataFound from "../Helper/NoDataFound";

const ProfileLinkIndex = (props) => {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.user.profile);
  const links = useSelector((state) => state.linkTree.links);

  useEffect(() => {
    dispatch(linkTreeListStart({ user_id: props.userId }));
  }, []);

  return (
    <>
      <div className="profile-link-tree-sec">
        <div className="other-profile-link-tree-box">
				<div className="other-profile-hashtags-header-sec">
                <div className="other-profile-hashtags-header-title-sec">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="27"
                    height="27"
                    fill="none"
                    viewBox="0 0 27 27"
                  >
                    <mask
                      id="mask0_2_28620"
                      style={{ maskType: "luminance" }}
                      width="27"
                      height="27"
                      x="0"
                      y="0"
                      maskUnits="userSpaceOnUse"
                    >
                      <path fill="#fff" d="M26.125.824h-26v26h26v-26z"></path>
                    </mask>
                    <g
                      stroke="#000"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.8"
                      mask="url(#mask0_2_28620)"
                    >
                      <path d="M12.482 6.929l2.238-2.407a4.731 4.731 0 016.884.172 5.671 5.671 0 01.16 7.413l-3.2 3.433a4.958 4.958 0 01-1.616 1.164 4.635 4.635 0 01-3.807 0 4.957 4.957 0 01-1.612-1.164"></path>
                      <path d="M13.77 20.713l-2.239 2.413a4.785 4.785 0 01-3.473 1.4 4.82 4.82 0 01-3.406-1.572 5.671 5.671 0 01-.16-7.413l3.196-3.428A4.957 4.957 0 019.3 10.944a4.635 4.635 0 013.806 0c.611.277 1.16.674 1.613 1.169"></path>
                    </g>
                  </svg>
                  <span>{profile.data.name}’s Links</span>
                </div>
                <div className="other-profile-hashtags-more-btn-sec">
                  <Link to="/add-link">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      fill="none"
                      viewBox="0 0 40 40"
                    >
                      <path
                        fill="#E0F8EB"
                        d="M20 40c11.046 0 20-8.954 20-20S31.046 0 20 0 0 8.954 0 20s8.954 20 20 20z"
                        opacity="0.8"
                      ></path>
                      <path
                        stroke="#0C0C0C"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M15.68 30.368h-5.13a.918.918 0 01-.917-.918v-5.13a.907.907 0 01.264-.643l13.77-13.77a.918.918 0 011.309 0l5.118 5.118a.918.918 0 010 1.308l-13.771 13.77a.906.906 0 01-.642.265zM20.65 12.925l6.426 6.427"
                      ></path>
                    </svg>
                  </Link>
                </div>
              </div>
          {links.loading ? (
            <LinksSkeleton />
          ) : Object.keys(links.data).length > 0 &&
					links.data.links.length > 0 ? (
            <>
              <div className="other-profile-link-tree-body-sec">
								{links.data.links.map((link) => (
                <div className="other-profile-link-tree-card">
                  <Link to={link.website_link} target="_blank" className="other-profile-link-tree-btn">
                    <Image
                      className="other-profile-link-tree-link-icon"
                      src={link.picture ? link.picture :
                        window.location.origin +
                        "/assets/img/other-profile/ravi's-link-icon.png"
                      }
                    />
                    <span>{link.name}</span>
                    <div className="profile-link-pin-btn-sec">
                      <Button className="profile-link-pin-btn">
                        {/* <Image
                          className="other-profile-link-tree-link-icon"
                          src={
                            window.location.origin +
                            "/assets/img/other-profile/pin-link-icon.svg"
                          }
                        /> */}
                      </Button>
                    </div>
                  </Link>
                  <div className="other-profile-link-tree-important-count">
                    <Image
                      className="other-profile-link-tree-link-important-icon"
                      src={link.status ?
                        window.location.origin +
                        "/assets/img/other-profile/eye-icon-profile.svg"
												: window.location.origin +
                                "/assets/img/other-profile/eye-close-icon-profile.svg"
                      }
                    />
                  </div>
                </div>
								))}
              </div>
            </>
          ) : <NoDataFound />}
        </div>
      </div>
    </>
  );
};

export default ProfileLinkIndex;
