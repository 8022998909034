import React from "react";
import Skeleton from "react-loading-skeleton";
import { Row, Col, Image, Container, Tab, Nav, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

const LinksSkeleton = () => {
  return (
    <>
      {/* <div className="other-profile-hashtags-header-sec">
        <div className="other-profile-hashtags-header-title-sec">
          <Skeleton count={1} circle={true} width={30} height={30} />
          <span>
            <Skeleton count={1} width={150} height={30} />
          </span>
        </div>
        <div className="other-profile-hashtags-more-btn-sec">
          <Link to="#">
            <Skeleton count={1} circle={true} width={30} height={30} />
          </Link>
        </div>
      </div> */}
      <div className="other-profile-link-tree-body-sec">
        {[...Array(3)].map((i) => (
          <div className="other-profile-link-tree-card">
            <Link to="#" className="other-profile-link-tree-link">
              <Skeleton
                className="other-profile-link-tree"
                count={1}
                height={50}
              />
            </Link>
            <div className="other-profile-link-tree-important-count">
              <Skeleton
                md={12}
                xl={5}
                lg={12}
                count={1}
                circle={true}
                width={50}
                height={50}
              />
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default LinksSkeleton;
