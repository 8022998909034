import React, { useEffect, useState, useRef } from "react";
import { Container, Row, Col, Form, Image, Button, InputGroup, FormGroup, Modal } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import 'react-phone-number-input/style.css';
import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
  isPossiblePhoneNumber,
} from 'react-phone-number-input';
import PhoneVerificationModal from "./PhoneVerificationModal";
import { userRegisterStart } from "../../store/slices/UserSlice";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useTranslation } from 'react-multi-lang';
import { useDispatch, useSelector } from 'react-redux';
import Footer from "../Layout/Footer/Footer";
import { getErrorNotificationMessage } from "../../components/Helper/NotificationMessage";
const auth0 = window.auth0;

const RegisterIndex = () => {

  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const t = useTranslation("register");
  const formRef = useRef(null);
  const register = useSelector(state => state.user.register);
  const [skipRender, setSkipRender] = useState(true);
  const [numberVerification, setNumberVerification] = useState(false);
  const [privacyAgreed, setPrivacyAgreed] = useState(false);
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirm: false,
  });
  const [email, setEmail] = useState(
    localStorage.getItem("join_email") ? localStorage.getItem("join_email") : ""
  );
  const [registerBtnLoading, setRegisterBtnLoading] = useState(false);

  const options = {
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
    redirectUri: window.location.origin + "/auth-login",
    responseType: "token",
  };

  const closePhoneVerificationModal = () => {
    setNumberVerification(null);
  };

  useEffect(() => {
    localStorage.removeItem("join_email");
  }, []);

  useEffect(() => {
    if (!skipRender && !register.loading && Object.keys(register.data).length > 0) {
      formRef.current.resetForm();
      if (register.data.user.onboarding_step === 1) {
        setNumberVerification(register.data.user.mobile);
      } else {
        navigate("/view-private-key");
      }
    }
    setSkipRender(false);
  }, [register]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("name.required")).matches(/^[a-zA-Z0-9\s\.]+$/g, t("name.invalid")),
    email: Yup.string().required(t("email.required")).email(t("email.invalid")),
    mobile: Yup.string().required(t("mobile.required")),
    password: Yup.string().required(t("password.required")),
    confirm_password: Yup.string().required(t("confirm_password.required"))
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          t("confirm_password.invalid")
        ),
      }),
  });

  const validatePassword = (value) => {
    let error;
    if (!value) {
      error = "Required";
    } else if (value.length < 6) {
      error = "At least 6 letters";
    } else {
      let trimmed = value.replaceAll(" ", "");
      if (trimmed !== value) {
        error = "No space allowed";
      }
    }
    return error;
  };

  const validateMobileNumber = (value) => {
    if (value) {
      if (!isPossiblePhoneNumber(value)) {
        formRef.current.setFieldError("mobile", t("not_a_phone_number"));
        return false;
      } else if (!isValidPhoneNumber(value)) {
        formRef.current.setFieldError("mobile", t("invalid_number"));
        return false;
      } else {
        // formRef.current.setFieldError("mobile", t("invalid_number"));
        return true;
      }
    } else {
      formRef.current.setFieldError("mobile", t("required"));
      return false;
    }
  };

  const registerInput = async (values) => {
    setRegisterBtnLoading(true);
    if (validateMobileNumber(values.mobile)) {
      const intlNo = formatPhoneNumberIntl(values.mobile);
      const countryCode = intlNo.substring(
        intlNo.indexOf("+") + 1,
        intlNo.indexOf(" ")
      );
      const mobile = intlNo
        .substring(intlNo.indexOf(" "), intlNo.length)
        .replaceAll(" ", "");
      const webAuth = new auth0.WebAuth(options);
      webAuth.signup({ 
        connection: 'Username-Password-Authentication', 
        email: values.email, 
        password: values.password,
        name: values.name,
        onRedirecting: function (done) {
          console.log("On redirecting..");
          done();
          // dispatch(userLoginStart({ ...values, ...loginData }));
        },
      }, function (err) {
        if (err) {
          console.error(err);
          getErrorNotificationMessage(
            err.policy ?? "Something went wrong"
          );
        } else {
          const state = randomString(10);
          const nonce = randomString(10);
          localStorage.setItem("state", state);
          localStorage.setItem("nonce", nonce);
          webAuth.login(
            {
              responseType: "token",
              username: values.email,
              password: values.password,
              state: state,
              nonce: nonce,
              realm: "Username-Password-Authentication",
              onRedirecting: function (done) {
                console.log("On redirecting..");
                done();
                // dispatch(userLoginStart({ ...values, ...loginData }));
              },
            },
            function (err) {
              setRegisterBtnLoading(false);
              console.error(err);
              getErrorNotificationMessage(
                err.error_description ?? "Something went wrong"
              );
            }
          );
        };
        setRegisterBtnLoading(false);
      });
    } else
        setRegisterBtnLoading(false)
  };

  const randomString = (length) => {
    var charset =
      "0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz+/";
    let result = "";

    while (length > 0) {
      var bytes = new Uint8Array(16);
      var random = window.crypto.getRandomValues(bytes);

      random.forEach(function (c) {
        if (length == 0) {
          return;
        }
        if (c < charset.length) {
          result += charset[c];
          length--;
        }
      });
    }
    return result;
  };

  return (
    <>
      <div className="auth-sec">
        <div className="auth-left-sec">
          <div className="auth-left-img-sec">
            <Image
              className="auth-left-img"
              src={
                window.location.origin + "/assets/img/auth/login-left-img.png"
              }
            />
          </div>
        </div>
        <div className="auth-right-sec">
          <div className="auth-right-box">
            <div className="auth-right-card">
              <div className="auth-form-sec">
                <Row className="justify-content-md-center">
                  <Col md={12} xl={8} lg={8}>
                    <div className="auth-logo-img-sec">
                      <Image
                        className="auth-logo-img"
                        src={
                          window.location.origin + "/assets/img/auth/logo.svg"
                        }
                      />
                    </div>
                    <div className="login-nav-link">
                      <ul>
                        <li
                          className={
                            location.pathname === "/" ? "active" : ""
                          }
                        >
                          <Link to="/">{t("sign_in")}</Link>
                        </li>
                        <li
                          className={
                            location.pathname === "/register" ? "active" : ""
                          }
                        >
                          <Link to="/register">{t("sign_up")}</Link>
                        </li>
                      </ul>
                    </div>
                    <Formik
                      initialValues={{
                        name: "",
                        email: email,
                        mobile: "",
                        password: "",
                        confirm_password: "",
                      }}
                      validationSchema={validationSchema}
                      onSubmit={(values) => registerInput(values)}
                      innerRef={formRef}
                    >
                      {({ errors, values, touched, setFieldValue }) => (
                        <FORM>
                          <div className="sign-in-sec mt-4">
                            <FormGroup
                              className="mb-4"
                            >
                              <Form.Label>{t("name.label")} *</Form.Label>
                              <InputGroup className={`${touched.name && errors.name ? "is-invalid" : ""
                                }`}>
                                <InputGroup.Text>
                                  <Image
                                    src={
                                      window.location.origin +
                                      "/assets/img/auth/user.svg"
                                    }
                                  />
                                </InputGroup.Text>
                                <Field className={`form-control ${touched.name && errors.name
                                  ? "is-invalid"
                                  : ""
                                  }`}
                                  type="text"
                                  placeholder={t("name.placeholder")}
                                  name="name"
                                />
                              </InputGroup>
                              <ErrorMessage
                                component={"div"}
                                name="name"
                                className="text-danger text-right"
                              />
                            </FormGroup>
                            <FormGroup
                              className="mb-4"
                            >
                              <Form.Label>{t("email.label")} *</Form.Label>
                              <InputGroup className={`mb-0 ${touched.email && errors.email
                                ? "is-invalid"
                                : ""
                                }`}>
                                <InputGroup.Text>
                                  <Image
                                    src={
                                      window.location.origin +
                                      "/assets/img/auth/email.svg"
                                    }
                                  />
                                </InputGroup.Text>
                                <Field
                                  className={`form-control ${touched.email && errors.email
                                    ? "is-invalid"
                                    : ""
                                    }`}
                                  type="email"
                                  placeholder={t("email.placeholder")}
                                  name="email"
                                />
                              </InputGroup>
                              <ErrorMessage
                                component={"div"}
                                name="email"
                                className="text-right"
                              />
                            </FormGroup>
                            <FormGroup
                              className="mb-4"
                            >
                              <Form.Label>{t("mobile.label")} *</Form.Label>
                              <PhoneInput
                                defaultCountry="IN"
                                placeholder="Enter phone number"
                                value={values.mobile}
                                onChange={(value) =>
                                  setFieldValue("mobile", value)
                                }
                                onBlur={() => validateMobileNumber(values.mobile)}
                                international
                                countryCallingCodeEditable={false}
                                className={`${touched.mobile && errors.mobile
                                  ? "is-invalid"
                                  : ""
                                  }`}
                              />
                              <ErrorMessage
                                component={"div"}
                                name="mobile"
                                className="text-right"
                              />
                            </FormGroup>
                            <FormGroup
                              className="mb-4"
                            >
                              <div className="Forgot-password-link">
                                <Form.Label>{t("password.label")} *</Form.Label>
                              </div>
                              <InputGroup className={`${touched.password && errors.password ? "is-invalid" : ""}`}>
                                <InputGroup.Text>
                                  <Image
                                    src={
                                      window.location.origin +
                                      "/assets/img/auth/lock.svg"
                                    }
                                  />
                                </InputGroup.Text>
                                <Field
                                  className={`form-control ${touched.password && errors.password ? "is-invalid" : ""}`}
                                  type={showPassword.password ? "text" : "password"}
                                  placeholder={t("password.placeholder")}
                                  name="password"
                                  validate={validatePassword}
                                />
                                <InputGroup.Text
                                  onClick={() => setShowPassword({
                                    ...showPassword,
                                    password: !showPassword.password
                                  })}
                                >
                                  <Image
                                    src={window.location.origin + "/assets/img/auth/eye.svg"
                                    }
                                  />
                                </InputGroup.Text>
                              </InputGroup>
                              <ErrorMessage
                                component={"div"}
                                name="password"
                                className="text-right"
                              />
                            </FormGroup>

                            <FormGroup
                              className="mb-4"
                            >
                              <div className="Forgot-password-link">
                                <Form.Label> {t("confirm_password.label")} *</Form.Label>
                              </div>
                              <InputGroup className={`mb-3 ${touched.confirm_password && errors.confirm_password ? "is-invalid" : ""}`}>
                                <InputGroup.Text>
                                  <Image
                                    src={
                                      window.location.origin +
                                      "/assets/img/auth/lock.svg"
                                    }
                                  />
                                </InputGroup.Text>
                                <Field
                                  className={`form-control 
                                 ${touched.confirm_password && errors.confirm_password ? "is-invalid" : ""}
                                 `}
                                  type={showPassword.confirm ? "text" : "password"}
                                  placeholder={t("confirm_password.placeholder")}
                                  name="confirm_password"
                                />
                                <InputGroup.Text
                                  onClick={() => setShowPassword({
                                    ...showPassword,
                                    confirm: !showPassword.confirm,
                                  })}
                                >
                                  <Image
                                    src={window.location.origin + "/assets/img/auth/eye.svg"
                                    }
                                  />
                                </InputGroup.Text>
                              </InputGroup>
                              <ErrorMessage
                                component={"div"}
                                name="confirm_password"
                                className="text-right"
                              />
                            </FormGroup>
                            <Form.Group
                              className="mb-2 static-link-card"
                              controlId="terms"
                            >
                              <Form.Check
                                type="checkbox"
                                checked={termsAgreed}
                                onChange={() => setTermsAgreed(!termsAgreed)}
                                label={t("i_agree_to")}
                                name="terms"
                              />
                              <Link to="#">
                                {t("terms_of_use")}
                              </Link>
                            </Form.Group>
                            <Form.Group
                              className="mb-4 static-link-card"
                              controlId="privacy"
                            >
                              <Form.Check
                                type="checkbox"
                                checked={privacyAgreed}
                                onChange={() => setPrivacyAgreed(!privacyAgreed)}
                                label={t("i_agree_to")}
                                name="privacy"
                              />
                              <Link to="#">
                                {t("privacy_policy")}
                              </Link>
                            </Form.Group>
                            <div className="login-btn">
                              <Button
                                className="default-btn"
                                type="submit"
                                disabled={registerBtnLoading
                                  || !termsAgreed
                                  || !privacyAgreed
                                }
                              >
                                {registerBtnLoading ? t("register_btn.loading") : t("register_btn.text")}
                              </Button>
                            </div>
                            <div className="sign-up-link">
                              <h4>
                                {t("already_have_an_aaccount")}
                                <Link to="/">{t("sign_in")}</Link>
                              </h4>
                            </div>
                            {/* <div className="separtor-or-sec">
                              <span>{t("or")}</span>
                            </div>
                            <ul className="list-unstyled auth-social-link-sec">
                              <li>
                                <a href="#">
                                  <Image
                                    src={window.location.origin + "/assets/img/auth/google.svg"
                                    }
                                  />
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <Image
                                    src={window.location.origin + "/assets/img/auth/apple.svg"
                                    }
                                  />
                                </a>
                              </li>
                            </ul> */}
                          </div>
                        </FORM>
                      )}
                    </Formik>
                  </Col>
                </Row>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div >
      {numberVerification ?
        <PhoneVerificationModal
          mobile={numberVerification}
          closePhoneVerificationModal={closePhoneVerificationModal}
        />
        :
        null
      }
    </>
  )
}

export default RegisterIndex;