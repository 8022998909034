import React, { useState, useEffect } from "react";
import { Row, Col, Image, Container, Tab, Nav, Button, Form, InputGroup } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const PageLoaderIndex = (props) => {

  return (
    <>
      <div className="page-loader-sec">
        <Container>
          <Row>
            <Col md={12}>
              <div className="page-loader-box">
                <div className="page-loader-card">
                  {/* <Image
                                className="page-loader-img"
                                src={window.location.origin + "/assets/img/page-loader.gif"}
                            /> */}
                  <div className="spinner-3"></div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default PageLoaderIndex;
