import React, { useEffect, useState } from "react";
import { Form, Image, Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  linkTreeDeleteStart,
  linkTreeListStart
} from "../../store/slices/LinkTreeSlice";

const DeleteTheLinkModal = (props) => {

  const dispatch = useDispatch();
  const listDelete = useSelector((state) => state.linkTree.linkDelete);
  const profile = useSelector((state) => state.user.profile);

  const [skipRender, setSkipRender] = useState(true);

  useEffect(() => {
    if (
      !skipRender &&
      !listDelete.loading &&
      Object.keys(listDelete.data).length > 0
    ) {
      props.closeDeleteTheLinkModal();
      dispatch(linkTreeListStart({ user_id: profile.data.user_id }));
    }
    setSkipRender(false);
  }, [listDelete]);
  
  return (
    <Modal
      className="modal-dialog-center delete-thelink-modal"
      size="md"
      centered
      show={props.deleteTheLink}
      onHide={() => props.closeDeleteTheLinkModal()}
    >
      <Modal.Body>
        <h2>Are you sure you want to delete <br></br> this link?</h2>

        <Button
          className="modal-close"
          onClick={() => props.closeDeleteTheLinkModal()}
        >
          <Image
            className="close-icon"
            src={window.location.origin + "/assets/img/modal-close.svg"}
          />
        </Button>
        <div className="delete-the-link-modal-top-btn-sec">
            <Button className="delete-the-link-modal-yes-btn" 
            onClick={() =>
                dispatch(
                  linkTreeDeleteStart({
                    link_tree_id: props.link.link_tree_id,
                  })
                )
              }>
                {listDelete.buttonDisable ? "Loading" : "Yes, Delete It"}
            </Button>
        </div>
        <div className="delete-the-link-modal-bottom-btn-sec">
            <Button className="delete-the-link-modal-cancel-btn"  onClick={() => props.closeDeleteTheLinkModal()}>
                Cancel
            </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteTheLinkModal;
