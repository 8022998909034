import { createSlice } from "@reduxjs/toolkit";
import {
  getCommonInitialState,
  getCommonStart,
  getCommonSuccess,
  getCommonFailure,
} from "../utils";

const initialState = {
  links: getCommonInitialState(),
  linkSave: getCommonInitialState(),
  linkDelete: getCommonInitialState(),
  linkStatus: getCommonInitialState(),
};

const LinkTreeSlice = createSlice({
  name: "linkTree",
  initialState,
  reducers: {
    
    linkTreeListStart: (state) => {
      state.links = getCommonStart();
    },
    linkTreeListSuccess: (state, action) => {
      state.links = getCommonSuccess(action.payload);
    },
    linkTreeListFailure: (state, action) => {
      state.links = getCommonFailure(action.payload);
    },

    linkTreeSaveStart: (state) => {
      state.linkSave = getCommonStart();
    },
    linkTreeSaveSuccess: (state, action) => {
      state.linkSave = getCommonSuccess(action.payload);
    },
    linkTreeSaveFailure: (state, action) => {
      state.linkSave = getCommonFailure(action.payload);
    },

    linkTreeDeleteStart: (state) => {
      state.linkDelete = getCommonStart();
    },
    linkTreeDeleteSuccess: (state, action) => {
      state.linkDelete = getCommonSuccess(action.payload);
    },
    linkTreeDeleteFailure: (state, action) => {
      state.linkDelete = getCommonFailure(action.payload);
    },

    linkTreeStatusUpdateStart: (state) => {
      state.linkStatus = getCommonStart();
    },
    linkTreeStatusUpdateSuccess: (state, action) => {
      state.linkStatus = getCommonSuccess(action.payload);
    },
    linkTreeStatusUpdateFailure: (state, action) => {
      state.linkStatus = getCommonFailure(action.payload);
    },

  },
});

export const {
  linkTreeListStart,
  linkTreeListSuccess,
  linkTreeListFailure,
  linkTreeSaveStart,
  linkTreeSaveSuccess,
  linkTreeSaveFailure,
  linkTreeDeleteStart,
  linkTreeDeleteSuccess,
  linkTreeDeleteFailure,
  linkTreeStatusUpdateStart,
  linkTreeStatusUpdateSuccess,
  linkTreeStatusUpdateFailure,
} = LinkTreeSlice.actions;

export default LinkTreeSlice.reducer;
