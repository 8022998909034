import { createSlice } from "@reduxjs/toolkit";
import {
  getCommonInitialState,
  getCommonStart,
  getCommonSuccess,
  getCommonFailure,
} from "../utils";

const initialState = {
  nftUserProfileItemsList: getCommonInitialState(),
};

const NftSlice = createSlice({
  name: "nft",
  initialState,
  reducers: {
    //Nft User Profile Items List
    nftUserProfileItemsListStart: (state) => {
      state.nftUserProfileItemsList = getCommonStart();
    },
    nftUserProfileItemsListSuccess: (state, action) => {
      state.nftUserProfileItemsList = getCommonSuccess(action.payload);
    },
    nftUserProfileItemsListFailure: (state, action) => {
      state.nftUserProfileItemsList = getCommonFailure(action.payload);
    },
  },
});

export const {
  nftUserProfileItemsListStart,
  nftUserProfileItemsListSuccess,
  nftUserProfileItemsListFailure,
} = NftSlice.actions;

export default NftSlice.reducer;
