import React, { useState, useEffect } from "react";
import { Row, Col, Image, Container, Dropdown , Button} from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import NoDataFound from "../Helper/NoDataFound";
import OnBoardingHeader from "../OnBoarding/OnBoardingHeader";
import background from "./single-profile-bg.png";
import configuration from "react-global-configuration";
import Skeleton from "react-loading-skeleton";

const ProfileCard = (props) => {

  const profile = useSelector((state) => state.user.profile);

  const [formattedLink, setFormattedLink] = useState(
    configuration.get("configData.frontend_url").replace("https://", "")
  );

  const [profileImage, setProfileImage] = useState({
    preview: null,
    src: null
  });

  const imgPreview = (e) => {
    setProfileImage({
      preview: URL.createObjectURL(e.target.files[0]),
      src: e.target.files[0]
    });
  };

  return (
    <>
      <div className="vertical-center-relative">
      {profile.loading ? (
    <Skeleton className="account-box" height={500} />
  ) : Object.keys(profile.data).length > 0 ? (
    <>
        <div className="single-account-box">
          <div className="single-account-header-sec" style={{ backgroundImage: `url(${profile.data.cover ? profile.data.cover : background})` }}>
            <div className="single-account-header-card">
              <div className="single-profile-link-sec">
                <Link to="#">
                    <Image
                    src={window.location.origin + "/assets/img/account/tick.png"}
                    className="single-profile-link-tick-icon"
                    />
                    <span>{formattedLink}{profile.data.username}</span>
                </Link>
              </div>
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#00337D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-more-horizontal">
                    <circle cx="12" cy="12" r="1"></circle>
                    <circle cx="19" cy="12" r="1"></circle>
                    <circle cx="5" cy="12" r="1"></circle>
                  </svg>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item>
                    <Link to="/account" className="profile-dropdown-btn">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="#797878"
                        viewBox="0 0 24 24"
                      >
                        <path d="M18.656.93L6.464 13.122A4.966 4.966 0 005 16.657V18a1 1 0 001 1h1.343a4.966 4.966 0 003.535-1.464L23.07 5.344a3.125 3.125 0 000-4.414 3.194 3.194 0 00-4.414 0zm3 3L9.464 16.122A3.02 3.02 0 017.343 17H7v-.343a3.02 3.02 0 01.878-2.121L20.07 2.344a1.148 1.148 0 011.586 0 1.123 1.123 0 010 1.586z"></path>
                        <path d="M23 8.979a1 1 0 00-1 1V15h-4a3 3 0 00-3 3v4H5a3 3 0 01-3-3V5a3 3 0 013-3h9.042a1 1 0 000-2H5a5.006 5.006 0 00-5 5v14a5.006 5.006 0 005 5h11.343a4.968 4.968 0 003.536-1.464l2.656-2.658A4.968 4.968 0 0024 16.343V9.979a1 1 0 00-1-1zm-4.535 12.143a2.975 2.975 0 01-1.465.8V18a1 1 0 011-1h3.925a3.016 3.016 0 01-.8 1.464z"></path>
                      </svg>
                      Edit Profile
                    </Link>
                  </Dropdown.Item>
                  {/* <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                  <Dropdown.Item href="#/action-3">Something else</Dropdown.Item> */}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="single-account-dashboard-btn">
                <div className="account-profile-img-upload-sec">
                    <label>
                        <input type="file" size="60" onChange={(e) => imgPreview(e)}/>
                        <svg
                        xmlns="http://www.w3.org/2000/svg"
                        data-name="Layer 1"
                        viewBox="0 0 24 24"
                        width="30"
                        height="30"
                        fill="#00c75e"
                        >
                        <path d="M13.75 11.664l-3.457 3.457A3.02 3.02 0 018.172 16H8v-.172c0-.789.32-1.562.879-2.121l3.457-3.457 1.414 1.414zm1.043-3.871L13.75 8.836l1.414 1.414 1.043-1.043a1 1 0 00-1.414-1.414zM24 12c0 6.617-5.383 12-12 12S0 18.617 0 12 5.383 0 12 0s12 5.383 12 12zm-6.379-5.621a3.002 3.002 0 00-4.242 0l-5.914 5.914A4.968 4.968 0 006 15.828V17a1 1 0 001 1h1.172a4.968 4.968 0 003.535-1.465l5.914-5.914a3.002 3.002 0 000-4.242z"></path>
                        </svg>
                    </label> 
                </div>
            </div>
          </div>
          <div className="single-profile-user-details">
            <div className="single-profile-user-img-sec">
              <Image
                src={profile.data.picture ? profile.data.picture : window.location.origin + "/assets/img/account/profile-img.png"}
                className="single-profile-user-img"
              />
              <div className="other-profile-user-tick-sec">
                <Image
                    className="other-profile-user-tick-icon"
                    src={
                        window.location.origin +
                        "/assets/img/verified-icon.png"
                    }
                />
              </div>
              <div className="single-profile-preimum-title-sec">
                Premium
              </div>
            </div>
            <div className="other-profile-user-name-card">
                <h4>Ravi Singh</h4>
                <span>|</span>
                <h4>Fashion Blogger</h4>
            </div>
            <div className="profile-desc">
                <p>Owner of @TinyOfficial, Fashion Writer, Content Creator, Lifestyle Blogger</p>
            </div>
          </div>
          <div className="other-profile-user-follow-reward-btn-sec">
            <h4>2.120 <span>fans</span></h4>
          </div>
          <div className="total-audience-reach-badge">
            Total Audience Reach
          </div>
          <ul className="list-unstyled single-profile-post-count-list">
            <li>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="M22.773 7.721A4.994 4.994 0 0019 6h-3.989l.336-2.041a3.037 3.037 0 00-5.721-1.837L7.712 6H5a5.006 5.006 0 00-5 5v5a5.006 5.006 0 005 5h13.3a5.024 5.024 0 004.951-4.3l.705-5a5 5 0 00-1.183-3.979zM2 16v-5a3 3 0 013-3h2v11H5a3 3 0 01-3-3zm19.971-4.581l-.706 5A3.012 3.012 0 0118.3 19H9V7.734a1 1 0 00.23-.292l2.189-4.435a1.07 1.07 0 011.722-.207 1.024 1.024 0 01.233.84l-.528 3.2A1 1 0 0013.833 8H19a3 3 0 012.971 3.419z"></path>
              </svg>
              <span>1.8k likes</span>
            </li>
            <li>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                data-name="Layer 1"
                viewBox="0 0 24 24"
              >
                <path d="M17 19c0 2.76-2.24 5-5 5H5c-2.76 0-5-2.24-5-5v-7c0-2.76 2.24-5 5-5h1c.55 0 1 .45 1 1s-.45 1-1 1H5c-1.65 0-3 1.35-3 3v7c0 1.65 1.35 3 3 3h7c1.65 0 3-1.35 3-3 0-.55.45-1 1-1s1 .45 1 1zm6.13-13.11L17.72.3c-.38-.4-1.02-.41-1.41-.02s-.41 1.02-.02 1.41l5.14 5.3H13c-2.76 0-5 2.24-5 5v5c0 .55.45 1 1 1s1-.45 1-1v-5c0-1.65 1.35-3 3-3h8.42l-5.14 5.3a.996.996 0 00.72 1.69c.25 0 .52-.1.72-.3l5.4-5.57a2.997 2.997 0 00.01-4.23z"></path>
              </svg>
              <span>0 shares</span>
            </li>
            <li>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                data-name="Layer 1"
                viewBox="0 0 24 24"
              >
                <path d="M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12h12V12c0-6.617-5.383-12-12-12zm10 22H12C6.486 22 2 17.514 2 12S6.486 2 12 2s10 4.486 10 10v10zm-8.5-10a1.5 1.5 0 11-3.001-.001A1.5 1.5 0 0113.5 12zm5 0a1.5 1.5 0 11-3.001-.001A1.5 1.5 0 0118.5 12zm-10 0a1.5 1.5 0 11-3.001-.001A1.5 1.5 0 018.5 12z"></path>
              </svg>
              <span>245 comments</span>
            </li>
          </ul>
          <div className="single-profile-social-media-followers-sec other-profile-social-media-followers-sec">
            <div className="single-profile-social-media-followers-insta">
              <Image
                src={
                  window.location.origin +
                  "/assets/img/social-media/instagram.svg"
                }
                className="single-profile-social-media-follower-icon"
              />
              <span>16.3K</span>
            </div>
            <div className="single-profile-social-media-followers-tiktok">
              <Image
                src={
                  window.location.origin +
                  "/assets/img/account/tiktok-icon.svg"
                }
                className="single-profile-social-media-follower-icon"
              />
              <span>28</span>
            </div>
            <div className="single-profile-social-media-followers-twitter">
              <Image
                src={
                  window.location.origin +
                  "/assets/img/other-profile/twitter.svg"
                }
                className="single-profile-social-media-follower-icon"
              />
              <span>3k</span>
            </div>
            <div className="single-profile-social-media-followers-facebook">
              <Image
                src={
                  window.location.origin +
                  "/assets/img/other-profile/facebook.svg"
                }
                className="single-profile-social-media-follower-icon"
              />
              <span>1k</span>
            </div>
            <div className="single-profile-social-media-followers-linkedin">
              <Image
                src={
                  window.location.origin +
                  "/assets/img/other-profile/linkedin.svg"
                }
                className="single-profile-social-media-follower-icon"
              />
              <span>10k</span>
            </div>
            <div className="single-profile-social-media-followers-youtube">
              <Image
                src={
                  window.location.origin +
                  "/assets/img/other-profile/youtube.svg"
                }
                className="single-profile-social-media-follower-icon"
              />
              <span>2M</span>
            </div>
          </div>
          <div className="other-profile-points-box">
            <div className="other-profile-points-card">
              <div className="other-profile-points-icon-sec">
                <Image
                    className="other-profile-points-icon"
                    src={
                        window.location.origin +
                        "/assets/img/other-profile/points-thumb-icon.svg"
                    }
                />
              </div>
              <div className="other-profile-points-info-sec">
                <h4>50 Points</h4>
                <p>From every like</p>
              </div>
            </div>
            <div className="other-profile-points-card">
              <div className="other-profile-points-icon-sec">
                <Image
                    className="other-profile-points-icon"
                    src={
                        window.location.origin +
                        "/assets/img/other-profile/points-share-icon.svg"
                    }
                />
              </div>
              <div className="other-profile-points-info-sec">
                <h4>50 Points</h4>
                <p>From every like</p>
              </div>
            </div>
            <div className="other-profile-points-card">
              <div className="other-profile-points-icon-sec">
                <Image
                    className="other-profile-points-icon"
                    src={
                        window.location.origin +
                        "/assets/img/other-profile/points-available-icon.svg"
                    }
                />
              </div>
              <div className="other-profile-points-info-sec">
                <h4>50 Points</h4>
                <p>From every like</p>
              </div>
            </div>
          </div>
        </div>
        </>
  ) : (
    <NoDataFound />
  )}
      </div>
    </>
  )
}

export default ProfileCard;
