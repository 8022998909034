import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Image,
  Container,
  Button,
  Nav,
  Tab,
  Form,
  InputGroup,
  FormGroup,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import OnBoardingHeader from "../OnBoarding/OnBoardingHeader";
import Skeleton from "react-loading-skeleton";
import NoDataFound from "../Helper/NoDataFound";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Persona from "persona";
import configuration from "react-global-configuration";
import {
  onboardingUpdateStart,
  usernameValidationStart,
} from "../../store/slices/OnBoardingSlice";
import { updateUserDetailsStart } from "../../store/slices/UserSlice";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/Helper/NotificationMessage";
import Select from "react-select";
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import GoogleMapReact from 'google-map-react';
import PhoneInput from 'react-phone-number-input';
import ChooseCategory from "../OnBoarding/ChooseCategory";
import AdditionalInformation from "../OnBoarding/AdditionalInformation";
import AddLocation from "../OnBoarding/AddLocation";
import InviteFriends from "../OnBoarding/InviteFriends";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

// import dayjs from "dayjs";


const auth0 = window.auth0;

const AccountOnboardingIndex = (props) => {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.user.profile);
  const onboardingUpdate = useSelector(
    (state) => state.onboard.onboardingUpdate
  );

  const genders = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
    { value: "others", label: "Other" },
    { value: "rather-not-select", label: "Rather not say" },
  ];

  const [selectedGender, setSelectedGender] = React.useState();


  const educations = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
    { value: "others", label: "Other" },
    { value: "rather-not-select", label: "Rather not say" },
  ];

  const [selectedEducate, setSelectedEducate] = React.useState();

  const customStyles = {
    ///.....
    menuPortal: provided => ({ ...provided, zIndex: 9999 }),
    menu: provided => ({
      ...provided, zIndex: 9999,
      left: '0px',
      borderRadius: "8px",
      overflow: "hidden",
    }),
    menuList: provided => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      }
    }),
    container: provided => ({ ...provided, width: '100%' }),
    control: provided =>
    ({
      ...provided,
      backgroundColor: '#fff!important',
      borderRadius: '50px',
      borderColor: '#787878!important',
      boxShadow: 'none',
      height: '45px',
      minWidth: "150px",

    }),
    singleValue: provided => ({
      ...provided, color: '#989CA1', display: "flex",
      alignItems: "center",
      gap: "0.5em", fontSize: "1em", fontWeight: "500"
    }),
    indicatorContainer: provided => ({ ...provided, color: '#4b4b4b!important' }),
    indicatorContainer: provided => ({ ...provided, fill: '#4b4b4b!important' }),
    indicatorSeparator: base => ({
      ...base,
      display: 'none'
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      "svg": {
        fill: "#4b4b4b"
      }
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#f7f7f7" : "#fff",
        color: "#000"
      };
    }
  }

  const AnyReactComponent = ({ text }) => (
    <div style={{
      color: 'white',
      background: 'grey',
      padding: '15px 10px',
      display: 'inline-flex',
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '100%',
      transform: 'translate(-50%, -50%)'
    }}>
      {text}
    </div>
  );

  const defaultProps = {
    center: {
      lat: 10.99835602,
      lng: 77.01502627
    },
    zoom: 11
  };

  const [value, setValue] = useState()


  return (
    <>
      <div className="account-sec">
        <Container>
          {profile.loading ? (
            <Skeleton className="account-box" height={500} />
          ) : Object.keys(profile.data).length > 0 ? (
            <>
              <div className="account-back-btn-sec">
                <Link to="/profile" className="account-back-btn">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-chevron-left"
                  >
                    <polyline points="15 18 9 12 15 6"></polyline>
                  </svg>
                  Back to Profile
                </Link>
              </div>
              {/* <Form> */}
                <div className="account-box">
                  <div className="account-left-sec">
                    <div className="account-user-details">
                      <Image
                        src={
                          profile.data.picture
                            ? profile.data.picture
                            : window.location.origin +
                            "/assets/img/account/profile-img.png"
                        }
                        className="account-user-img"
                      />
                      <h4>{profile.data.name}</h4>
                    </div>
                    <div className="account-sidebar-list-sec">
                      <ul className="list-unstyled account-sidebar-list">
                        <li>
                          <Link to="/account">
                            <span>Account</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="/account-onboarding" className="active">
                            <span>Onboarding</span>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="account-right-sec">
                    <div className="account-tab-sec">
                      <Tab.Container
                        id="left-tabs-example"
                        defaultActiveKey="choose-category"
                      >
                        <Row>
                          <Col sm={12}>
                            <Nav variant="pills">
                              <Nav.Item>
                                <Nav.Link eventKey="choose-category">Choose Category</Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey="additional-information">
                                  Additional Information
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey="location">
                                  Location
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey="invite-friends">Invite Friends</Nav.Link>
                              </Nav.Item>
                            </Nav>
                          </Col>
                          <Col sm={12}>
                            <Tab.Content>
                              <Tab.Pane eventKey="choose-category">
                                {/* <div className="account-choose-category-sec">
                                  <div className="account-tab-content">
                                    <div className="account-tab-content-title-sec">
                                      <h4>Choose Category</h4>
                                      <p> Please choose at least 1 tag for us to know you better!</p>
                                    </div>
                                    <Row className="justify-content-md-center">
                                      <Col md={10} xl={6} lg={6}>
                                        <div className="category-list">
                                          <ul class="list-unstyled">

                                            <li
                                              className="category-outline-btn active"

                                            >
                                              <span>
                                                {" "}
                                                <Image
                                                  src={
                                                    window.location.origin +
                                                    "/assets/img/modal-close.svg"
                                                  }
                                                />
                                              </span>
                                              Another Tag
                                            </li>
                                            <li
                                              className="category-outline-btn"
                                            >
                                              Longcopy tag
                                            </li>
                                            <li
                                              className="category-outline-btn"
                                            >
                                              Longcopy tag
                                            </li>
                                            <li
                                              className="category-outline-btn"
                                            >
                                              Longcopy tag
                                            </li>
                                            <li
                                              className="category-outline-btn"
                                            >
                                              Longcopy tag
                                            </li>
                                            <li
                                              className="category-outline-btn"
                                            >
                                              Longcopy tag
                                            </li>
                                            <li
                                              className="category-outline-btn active"

                                            >
                                              <span>
                                                {" "}
                                                <Image
                                                  src={
                                                    window.location.origin +
                                                    "/assets/img/modal-close.svg"
                                                  }
                                                />
                                              </span>
                                              Another Tag
                                            </li>
                                            <li
                                              className="category-outline-btn active"

                                            >
                                              <span>
                                                {" "}
                                                <Image
                                                  src={
                                                    window.location.origin +
                                                    "/assets/img/modal-close.svg"
                                                  }
                                                />
                                              </span>
                                              Another Tag
                                            </li>
                                          </ul>
                                        </div>
                                        <div className="login-btn mt-4">
                                          <Button
                                            type="submit"
                                            className="default-btn"
                                            disabled={
                                              onboardingUpdate.buttonDisable
                                            }
                                          >
                                            {onboardingUpdate.buttonDisable
                                              ? "Loading"
                                              : "Save"}
                                          </Button>
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                </div> */}
                                <ChooseCategory />
                              </Tab.Pane>
                              <Tab.Pane eventKey="additional-information">
                                {/* <div className="account-choose-category-sec">
                                  <div className="account-tab-content">
                                    <div className="account-tab-content-title-sec">
                                      <h4>Additional Information</h4>
                                      <p>Just to know you better!</p>
                                    </div>
                                    <Row className="justify-content-md-center">
                                      <Col md={10} xl={6} lg={6}>
                                        <div className="additional-info">
                                          <div>
                                            <Form.Group>
                                              <Form.Label>Gender*</Form.Label>
                                              <Select
                                                options={genders}
                                                initialValue={selectedGender}
                                                value={selectedGender}
                                                onChange={(value) => setSelectedGender(value)}
                                                styles={customStyles}
                                                // menuIsOpen={true}
                                                isSearchable={false}
                                              />
                                            </Form.Group>
                                          </div>
                                          <div className="mt-4">
                                            <Form.Label>Date of Birth*</Form.Label>
                                            <div className="additional-date-picker">
                                              <DatePicker />
                                            </div>
                                          </div>
                                          <div className="mt-4">
                                            <Form.Group>
                                              <Form.Label>Education*</Form.Label>
                                              <Select
                                                options={educations}
                                                initialValue={selectedEducate}
                                                value={selectedEducate}
                                                onChange={(value) => setSelectedEducate(value)}
                                                styles={customStyles}
                                                // menuIsOpen={true}
                                                isSearchable={false}
                                              />
                                            </Form.Group>
                                          </div>
                                        </div>
                                        <div className="login-btn mt-4">
                                          <Button
                                            type="submit"
                                            className="default-btn"
                                            disabled={
                                              onboardingUpdate.buttonDisable
                                            }
                                          >
                                            {onboardingUpdate.buttonDisable
                                              ? "Loading"
                                              : "Save"}
                                          </Button>
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                </div> */}
                                <AdditionalInformation />
                              </Tab.Pane>
                              <Tab.Pane eventKey="location">
                                {/* <div className="create-link-sec login-auth-sec">
                                  <div className="sign-in-sec account-choose-category-sec">
                                    <div className="account-tab-content">
                                      <div className="account-tab-content-title-sec">
                                        <h4>Location</h4>
                                        <p> Please choose your location.!</p>
                                      </div>
                                      <Row className="justify-content-md-center">
                                        <Col md={10} xl={6} lg={6}>
                                          <FormGroup className="mb-5 mt-4">
                                            <InputGroup className="mb-3">
                                              <InputGroup.Text id="basic-addon1">
                                                <Image
                                                  src={
                                                    window.location.origin + "/assets/img/auth/email.svg"
                                                  }
                                                />
                                              </InputGroup.Text>
                                              <Form.Control
                                                placeholder="Search Loaction"
                                              />
                                            </InputGroup>
                                          </FormGroup>
                                          <div style={{ height: '50vh', width: '100%', marginBottom: '3em' }}>
                                            <GoogleMapReact
                                              bootstrapURLKeys={{ key: "" }}
                                              defaultCenter={defaultProps.center}
                                              defaultZoom={defaultProps.zoom}
                                            >
                                              <AnyReactComponent
                                                lat={59.955413}
                                                lng={30.337844}
                                                text="My Marker"
                                              />
                                            </GoogleMapReact>
                                          </div>
                                          <div className="login-btn mt-4">
                                            <Button
                                              type="submit"
                                              className="default-btn"
                                              disabled={
                                                onboardingUpdate.buttonDisable
                                              }
                                            >
                                              {onboardingUpdate.buttonDisable
                                                ? "Loading"
                                                : "Save"}
                                            </Button>
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>
                                  </div>
                                </div> */}
                                <AddLocation />
                              </Tab.Pane>
                              <Tab.Pane eventKey="invite-friends">
                                {/* <div className="create-link-sec login-auth-sec">
                                  <div className="sign-in-sec account-choose-category-sec">
                                    <div className="account-tab-content">
                                      <div className="account-tab-content-title-sec">
                                        <h4>Your friends aren't here yet?</h4>
                                        <p>Invite your friends and get 100 points for every invited who joined</p>
                                      </div>
                                      <Row className="justify-content-md-center">
                                        <Col md={10} xl={6} lg={6}>
                                          <div className="invite-link-title">
                                            <h4>Send invitation via;</h4>
                                          </div>
                                          <FormGroup className="mb-3">
                                            <Form.Label>E-mail*</Form.Label>
                                            <InputGroup className="mb-3">
                                              <InputGroup.Text id="basic-addon1">
                                                <Image
                                                  src={window.location.origin + "/assets/img/auth/email.svg"}
                                                />
                                              </InputGroup.Text>
                                              <Form.Control
                                                type="email"
                                                placeholder="Enter Your Email Address"
                                                name="email"
                                              />
                                            </InputGroup>
                                          </FormGroup>
                                          <FormGroup className="mb-3">
                                            <Form.Label>Phone*</Form.Label>
                                            <PhoneInput
                                              defaultCountry="US"
                                              placeholder="Enter phone number"
                                              value={value}
                                              onChange={setValue}
                                            />
                                          </FormGroup>
                                          <div className="login-btn mt-4">
                                            <Button
                                              type="submit"
                                              className="default-btn"
                                              disabled={
                                                onboardingUpdate.buttonDisable
                                              }
                                            >
                                              {onboardingUpdate.buttonDisable
                                                ? "Loading"
                                                : "Save"}
                                            </Button>
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>
                                  </div>
                                </div> */}
                                <InviteFriends />
                              </Tab.Pane>
                            </Tab.Content>
                          </Col>
                        </Row>
                      </Tab.Container>
                    </div>
                  </div>
                </div>
              {/* </Form> */}
            </>
          ) : (
            <NoDataFound />
          )}
        </Container>
      </div>
    </>
  );
};

export default AccountOnboardingIndex;
