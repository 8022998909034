import React, { useState, useEffect } from "react";
import { Row, Col, Image, Container, Dropdown , Button} from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import OnBoardingHeader from "../OnBoarding/OnBoardingHeader";
import configuration from "react-global-configuration";
import OtherProfileCard from "./OtherProfileCard";
import OtherProfileNFTStoreFront from "./OtherProfileNFTStoreFront";
import OtherProfileHashtags from "./OtherProfileHashtags";
import OtherProfileMentions from "./OtherProfileMentions";
import OtherProfileReviewTestimonials from "./OtherProfileReviewTestimonials";
import OtherProfileLinkIndex from "./OtherProfileLinkIndex";
import OtherProfilePostCard from "./OtherProfilePostCard";

const OtherProfileIndex = (props) => {


  return (
    <>
      <div className="single-account-sec other-profile-sec">
      <OnBoardingHeader />
        <div className="other-profile-box">
          <Container>
            <Row>
              <Col md={12} xl={5} lg={12}>
                <OtherProfileCard/>
                <OtherProfileNFTStoreFront/>
                <OtherProfileHashtags/>
                <OtherProfileMentions/>
                <OtherProfileReviewTestimonials/>
              </Col>
              <Col md={12} xl={7} lg={12}>
                <OtherProfileLinkIndex/>
                <OtherProfilePostCard/>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  )
}

export default OtherProfileIndex;
