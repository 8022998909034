import { all, fork } from "redux-saga/effects";
import UserSaga from "./UserSaga";
import OnBoardSaga from "./OnBoardingSaga";
import NftSaga from "./NftSaga";
import LinkTreeSaga from "./LinkTreeSaga";

export default function* rootSaga() {
    yield all([
        fork(UserSaga),
        fork(OnBoardSaga),
        fork(NftSaga),
        fork(LinkTreeSaga),
    ]);
}
