import React, { useState, useEffect } from "react";
import { Row, Col, Image, Container, Tab, Nav, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const OtherProfilePostCard = (props) => {


  return (
    <>
      <div className="other-profile-post-card-sec">
        <div className="other-profile-post-card-box">
          <div className="other-profile-post-card-header-sec">
            <Tab.Container id="left-tabs-example" defaultActiveKey="bio">
              <Row>
                <Col sm={12}>
                  <div className="other-profile-post-card-tab-nav">
                    <Nav variant="pills">
                      <Nav.Item>
                        <Nav.Link eventKey="bio">
                          <Image
                              className="other-profile-post-card-tab-nav-icon-1"
                              src={window.location.origin + "/assets/img/other-profile/bio-logo.png"}
                          />
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="insta">
                          <Image
                              className="other-profile-post-card-tab-nav-icon"
                              src={window.location.origin + "/assets/img/social-media/instagram.svg"}
                          />
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="twitter">
                          <Image
                              className="other-profile-post-card-tab-nav-icon"
                              src={window.location.origin + "/assets/img/other-profile/twitter.svg"}
                          />
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="facebook">
                          <Image
                              className="other-profile-post-card-tab-nav-icon"
                              src={window.location.origin + "/assets/img/other-profile/facebook.svg"}
                          />
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <div className="other-profile-hashtags-more-btn-sec">
                      <Link to="#">
                          More
                          <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="12"
                              height="12"
                              fill="none"
                              viewBox="0 0 6 9"
                              >
                              <path
                                  stroke="#000"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="1.3"
                                  d="M1.168 1.42l3.331 3.262-3.331 3.26"
                              ></path>
                          </svg>
                      </Link>
                    </div>
                  </div>
                </Col>
                <Col sm={12}>
                  <Tab.Content>
                    <Tab.Pane eventKey="bio">
                      <div className="other-profile-post-card-tab-content">
                        <ul className="other-profile-post-card-tab-content-list list-unstyled">
                          <li>
                            <Link to="#" className="active">
                              Most Engaged
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              Recent Social Media Post
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              Latest NFT Minted
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              Sponsored Ad
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              Latest Favor
                            </Link>
                          </li>
                        </ul>
                        <div className="other-profile-post-box">
                          <div className="other-profile-post-card">
                            <div className="other-profile-post-img-sec">
                              <Image
                                  className="other-profile-post-img"
                                  src={
                                  window.location.origin +
                                  "/assets/img/other-profile/post-img.png"
                                  }
                              />
                            </div>
                            <div className="other-profile-post-count-sec">
                              <div className="other-profile-post-count-left-sec">
                                <div className="other-profile-post-like-count-sec">
                                  <div className="other-profile-post-like-count-card">
                                    <div className="other-profile-post-like-user-1-sec">
                                      <Image
                                        className="other-profile-post-like-user-1"
                                        src={
                                        window.location.origin +
                                        "/assets/img/other-profile/user-like-share-img.png"
                                        }
                                      />
                                    </div>
                                    <div className="other-profile-post-like-user-2-sec">
                                      <Image
                                        className="other-profile-post-like-user-2"
                                        src={
                                        window.location.origin +
                                        "/assets/img/other-profile/user-like-share-img.png"
                                        }
                                      />
                                    </div>
                                    <div className="other-profile-post-like-user-3-sec">
                                      <Image
                                        className="other-profile-post-like-user-3"
                                        src={
                                        window.location.origin +
                                        "/assets/img/other-profile/user-like-share-img.png"
                                        }
                                      />
                                    </div>
                                  </div>
                                  <span>4,962 likes</span>
                                </div>
                                <div className="other-profile-post-share-count-sec">
                                  <div className="other-profile-post-share-count-card">
                                    <div className="other-profile-post-share-user-1-sec">
                                      <Image
                                        className="other-profile-post-share-user-1"
                                        src={
                                        window.location.origin +
                                        "/assets/img/other-profile/user-like-share-img.png"
                                        }
                                      />
                                    </div>
                                    <div className="other-profile-post-share-user-2-sec">
                                      <Image
                                        className="other-profile-post-share-user-2"
                                        src={
                                        window.location.origin +
                                        "/assets/img/other-profile/user-like-share-img.png"
                                        }
                                      />
                                    </div>
                                    <div className="other-profile-post-share-user-3-sec">
                                      <Image
                                        className="other-profile-post-share-user-3"
                                        src={
                                        window.location.origin +
                                        "/assets/img/other-profile/user-like-share-img.png"
                                        }
                                      />
                                    </div>
                                  </div>
                                  <span>3,205 shares</span>
                                </div>
                              </div>
                              <div className="other-profile-post-count-right-sec">
                                <div className="all-points-sold-badge-sec">
                                    <Button className="all-points-sold-badge">
                                        All Points Sold!
                                    </Button>
                                </div>
                              </div>
                            </div>
                            <div className="other-profile-post-footer-btn-sec">
                              <Button className="other-profile-post-footer-btn">
                                <Image
                                  className="other-profile-post-footer-btn-icon"
                                  src={
                                  window.location.origin +
                                  "/assets/img/other-profile/like-btn-icon.svg"
                                  }
                                />
                              </Button>
                              <Button className="other-profile-post-footer-btn">
                                <Image
                                  className="other-profile-post-footer-btn-icon"
                                  src={
                                  window.location.origin +
                                  "/assets/img/other-profile/share-btn-icon.svg"
                                  }
                                />
                              </Button>
                            </div>
                          </div>
                          <div className="other-profile-post-card">
                            <div className="other-profile-post-img-sec">
                              <Image
                                  className="other-profile-post-img"
                                  src={
                                  window.location.origin +
                                  "/assets/img/other-profile/post-img-1.png"
                                  }
                              />
                            </div>
                            <div className="other-profile-post-count-sec">
                              <div className="other-profile-post-count-left-sec">
                                <div className="other-profile-post-like-count-sec">
                                  <div className="other-profile-post-like-count-card">
                                    <div className="other-profile-post-like-user-1-sec">
                                      <Image
                                        className="other-profile-post-like-user-1"
                                        src={
                                        window.location.origin +
                                        "/assets/img/other-profile/user-like-share-img.png"
                                        }
                                      />
                                    </div>
                                    <div className="other-profile-post-like-user-2-sec">
                                      <Image
                                        className="other-profile-post-like-user-2"
                                        src={
                                        window.location.origin +
                                        "/assets/img/other-profile/user-like-share-img.png"
                                        }
                                      />
                                    </div>
                                    <div className="other-profile-post-like-user-3-sec">
                                      <Image
                                        className="other-profile-post-like-user-3"
                                        src={
                                        window.location.origin +
                                        "/assets/img/other-profile/user-like-share-img.png"
                                        }
                                      />
                                    </div>
                                  </div>
                                  <span>4,962 likes</span>
                                </div>
                                <div className="other-profile-post-share-count-sec">
                                  <div className="other-profile-post-share-count-card">
                                    <div className="other-profile-post-share-user-1-sec">
                                      <Image
                                        className="other-profile-post-share-user-1"
                                        src={
                                        window.location.origin +
                                        "/assets/img/other-profile/user-like-share-img.png"
                                        }
                                      />
                                    </div>
                                    <div className="other-profile-post-share-user-2-sec">
                                      <Image
                                        className="other-profile-post-share-user-2"
                                        src={
                                        window.location.origin +
                                        "/assets/img/other-profile/user-like-share-img.png"
                                        }
                                      />
                                    </div>
                                    <div className="other-profile-post-share-user-3-sec">
                                      <Image
                                        className="other-profile-post-share-user-3"
                                        src={
                                        window.location.origin +
                                        "/assets/img/other-profile/user-like-share-img.png"
                                        }
                                      />
                                    </div>
                                  </div>
                                  <span>3,205 shares</span>
                                </div>
                              </div>
                              <div className="other-profile-post-count-right-sec">
                                <div className="other-profile-post-available-points-card">
                                    <div className="other-profile-post-available-status-sec">
                                      <Image
                                        className="other-profile-post-available-status-icon"
                                        src={
                                        window.location.origin +
                                        "/assets/img/other-profile/points-available-icon.svg"
                                        }
                                      />
                                      <span>Available</span>
                                    </div>
                                    <div className="other-profile-post-available-points-sec">
                                      <span>500</span>
                                      <Image
                                        className="other-profile-post-available-points-icon"
                                        src={
                                        window.location.origin +
                                        "/assets/img/other-profile/ravi's-link-icon-important.png"
                                        }
                                      />
                                    </div>
                                </div>
                              </div>
                            </div>
                            <div className="other-profile-post-footer-btn-sec">
                              <Button className="other-profile-post-footer-btn">
                                <Image
                                  className="other-profile-post-footer-btn-icon"
                                  src={
                                  window.location.origin +
                                  "/assets/img/other-profile/like-btn-icon.svg"
                                  }
                                />
                              </Button>
                              <Button className="other-profile-post-footer-btn">
                                <Image
                                  className="other-profile-post-footer-btn-icon"
                                  src={
                                  window.location.origin +
                                  "/assets/img/other-profile/share-btn-icon.svg"
                                  }
                                />
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="insta">
                    <div className="other-profile-post-card-tab-content">
                        <ul className="other-profile-post-card-tab-content-list list-unstyled">
                          <li>
                            <Link to="#" className="active">
                              Most Engaged
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              Recent Social Media Post
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              Latest NFT Minted
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              Sponsored Ad
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              Latest Favor
                            </Link>
                          </li>
                        </ul>
                        <div className="other-profile-post-box">
                          <div className="other-profile-post-card">
                            <div className="other-profile-post-img-sec">
                              <Image
                                  className="other-profile-post-img"
                                  src={
                                  window.location.origin +
                                  "/assets/img/other-profile/post-img.png"
                                  }
                              />
                            </div>
                            <div className="other-profile-post-count-sec">
                              <div className="other-profile-post-count-left-sec">
                                <div className="other-profile-post-like-count-sec">
                                  <div className="other-profile-post-like-count-card">
                                    <div className="other-profile-post-like-user-1-sec">
                                      <Image
                                        className="other-profile-post-like-user-1"
                                        src={
                                        window.location.origin +
                                        "/assets/img/other-profile/user-like-share-img.png"
                                        }
                                      />
                                    </div>
                                    <div className="other-profile-post-like-user-2-sec">
                                      <Image
                                        className="other-profile-post-like-user-2"
                                        src={
                                        window.location.origin +
                                        "/assets/img/other-profile/user-like-share-img.png"
                                        }
                                      />
                                    </div>
                                    <div className="other-profile-post-like-user-3-sec">
                                      <Image
                                        className="other-profile-post-like-user-3"
                                        src={
                                        window.location.origin +
                                        "/assets/img/other-profile/user-like-share-img.png"
                                        }
                                      />
                                    </div>
                                  </div>
                                  <span>4,962 likes</span>
                                </div>
                                <div className="other-profile-post-share-count-sec">
                                  <div className="other-profile-post-share-count-card">
                                    <div className="other-profile-post-share-user-1-sec">
                                      <Image
                                        className="other-profile-post-share-user-1"
                                        src={
                                        window.location.origin +
                                        "/assets/img/other-profile/user-like-share-img.png"
                                        }
                                      />
                                    </div>
                                    <div className="other-profile-post-share-user-2-sec">
                                      <Image
                                        className="other-profile-post-share-user-2"
                                        src={
                                        window.location.origin +
                                        "/assets/img/other-profile/user-like-share-img.png"
                                        }
                                      />
                                    </div>
                                    <div className="other-profile-post-share-user-3-sec">
                                      <Image
                                        className="other-profile-post-share-user-3"
                                        src={
                                        window.location.origin +
                                        "/assets/img/other-profile/user-like-share-img.png"
                                        }
                                      />
                                    </div>
                                  </div>
                                  <span>3,205 shares</span>
                                </div>
                              </div>
                              <div className="other-profile-post-count-right-sec">
                                <div className="all-points-sold-badge-sec">
                                    <Button className="all-points-sold-badge">
                                        All Points Sold!
                                    </Button>
                                </div>
                              </div>
                            </div>
                            <div className="other-profile-post-footer-btn-sec">
                              <Button className="other-profile-post-footer-btn">
                                <Image
                                  className="other-profile-post-footer-btn-icon"
                                  src={
                                  window.location.origin +
                                  "/assets/img/other-profile/like-btn-icon.svg"
                                  }
                                />
                              </Button>
                              <Button className="other-profile-post-footer-btn">
                                <Image
                                  className="other-profile-post-footer-btn-icon"
                                  src={
                                  window.location.origin +
                                  "/assets/img/other-profile/share-btn-icon.svg"
                                  }
                                />
                              </Button>
                            </div>
                          </div>
                          <div className="other-profile-post-card">
                            <div className="other-profile-post-img-sec">
                              <Image
                                  className="other-profile-post-img"
                                  src={
                                  window.location.origin +
                                  "/assets/img/other-profile/post-img-1.png"
                                  }
                              />
                            </div>
                            <div className="other-profile-post-count-sec">
                              <div className="other-profile-post-count-left-sec">
                                <div className="other-profile-post-like-count-sec">
                                  <div className="other-profile-post-like-count-card">
                                    <div className="other-profile-post-like-user-1-sec">
                                      <Image
                                        className="other-profile-post-like-user-1"
                                        src={
                                        window.location.origin +
                                        "/assets/img/other-profile/user-like-share-img.png"
                                        }
                                      />
                                    </div>
                                    <div className="other-profile-post-like-user-2-sec">
                                      <Image
                                        className="other-profile-post-like-user-2"
                                        src={
                                        window.location.origin +
                                        "/assets/img/other-profile/user-like-share-img.png"
                                        }
                                      />
                                    </div>
                                    <div className="other-profile-post-like-user-3-sec">
                                      <Image
                                        className="other-profile-post-like-user-3"
                                        src={
                                        window.location.origin +
                                        "/assets/img/other-profile/user-like-share-img.png"
                                        }
                                      />
                                    </div>
                                  </div>
                                  <span>4,962 likes</span>
                                </div>
                                <div className="other-profile-post-share-count-sec">
                                  <div className="other-profile-post-share-count-card">
                                    <div className="other-profile-post-share-user-1-sec">
                                      <Image
                                        className="other-profile-post-share-user-1"
                                        src={
                                        window.location.origin +
                                        "/assets/img/other-profile/user-like-share-img.png"
                                        }
                                      />
                                    </div>
                                    <div className="other-profile-post-share-user-2-sec">
                                      <Image
                                        className="other-profile-post-share-user-2"
                                        src={
                                        window.location.origin +
                                        "/assets/img/other-profile/user-like-share-img.png"
                                        }
                                      />
                                    </div>
                                    <div className="other-profile-post-share-user-3-sec">
                                      <Image
                                        className="other-profile-post-share-user-3"
                                        src={
                                        window.location.origin +
                                        "/assets/img/other-profile/user-like-share-img.png"
                                        }
                                      />
                                    </div>
                                  </div>
                                  <span>3,205 shares</span>
                                </div>
                              </div>
                              <div className="other-profile-post-count-right-sec">
                                <div className="other-profile-post-available-points-card">
                                    <div className="other-profile-post-available-status-sec">
                                      <Image
                                        className="other-profile-post-available-status-icon"
                                        src={
                                        window.location.origin +
                                        "/assets/img/other-profile/points-available-icon.svg"
                                        }
                                      />
                                      <span>Available</span>
                                    </div>
                                    <div className="other-profile-post-available-points-sec">
                                      <span>500</span>
                                      <Image
                                        className="other-profile-post-available-points-icon"
                                        src={
                                        window.location.origin +
                                        "/assets/img/other-profile/ravi's-link-icon-important.png"
                                        }
                                      />
                                    </div>
                                </div>
                              </div>
                            </div>
                            <div className="other-profile-post-footer-btn-sec">
                              <Button className="other-profile-post-footer-btn">
                                <Image
                                  className="other-profile-post-footer-btn-icon"
                                  src={
                                  window.location.origin +
                                  "/assets/img/other-profile/like-btn-icon.svg"
                                  }
                                />
                              </Button>
                              <Button className="other-profile-post-footer-btn">
                                <Image
                                  className="other-profile-post-footer-btn-icon"
                                  src={
                                  window.location.origin +
                                  "/assets/img/other-profile/share-btn-icon.svg"
                                  }
                                />
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="twitter">
                    <div className="other-profile-post-card-tab-content">
                        <ul className="other-profile-post-card-tab-content-list list-unstyled">
                          <li>
                            <Link to="#" className="active">
                              Most Engaged
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              Recent Social Media Post
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              Latest NFT Minted
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              Sponsored Ad
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              Latest Favor
                            </Link>
                          </li>
                        </ul>
                        <div className="other-profile-post-box">
                          <div className="other-profile-post-card">
                            <div className="other-profile-post-img-sec">
                              <Image
                                  className="other-profile-post-img"
                                  src={
                                  window.location.origin +
                                  "/assets/img/other-profile/post-img.png"
                                  }
                              />
                            </div>
                            <div className="other-profile-post-count-sec">
                              <div className="other-profile-post-count-left-sec">
                                <div className="other-profile-post-like-count-sec">
                                  <div className="other-profile-post-like-count-card">
                                    <div className="other-profile-post-like-user-1-sec">
                                      <Image
                                        className="other-profile-post-like-user-1"
                                        src={
                                        window.location.origin +
                                        "/assets/img/other-profile/user-like-share-img.png"
                                        }
                                      />
                                    </div>
                                    <div className="other-profile-post-like-user-2-sec">
                                      <Image
                                        className="other-profile-post-like-user-2"
                                        src={
                                        window.location.origin +
                                        "/assets/img/other-profile/user-like-share-img.png"
                                        }
                                      />
                                    </div>
                                    <div className="other-profile-post-like-user-3-sec">
                                      <Image
                                        className="other-profile-post-like-user-3"
                                        src={
                                        window.location.origin +
                                        "/assets/img/other-profile/user-like-share-img.png"
                                        }
                                      />
                                    </div>
                                  </div>
                                  <span>4,962 likes</span>
                                </div>
                                <div className="other-profile-post-share-count-sec">
                                  <div className="other-profile-post-share-count-card">
                                    <div className="other-profile-post-share-user-1-sec">
                                      <Image
                                        className="other-profile-post-share-user-1"
                                        src={
                                        window.location.origin +
                                        "/assets/img/other-profile/user-like-share-img.png"
                                        }
                                      />
                                    </div>
                                    <div className="other-profile-post-share-user-2-sec">
                                      <Image
                                        className="other-profile-post-share-user-2"
                                        src={
                                        window.location.origin +
                                        "/assets/img/other-profile/user-like-share-img.png"
                                        }
                                      />
                                    </div>
                                    <div className="other-profile-post-share-user-3-sec">
                                      <Image
                                        className="other-profile-post-share-user-3"
                                        src={
                                        window.location.origin +
                                        "/assets/img/other-profile/user-like-share-img.png"
                                        }
                                      />
                                    </div>
                                  </div>
                                  <span>3,205 shares</span>
                                </div>
                              </div>
                              <div className="other-profile-post-count-right-sec">
                                <div className="all-points-sold-badge-sec">
                                    <Button className="all-points-sold-badge">
                                        All Points Sold!
                                    </Button>
                                </div>
                              </div>
                            </div>
                            <div className="other-profile-post-footer-btn-sec">
                              <Button className="other-profile-post-footer-btn">
                                <Image
                                  className="other-profile-post-footer-btn-icon"
                                  src={
                                  window.location.origin +
                                  "/assets/img/other-profile/like-btn-icon.svg"
                                  }
                                />
                              </Button>
                              <Button className="other-profile-post-footer-btn">
                                <Image
                                  className="other-profile-post-footer-btn-icon"
                                  src={
                                  window.location.origin +
                                  "/assets/img/other-profile/share-btn-icon.svg"
                                  }
                                />
                              </Button>
                            </div>
                          </div>
                          <div className="other-profile-post-card">
                            <div className="other-profile-post-img-sec">
                              <Image
                                  className="other-profile-post-img"
                                  src={
                                  window.location.origin +
                                  "/assets/img/other-profile/post-img-1.png"
                                  }
                              />
                            </div>
                            <div className="other-profile-post-count-sec">
                              <div className="other-profile-post-count-left-sec">
                                <div className="other-profile-post-like-count-sec">
                                  <div className="other-profile-post-like-count-card">
                                    <div className="other-profile-post-like-user-1-sec">
                                      <Image
                                        className="other-profile-post-like-user-1"
                                        src={
                                        window.location.origin +
                                        "/assets/img/other-profile/user-like-share-img.png"
                                        }
                                      />
                                    </div>
                                    <div className="other-profile-post-like-user-2-sec">
                                      <Image
                                        className="other-profile-post-like-user-2"
                                        src={
                                        window.location.origin +
                                        "/assets/img/other-profile/user-like-share-img.png"
                                        }
                                      />
                                    </div>
                                    <div className="other-profile-post-like-user-3-sec">
                                      <Image
                                        className="other-profile-post-like-user-3"
                                        src={
                                        window.location.origin +
                                        "/assets/img/other-profile/user-like-share-img.png"
                                        }
                                      />
                                    </div>
                                  </div>
                                  <span>4,962 likes</span>
                                </div>
                                <div className="other-profile-post-share-count-sec">
                                  <div className="other-profile-post-share-count-card">
                                    <div className="other-profile-post-share-user-1-sec">
                                      <Image
                                        className="other-profile-post-share-user-1"
                                        src={
                                        window.location.origin +
                                        "/assets/img/other-profile/user-like-share-img.png"
                                        }
                                      />
                                    </div>
                                    <div className="other-profile-post-share-user-2-sec">
                                      <Image
                                        className="other-profile-post-share-user-2"
                                        src={
                                        window.location.origin +
                                        "/assets/img/other-profile/user-like-share-img.png"
                                        }
                                      />
                                    </div>
                                    <div className="other-profile-post-share-user-3-sec">
                                      <Image
                                        className="other-profile-post-share-user-3"
                                        src={
                                        window.location.origin +
                                        "/assets/img/other-profile/user-like-share-img.png"
                                        }
                                      />
                                    </div>
                                  </div>
                                  <span>3,205 shares</span>
                                </div>
                              </div>
                              <div className="other-profile-post-count-right-sec">
                                <div className="other-profile-post-available-points-card">
                                    <div className="other-profile-post-available-status-sec">
                                      <Image
                                        className="other-profile-post-available-status-icon"
                                        src={
                                        window.location.origin +
                                        "/assets/img/other-profile/points-available-icon.svg"
                                        }
                                      />
                                      <span>Available</span>
                                    </div>
                                    <div className="other-profile-post-available-points-sec">
                                      <span>500</span>
                                      <Image
                                        className="other-profile-post-available-points-icon"
                                        src={
                                        window.location.origin +
                                        "/assets/img/other-profile/ravi's-link-icon-important.png"
                                        }
                                      />
                                    </div>
                                </div>
                              </div>
                            </div>
                            <div className="other-profile-post-footer-btn-sec">
                              <Button className="other-profile-post-footer-btn">
                                <Image
                                  className="other-profile-post-footer-btn-icon"
                                  src={
                                  window.location.origin +
                                  "/assets/img/other-profile/like-btn-icon.svg"
                                  }
                                />
                              </Button>
                              <Button className="other-profile-post-footer-btn">
                                <Image
                                  className="other-profile-post-footer-btn-icon"
                                  src={
                                  window.location.origin +
                                  "/assets/img/other-profile/share-btn-icon.svg"
                                  }
                                />
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="facebook">
                    <div className="other-profile-post-card-tab-content">
                        <ul className="other-profile-post-card-tab-content-list list-unstyled">
                          <li>
                            <Link to="#" className="active">
                              Most Engaged
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              Recent Social Media Post
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              Latest NFT Minted
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              Sponsored Ad
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              Latest Favor
                            </Link>
                          </li>
                        </ul>
                        <div className="other-profile-post-box">
                          <div className="other-profile-post-card">
                            <div className="other-profile-post-img-sec">
                              <Image
                                  className="other-profile-post-img"
                                  src={
                                  window.location.origin +
                                  "/assets/img/other-profile/post-img.png"
                                  }
                              />
                            </div>
                            <div className="other-profile-post-count-sec">
                              <div className="other-profile-post-count-left-sec">
                                <div className="other-profile-post-like-count-sec">
                                  <div className="other-profile-post-like-count-card">
                                    <div className="other-profile-post-like-user-1-sec">
                                      <Image
                                        className="other-profile-post-like-user-1"
                                        src={
                                        window.location.origin +
                                        "/assets/img/other-profile/user-like-share-img.png"
                                        }
                                      />
                                    </div>
                                    <div className="other-profile-post-like-user-2-sec">
                                      <Image
                                        className="other-profile-post-like-user-2"
                                        src={
                                        window.location.origin +
                                        "/assets/img/other-profile/user-like-share-img.png"
                                        }
                                      />
                                    </div>
                                    <div className="other-profile-post-like-user-3-sec">
                                      <Image
                                        className="other-profile-post-like-user-3"
                                        src={
                                        window.location.origin +
                                        "/assets/img/other-profile/user-like-share-img.png"
                                        }
                                      />
                                    </div>
                                  </div>
                                  <span>4,962 likes</span>
                                </div>
                                <div className="other-profile-post-share-count-sec">
                                  <div className="other-profile-post-share-count-card">
                                    <div className="other-profile-post-share-user-1-sec">
                                      <Image
                                        className="other-profile-post-share-user-1"
                                        src={
                                        window.location.origin +
                                        "/assets/img/other-profile/user-like-share-img.png"
                                        }
                                      />
                                    </div>
                                    <div className="other-profile-post-share-user-2-sec">
                                      <Image
                                        className="other-profile-post-share-user-2"
                                        src={
                                        window.location.origin +
                                        "/assets/img/other-profile/user-like-share-img.png"
                                        }
                                      />
                                    </div>
                                    <div className="other-profile-post-share-user-3-sec">
                                      <Image
                                        className="other-profile-post-share-user-3"
                                        src={
                                        window.location.origin +
                                        "/assets/img/other-profile/user-like-share-img.png"
                                        }
                                      />
                                    </div>
                                  </div>
                                  <span>3,205 shares</span>
                                </div>
                              </div>
                              <div className="other-profile-post-count-right-sec">
                                <div className="all-points-sold-badge-sec">
                                    <Button className="all-points-sold-badge">
                                        All Points Sold!
                                    </Button>
                                </div>
                              </div>
                            </div>
                            <div className="other-profile-post-footer-btn-sec">
                              <Button className="other-profile-post-footer-btn">
                                <Image
                                  className="other-profile-post-footer-btn-icon"
                                  src={
                                  window.location.origin +
                                  "/assets/img/other-profile/like-btn-icon.svg"
                                  }
                                />
                              </Button>
                              <Button className="other-profile-post-footer-btn">
                                <Image
                                  className="other-profile-post-footer-btn-icon"
                                  src={
                                  window.location.origin +
                                  "/assets/img/other-profile/share-btn-icon.svg"
                                  }
                                />
                              </Button>
                            </div>
                          </div>
                          <div className="other-profile-post-card">
                            <div className="other-profile-post-img-sec">
                              <Image
                                  className="other-profile-post-img"
                                  src={
                                  window.location.origin +
                                  "/assets/img/other-profile/post-img-1.png"
                                  }
                              />
                            </div>
                            <div className="other-profile-post-count-sec">
                              <div className="other-profile-post-count-left-sec">
                                <div className="other-profile-post-like-count-sec">
                                  <div className="other-profile-post-like-count-card">
                                    <div className="other-profile-post-like-user-1-sec">
                                      <Image
                                        className="other-profile-post-like-user-1"
                                        src={
                                        window.location.origin +
                                        "/assets/img/other-profile/user-like-share-img.png"
                                        }
                                      />
                                    </div>
                                    <div className="other-profile-post-like-user-2-sec">
                                      <Image
                                        className="other-profile-post-like-user-2"
                                        src={
                                        window.location.origin +
                                        "/assets/img/other-profile/user-like-share-img.png"
                                        }
                                      />
                                    </div>
                                    <div className="other-profile-post-like-user-3-sec">
                                      <Image
                                        className="other-profile-post-like-user-3"
                                        src={
                                        window.location.origin +
                                        "/assets/img/other-profile/user-like-share-img.png"
                                        }
                                      />
                                    </div>
                                  </div>
                                  <span>4,962 likes</span>
                                </div>
                                <div className="other-profile-post-share-count-sec">
                                  <div className="other-profile-post-share-count-card">
                                    <div className="other-profile-post-share-user-1-sec">
                                      <Image
                                        className="other-profile-post-share-user-1"
                                        src={
                                        window.location.origin +
                                        "/assets/img/other-profile/user-like-share-img.png"
                                        }
                                      />
                                    </div>
                                    <div className="other-profile-post-share-user-2-sec">
                                      <Image
                                        className="other-profile-post-share-user-2"
                                        src={
                                        window.location.origin +
                                        "/assets/img/other-profile/user-like-share-img.png"
                                        }
                                      />
                                    </div>
                                    <div className="other-profile-post-share-user-3-sec">
                                      <Image
                                        className="other-profile-post-share-user-3"
                                        src={
                                        window.location.origin +
                                        "/assets/img/other-profile/user-like-share-img.png"
                                        }
                                      />
                                    </div>
                                  </div>
                                  <span>3,205 shares</span>
                                </div>
                              </div>
                              <div className="other-profile-post-count-right-sec">
                                <div className="other-profile-post-available-points-card">
                                    <div className="other-profile-post-available-status-sec">
                                      <Image
                                        className="other-profile-post-available-status-icon"
                                        src={
                                        window.location.origin +
                                        "/assets/img/other-profile/points-available-icon.svg"
                                        }
                                      />
                                      <span>Available</span>
                                    </div>
                                    <div className="other-profile-post-available-points-sec">
                                      <span>500</span>
                                      <Image
                                        className="other-profile-post-available-points-icon"
                                        src={
                                        window.location.origin +
                                        "/assets/img/other-profile/ravi's-link-icon-important.png"
                                        }
                                      />
                                    </div>
                                </div>
                              </div>
                            </div>
                            <div className="other-profile-post-footer-btn-sec">
                              <Button className="other-profile-post-footer-btn">
                                <Image
                                  className="other-profile-post-footer-btn-icon"
                                  src={
                                  window.location.origin +
                                  "/assets/img/other-profile/like-btn-icon.svg"
                                  }
                                />
                              </Button>
                              <Button className="other-profile-post-footer-btn">
                                <Image
                                  className="other-profile-post-footer-btn-icon"
                                  src={
                                  window.location.origin +
                                  "/assets/img/other-profile/share-btn-icon.svg"
                                  }
                                />
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </div>
      </div>
    </>
  )
}

export default OtherProfilePostCard;
