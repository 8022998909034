import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Image,
  Button,
  InputGroup,
  FormGroup,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PasswordSuccessModal from "./PasswordSuccessModal";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import { resetPasswordStart } from "../../store/slices/UserSlice";
import * as Yup from "yup";
import Footer from "../Layout/Footer/Footer";
import { useTranslation } from "react-multi-lang";

const ResetPasswordIndex = (props) => {

  const t = useTranslation("reset_password");

  const location = useLocation();
  const [passwordSuccess, setPasswordSuccess] = useState(false);

  const closePasswordSuccessModal = () => {
    setPasswordSuccess(false);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [skipRender, setSkipRender] = useState(true);
  const [showPassword, setShowPassword] = useState({
    new_password: false,
    confirm_password: false,
  });

  const resetPassword = useSelector((state) => state.user.resetPassword);

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required(t('required'))
      .test(
        "len",
        t('password_length_error'),
        (val) => val && val.length >= 6
      ),
    password_confirmation: Yup.string()
      .required(t('required'))
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          t('confirm_password_error')
        ),
      }),
  });

  const reset = (values) => {
    dispatch(
      resetPasswordStart({
        ...values,
        reset_token: localStorage.getItem("reset_token"),
      })
    );
  };

  useEffect(() => {
    console.log("resetPassword", resetPassword);
    if (
      !skipRender &&
      !resetPassword.loading &&
      Object.keys(resetPassword.data).length > 0
    ) {
      localStorage.removeItem("reset_token");
      // navigate("/login");
      setPasswordSuccess(true);
    }
    setSkipRender(false);
  }, [resetPassword]);

  return (
    <>
      <div className="auth-sec">
        <div className="auth-left-sec">
          <div className="auth-left-img-sec">
            <Image
              className="auth-left-img"
              src={
                window.location.origin + "/assets/img/auth/login-left-img.png"
              }
            />
          </div>
        </div>
        <div className="auth-right-sec">
          <div className="auth-right-box">
            <div className="auth-right-card">
              <div className="auth-form-sec">
                <Row className="justify-content-md-center">
                  <Col md={12} xl={8} lg={8}>
                    <div className="auth-logo-img-sec">
                      <Image
                        className="auth-logo-img"
                        src={
                          window.location.origin + "/assets/img/auth/logo.svg"
                        }
                      />
                    </div>
                    <div className="login-nav-link forgot-password-nav-link">
                      <ul>
                        <li
                          className={
                            location.pathname === "/reset-password"
                              ? "active"
                              : ""
                          }
                        >
                          <Link to="/reset-password">{t("reset_password")}</Link>
                        </li>
                      </ul>
                    </div>
                    <div className="notes-auth">
                      {t("reset_password_para")}
                    </div>
                    <Formik
                      initialValues={{
                        password: "",
                        password_confirmation: "",
                      }}
                      validationSchema={validationSchema}
                      onSubmit={(values) => reset(values)}
                    >
                      {({ errors, values, touched, setFieldValue }) => (
                        <FORM>
                          <div className="sign-in-sec mt-4">
                            <FormGroup className="mb-4">
                              <div className="Forgot-password-link">
                                <Form.Label>{t('new_password')} *</Form.Label>
                              </div>
                              <InputGroup
                                className={`${
                                  touched.password && errors.password
                                    ? "is-invalid"
                                    : ""
                                }`}
                              >
                                <InputGroup.Text>
                                  <Image
                                    src={
                                      window.location.origin +
                                      "/assets/img/auth/lock.svg"
                                    }
                                  />
                                </InputGroup.Text>
                                <Field
                                  className={`form-control ${
                                    touched.password && errors.password
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  type={
                                    showPassword.new_password
                                      ? "text"
                                      : "password"
                                  }
                                  placeholder={t('enter_new_number')}
                                  name="password"
                                />
                                <InputGroup.Text
                                  onClick={() =>
                                    setShowPassword({
                                      ...showPassword,
                                      new_password: !showPassword.new_password,
                                    })
                                  }
                                >
                                  <Image
                                    src={
                                      showPassword.new_password
                                        ? window.location.origin +
                                          "/assets/img/auth/eye_dash.svg"
                                        : window.location.origin +
                                          "/assets/img/auth/eye.svg"
                                    }
                                  />
                                </InputGroup.Text>
                              </InputGroup>
                              <ErrorMessage
                                component={"div"}
                                name="password"
                                className="text-right"
                              />
                            </FormGroup>
                            <FormGroup className="mb-4">
                              <div className="Forgot-password-link">
                                <Form.Label>{t('confirm_password')} *</Form.Label>
                              </div>
                              <InputGroup
                                className={`${
                                  touched.password_confirmation &&
                                  errors.password_confirmation
                                    ? "is-invalid"
                                    : ""
                                }`}
                              >
                                <InputGroup.Text>
                                  <Image
                                    src={
                                      window.location.origin +
                                      "/assets/img/auth/lock.svg"
                                    }
                                  />
                                </InputGroup.Text>
                                <Field
                                  className={`form-control ${
                                    touched.password_confirmation &&
                                    errors.password_confirmation
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  type={
                                    showPassword.confirm_password
                                      ? "text"
                                      : "password"
                                  }
                                  placeholder={t('confirm_password')}
                                  name="password_confirmation"
                                />
                                <InputGroup.Text
                                  onClick={() =>
                                    setShowPassword({
                                      ...showPassword,
                                      confirm_password:
                                        !showPassword.confirm_password,
                                    })
                                  }
                                >
                                  <Image
                                    src={
                                      showPassword.confirm_password
                                        ? window.location.origin +
                                          "/assets/img/auth/eye_dash.svg"
                                        : window.location.origin +
                                          "/assets/img/auth/eye.svg"
                                    }
                                  />
                                </InputGroup.Text>
                              </InputGroup>
                              <ErrorMessage
                                component={"div"}
                                name="password_confirmation"
                                className="text-right"
                              />
                            </FormGroup>
                            <div className="reset-btn-sec">
                              <Button
                                className="default-btn"
                                disabled={resetPassword.buttonDisable}
                                type="submit"
                              >
                                {resetPassword.buttonDisable
                                  ? t("loading")
                                  : t("submit")}
                              </Button>
                            </div>
                          </div>
                        </FORM>
                      )}
                    </Formik>
                  </Col>
                </Row>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
      {passwordSuccess && (
        <PasswordSuccessModal
          passwordSuccess={passwordSuccess}
          closePasswordSuccessModal={closePasswordSuccessModal}
          setPasswordSuccess={setPasswordSuccess}
        />
      )}
    </>
  );
};

export default ResetPasswordIndex;
