import React, { useEffect, useRef, useState } from "react";
import { Form, Image, Button, Modal, InputGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  linkTreeSaveStart,
  linkTreeListStart,
} from "../../store/slices/LinkTreeSlice";

const AddNewLinkModal = (props) => {
  const dispatch = useDispatch();
  const linkSave = useSelector((state) => state.linkTree.linkSave);
  const profile = useSelector((state) => state.user.profile);

  const [profileImage, setProfileImage] = useState({
    preview: null,
    src: null,
  });

  const [skipRender, setSkipRender] = useState(true);

  const formRef = useRef(null);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    website_link: Yup.string().required("Required"),
  });

  const handleSubmit = (values) => {
    let newValues = values;
    if (profileImage.src) {
      newValues = {
        ...values,
        picture: profileImage.src,
      };
    }
    dispatch(linkTreeSaveStart(newValues));
  };

  const imgPreview = (e) => {
    if (e.target.files) {
      setProfileImage({
        preview: URL.createObjectURL(e.target.files[0]),
        src: e.target.files[0],
      });
    }
  };

  useEffect(() => {
    if (
      !skipRender &&
      !linkSave.loading &&
      Object.keys(linkSave.data).length > 0
    ) {
      formRef.current.resetForm();
      props.closeAddNewLinkModal();
      dispatch(linkTreeListStart({ user_id: profile.data.user_id }));
    }
    setSkipRender(false);
  }, [linkSave]);

  return (
    <Modal
      className="modal-dialog-center phone-number-verification"
      size="lg"
      centered
      show={props.addNewLink}
      onHide={() => props.closeAddNewLinkModal()}
    >
      <Modal.Body>
        <h2>Add New Link</h2>

        <Button
          className="modal-close"
          onClick={() => props.closeAddNewLinkModal()}
        >
          <Image
            className="close-icon"
            src={window.location.origin + "/assets/img/modal-close.svg"}
          />
        </Button>
        <Formik
          initialValues={{
            name: "",
            website_link: "",
            status: 1,
          }}
          onSubmit={(values) => handleSubmit(values)}
          validationSchema={validationSchema}
          innerRef={formRef}
        >
          {({ touched, errors, isSubmitting, setFieldValue, values }) => (
            <FORM>
              <div className="add-link-card-item">
                <div className="add-link-card">
                  <div className="add-link-card-left-sec">
                    <div className="add-link-card-left-static-icon-sec">
                      <Image
                        className="add-link-card-left-static-icon"
                        src={
                          window.location.origin +
                          "/assets/img/add-link/add-link-card-left-static-icon.svg"
                        }
                      />
                      <div className="add-link-card-static-logo-edit-icon-sec">
                        <Image
                          className="add-link-card-left-static-link-logo-edit-icon"
                          src={
                            profileImage.preview
                              ? profileImage.preview
                              : window.location.origin +
                                "/assets/img/add-link/ravi's-link-icon.png"
                          }
                        />
                        <div className="add-link-card-static-logo-edit-options-sec">
                          <label>
                            <input
                              type="file"
                              size="60"
                              onChange={(e) => imgPreview(e)}
                            />
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              data-name="Layer 1"
                              viewBox="0 0 24 24"
                              width="25"
                              height="25"
                              fill="#00c75e"
                            >
                              <path d="M13.75 11.664l-3.457 3.457A3.02 3.02 0 018.172 16H8v-.172c0-.789.32-1.562.879-2.121l3.457-3.457 1.414 1.414zm1.043-3.871L13.75 8.836l1.414 1.414 1.043-1.043a1 1 0 00-1.414-1.414zM24 12c0 6.617-5.383 12-12 12S0 18.617 0 12 5.383 0 12 0s12 5.383 12 12zm-6.379-5.621a3.002 3.002 0 00-4.242 0l-5.914 5.914A4.968 4.968 0 006 15.828V17a1 1 0 001 1h1.172a4.968 4.968 0 003.535-1.465l5.914-5.914a3.002 3.002 0 000-4.242z"></path>
                            </svg>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="add-link-card-form-sec">
                      <div className="add-link-form-box">
                        <div className="add-link-form-card">
                          <InputGroup className="mb-0">
                            <Field
                              placeholder="Title"
                              className={`form-control ${
                                touched.password && errors.password
                                  ? "is-invalid"
                                  : ""
                              }`}
                              name="name"
                            />
                          </InputGroup>
                          <ErrorMessage
                            component={"div"}
                            name="name"
                            className="text-right"
                          />
                        </div>
                        <div className="add-link-form-card">
                          <InputGroup className="mb-0">
                            <Field
                              className={`form-control ${
                                touched.password && errors.password
                                  ? "is-invalid"
                                  : ""
                              }`}
                              placeholder="URL"
                              name="website_link"
                            />
                          </InputGroup>
                          <ErrorMessage
                            component={"div"}
                            name="website_link"
                            className="text-right"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="add-link-card-right-sec">
                    <div className="add-link-card-right-btn-sec">
                      <Button
                        onClick={() =>
                          setFieldValue("status", values.status ? 0 : 1)
                        }
                        className="add-link-view-btn"
                      >
                        <Image
                          className="add-link-view-icon"
                          src={
                            values.status
                              ? window.location.origin +
                                "/assets/img/add-link/add-link-eye-icon.svg"
                              : window.location.origin +
                                "/assets/img/other-profile/eye-close-icon-profile.svg"
                          }
                        />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="delete-the-link-modal-top-btn-sec">
                <Button
                  className="delete-the-link-modal-yes-btn"
                  type="submit"
                  disabled={linkSave.buttonDisable}
                >
                  {linkSave.buttonDisable ? "Loading" : "Add New Link"}
                </Button>
              </div>
              <div className="delete-the-link-modal-bottom-btn-sec">
                <Button
                  className="delete-the-link-modal-cancel-btn"
                  onClick={() => props.closeAddNewLinkModal()}
                >
                  Cancel
                </Button>
              </div>
            </FORM>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default AddNewLinkModal;
