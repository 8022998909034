import React, { useState, useEffect, useRef } from "react";
import { Image, Button, Form, Row, Col } from "react-bootstrap";
import Select from "react-select";
// import DatePicker from 'react-date-picker';
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import { onboardingUpdateStart } from "../../store/slices/OnBoardingSlice";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import Badge from "@mui/material/Badge";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { CalendarPickerSkeleton } from "@mui/x-date-pickers/CalendarPickerSkeleton";
const moment = require("moment");

const AdditionalInformation = (props) => {
  const genders = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
    { value: "others", label: "Other" },
    { value: "rather-not-select", label: "Rather not say" },
  ];

  const [skipRender, setSkipRender] = useState(true);
  const [isLoading, setIsLoading] = React.useState(false);
  const [maxDate, setMaxDate] = React.useState("08/17/2000");
  const [highlightedDays, setHighlightedDays] = React.useState([]);
  const dispatch = useDispatch();
  const onboardingUpdate = useSelector(
    (state) => state.onboard.onboardingUpdate
  );
  const profile = useSelector((state) => state.user.profile);

  function getRandomNumber(min, max) {
    return Math.round(Math.random() * (max - min) + min);
  }

  const validationSchema = Yup.object().shape({
    education: Yup.string().required("Education is required *"),
  });

  const initialValue = profile.data.dob
    ? new Date(profile.data.dob)
    : new Date("2000/01/01");

  const requestAbortController = useRef(null);
  const [value, setValue] = useState(initialValue);

  const [selectedGender, setSelectedGender] = useState();

  useEffect(() => {
    setSelectedGender(
      genders.filter((gender) => gender.value === profile.data.gender)[0]
    );
  }, []);

  const handleSubmit = (values) => {
    dispatch(
      onboardingUpdateStart({
        gender: selectedGender.value,
        dob: dayjs(value).format("YYYY-MM-DD"),
        ...values,
      })
    );
  };

  useEffect(() => {
    if (
      !skipRender &&
      !onboardingUpdate.loading &&
      Object.keys(onboardingUpdate.data).length > 0
    ) {
      // props.nextStep();
    }
    setSkipRender(false);
  }, [onboardingUpdate]);

  const customStyles = {
    ///.....
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "8px",
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "100%" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "#fff!important",
      borderRadius: "50px",
      borderColor: "#787878!important",
      boxShadow: "none",
      height: "45px",
      minWidth: "150px",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#989CA1",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "1em",
      fontWeight: "500",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#4b4b4b!important",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      fill: "#4b4b4b!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#4b4b4b",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#f7f7f7" : "#fff",
        color: "#000",
      };
    },
  };

  /**
   * Mimic fetch with abort controller https://developer.mozilla.org/en-US/docs/Web/API/AbortController/abort
   * ⚠️ No IE11 support
   */
  function fakeFetch(date, { signal }) {
    return new Promise((resolve, reject) => {
      const timeout = setTimeout(() => {
        const daysInMonth = moment(date).daysInMonth();
        const daysToHighlight = [1, 2, 3].map(() =>
          getRandomNumber(1, daysInMonth)
        );

        resolve({ daysToHighlight });
      }, 500);

      signal.onabort = () => {
        clearTimeout(timeout);
        reject(new DOMException("aborted", "AbortError"));
      };
    });
  }

  const fetchHighlightedDays = (date) => {
    const controller = new AbortController();
    fakeFetch(date, {
      signal: controller.signal,
    })
      .then(({ daysToHighlight }) => {
        // setHighlightedDays(daysToHighlight);
        setIsLoading(false);
      })
      .catch((error) => {
        // ignore the error if it's caused by `controller.abort`
        if (error.name !== "AbortError") {
          throw error;
        }
      });

    requestAbortController.current = controller;
  };

  React.useEffect(() => {
    const d = new Date();
    d.setFullYear(d.getFullYear() - 10);
    setMaxDate(d);
    console.log(d, d.toDateString());
    fetchHighlightedDays(initialValue);
    // abort request on unmount
    return () => requestAbortController.current?.abort();
  }, []);

  const handleMonthChange = (date) => {
    if (requestAbortController.current) {
      // make sure that you are aborting useless requests
      // because it is possible to switch between months pretty quickly
      requestAbortController.current.abort();
    }

    setIsLoading(true);
    setHighlightedDays([]);
    fetchHighlightedDays(date);
  };

  return (
    <>
      {/* <div className="header-back-btn">
        <Button className="back-btn" onClick={() => props.prevStep(3)}>
          <Image
            src={window.location.origin + "/assets/img/back.svg"}
            className="back-btn"
          />
        </Button>
      </div> */}
      <div className="create-link-sec login-auth-sec ">
        <div className="sign-in-sec mt-4">
          <Formik
            initialValues={{
              education: profile.data.education ? profile.data.education : "",
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => handleSubmit(values)}
          >
            {({ touched, errors, isSubmitting, setFieldValue }) => (
              <FORM>
                <div className="account-tab-content-title-sec create-link-content">
                  <h4>Additional Information</h4>
                  <p>Just to know you better!</p>
                </div>
                <Row className="justify-content-md-center">
                  <Col md={10} xl={6} lg={6}>
                    <div className="additional-info">
                      <div className="mt-4">
                        <Form.Group>
                          <Form.Label>Gender*</Form.Label>
                          <Select
                            options={genders}
                            initialValue={selectedGender}
                            value={selectedGender}
                            onChange={(value) => setSelectedGender(value)}
                            styles={customStyles}
                            isSearchable={false}
                          />
                        </Form.Group>
                      </div>
                      <div className="mt-4">
                        <Form.Label>Date of Birth*</Form.Label>
                        <div className="additional-date-picker">
                          {/* <DatePicker onChange={newValue => setValue(newValue)} value={value} /> */}
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              value={value}
                              loading={isLoading}
                              onChange={(newValue) => {
                                setValue(newValue);
                              }}
                              onMonthChange={handleMonthChange}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                              renderLoading={() => <CalendarPickerSkeleton />}
                              maxDate={maxDate}
                              renderDay={(day, _value, DayComponentProps) => {
                                const isSelected =
                                  !DayComponentProps.outsideCurrentMonth &&
                                  highlightedDays.indexOf(day.date()) > 0;

                                return (
                                  <Badge
                                    key={day.toString()}
                                    overlap="circular"
                                    badgeContent={isSelected ? "🌚" : undefined}
                                  >
                                    <PickersDay {...DayComponentProps} />
                                  </Badge>
                                );
                              }}
                            />
                          </LocalizationProvider>
                        </div>
                      </div>
                      <div className="mt-4">
                        <Form.Group
                          className={`mb-3 ${
                            touched.education && errors.education
                              ? "is-invalid"
                              : ""
                          }`}
                        >
                          <Form.Label>Education*</Form.Label>
                          <Field
                            className={`form-control education-control ${
                              touched.education && errors.education
                                ? "is-invalid"
                                : ""
                            }`}
                            name="education"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="education"
                            className="text-danger text-right"
                          />
                        </Form.Group>
                      </div>
                    </div>
                    <div className="login-btn mt-4">
                      <Button
                        type="submit"
                        className="default-btn"
                        disabled={!value || onboardingUpdate.buttonDisable}
                      >
                        {onboardingUpdate.buttonDisable
                          ? "Loading"
                          : "Submit"}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </FORM>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default AdditionalInformation;
