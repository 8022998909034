import api from "../Environment";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from '../components/Helper/NotificationMessage';
import store from './index';
import { fetchUserDetailsFailure } from "./slices/UserSlice";

const logout = () => {
  store.dispatch(fetchUserDetailsFailure());
  localStorage.removeItem("userId");
  localStorage.removeItem("accessToken");
  localStorage.removeItem("device_unique_id");
}


export const getCommonSaga = async ({
  apiUrl,
  payload,
  success,
  failure,
  baseUrl = null,
  successCallback = false,
  failureCallback = false,
  successNotify = true,
  errorNotify = true,
}) => {
  try {
    const response = await api.postMethod(apiUrl, payload, baseUrl);
    if (response.data.success) {
      if (successCallback) success(response.data.data);
      else store.dispatch(success(response.data.data));
      if (successNotify) getSuccessNotificationMessage(response.data.message);
    } else {
      if (failureCallback) failure(response.data);
      else store.dispatch(failure(response.data));
      if (errorNotify) getErrorNotificationMessage(response.data.error);
      if (response.data.error_code && response.data.error_code === 401) logout();
    }
  } catch (error) {
    if (failureCallback) failure(error);
    else store.dispatch(failure(error));
    if (errorNotify) getErrorNotificationMessage(error.message);
  }
}