import { takeLatest, all } from "redux-saga/effects";
import { getCommonSaga } from "../sagaUtils";
import store from "../index";
import {
  nftUserProfileItemsListSuccess,
  nftUserProfileItemsListFailure,
} from "../slices/NftSlice";
import { marketplaceBaseUrl } from "../../Environment";

function* nftUserProfileItemsListAPI(action) {
  yield getCommonSaga({
    apiUrl: "nft_user_profile_items_list",
    baseUrl: marketplaceBaseUrl,
    payload: action.payload,
    successCallback: true,
    success: (data) => {
      store.dispatch(nftUserProfileItemsListSuccess(data));
    },
    failure: nftUserProfileItemsListFailure,
  });
}

export default function* NftSaga() {
  yield all([
    yield takeLatest("nft/nftUserProfileItemsListStart", nftUserProfileItemsListAPI),
  ]);
}
