import { takeLatest, all } from "redux-saga/effects";
import { getCommonSaga } from "../sagaUtils";
import store from "../index";
import {
    userLoginSuccess,
    userLoginFailure,
    userAuth0LoginSuccess,
    userAuth0LoginFailure,
    userRegisterSuccess,
    userRegisterFailure,
    forgotPasswordSuccess,
    forgotPasswordFailure,
    verifyForgotPasswordSuccess,
    verifyForgotPasswordFailure,
    resetPasswordSuccess,
    resetPasswordFailure,
    fetchUserDetailsSuccess,
    fetchUserDetailsFailure,
    updateUserDetailsSuccess,
    updateUserDetailsFailure,
} from "../slices/UserSlice";

function* userLoginAPI(action) {
  yield getCommonSaga({
    apiUrl: "login",
    payload: action.payload,
    successCallback: true,
    success: (data) => {
      if (data.onboarding_step === 1 || data.code === 258) {
        localStorage.setItem("email", data.email);
      } else {
        localStorage.setItem("userId", data.user_id);
        localStorage.setItem("accessToken", data.token);
        localStorage.setItem("device_unique_id", data.device_unique_id);
        store.dispatch(fetchUserDetailsSuccess(data));
      }
      store.dispatch(userLoginSuccess(data));
    },
    failure: userLoginFailure,
  });
}

function* userAuth0LoginAPI(action) {
  yield getCommonSaga({
    apiUrl: "auth0_login",
    payload: action.payload,
    successCallback: true,
    success: (data) => {
      if (data.onboarding_step === 1 || data.code === 258) {
        localStorage.setItem("email", data.email);
      } else {
        localStorage.setItem("userId", data.user_id);
        // localStorage.setItem("accessToken", data.token);
        localStorage.setItem("device_unique_id", data.device_unique_id);
        store.dispatch(fetchUserDetailsSuccess(data));
      }
      store.dispatch(userAuth0LoginSuccess(data));
    },
    failure: userAuth0LoginFailure,
  });
}

function* userRegisterAPI(action) {
  yield getCommonSaga({
    apiUrl: "register",
    payload: action.payload,
    successCallback: true,
    success: (data) => {
      if (data.user.onboarding_step === 1) {
        localStorage.setItem("email", data.user.email);
        localStorage.setItem("mobile", data.user.mobile);
      } else {
        localStorage.setItem("userId", data.user_id);
        localStorage.setItem("userId", data.user.user_id);
        localStorage.setItem("accessToken", data.user.token);
        store.dispatch(fetchUserDetailsSuccess(data));
      }
      store.dispatch(userRegisterSuccess(data));
    },
    failure: userRegisterFailure,
  });
}

function* forgotPasswordAPI(action) {
  yield getCommonSaga({
    apiUrl: "forgot_password",
    payload: action.payload,
    successCallback: true,
    success: (data) => {
      store.dispatch(
        forgotPasswordSuccess(action)
      )
    },
    failure: forgotPasswordFailure,
  });
}

function* verifyForgotPasswordStartAPI(action) {
  yield getCommonSaga({
    apiUrl: "verify_forgot_password",
    payload: action.payload,
    success: verifyForgotPasswordSuccess,
    failure: verifyForgotPasswordFailure,
  });
}

function* resetPasswordAPI(action) {
  yield getCommonSaga({
    apiUrl: "reset_password",
    payload: action.payload,
    successCallback: true,
    success: (data) =>
      store.dispatch(
        resetPasswordSuccess({ ...data, email: action.payload.email })
      ),
    failure: resetPasswordFailure,
  });
}

function* fetchUserDetailsAPI(action) {
  yield getCommonSaga({
    apiUrl: "profile",
    payload: action.payload,
    successNotify: false,
    success: (data) => store.dispatch(fetchUserDetailsSuccess(data)),
    failure: fetchUserDetailsFailure,
  });
}

function* updateUserDetailsAPI(action) {
    yield getCommonSaga({
        apiUrl: "update_profile",
        payload: action.payload,
        successCallback: true,
        success: data => {
            store.dispatch(updateUserDetailsSuccess(data))
            store.dispatch(fetchUserDetailsSuccess(data))
        },
        failure: updateUserDetailsFailure,
    })
}

export default function* UserSaga() {
    yield all([
        yield takeLatest('user/userLoginStart', userLoginAPI),
        yield takeLatest('user/userAuth0LoginStart', userAuth0LoginAPI),
        yield takeLatest('user/userRegisterStart', userRegisterAPI),
        yield takeLatest('user/forgotPasswordStart', forgotPasswordAPI),
        yield takeLatest('user/verifyForgotPasswordStart', verifyForgotPasswordStartAPI),
        yield takeLatest('user/resetPasswordStart', resetPasswordAPI),
        yield takeLatest('user/fetchUserDetailsStart', fetchUserDetailsAPI),
        yield takeLatest('user/updateUserDetailsStart', updateUserDetailsAPI),

    ]);
}
