import React, { useState, useEffect } from "react";
import {
  Image,
  Button,
  FormGroup,
  Form,
  InputGroup,
  Row,
  Col,
} from "react-bootstrap";
import { onboardingUpdateStart } from "../../store/slices/OnBoardingSlice";
import { useDispatch, useSelector } from "react-redux";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import "@reach/combobox/styles.css";

const AddLocation = (props) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries: ["places"],
  });

  const dispatch = useDispatch();
  const [skipRender, setSkipRender] = useState(true);
  const onboardingUpdate = useSelector(
    (state) => state.onboard.onboardingUpdate
  );
  const profile = useSelector((state) => state.user.profile);
  const [locationData, setLocationData] = useState({
    latitude: profile.data.latitude ? profile.data.latitude : 37,
    longitude: profile.data.longitude ? profile.data.longitude : -95,
    address: profile.data.address ? profile.data.address : "",
  });

  const handleSubmit = () => {
    dispatch(onboardingUpdateStart(locationData));
  };

  useEffect(() => {
    if (
      !skipRender &&
      !onboardingUpdate.loading &&
      Object.keys(onboardingUpdate.data).length > 0
    ) {
      // props.nextStep();
    }
    setSkipRender(false);
  }, [onboardingUpdate]);

  const Marker = () => <div className="marker"></div>;

  const containerStyle = {
    height: "50vh",
    width: "100%",
  };

  return (
    <>
      {/* <div className="header-back-btn">
        <Button className="back-btn" onClick={() => props.prevStep(4)}>
          <Image
            src={window.location.origin + "/assets/img/back.svg"}
            className="back-btn"
          />
        </Button>
      </div> */}
      <div className="create-link-sec login-auth-sec ">
        <div className="sign-in-sec mt-4">
          <Form>
            <div className="account-tab-content-title-sec create-link-content">
              <h4>Location</h4>
              <p>Please choose your location.</p>
            </div>
            {isLoaded ? (
              <>
                <Row className="justify-content-md-center">
                  <Col md={10} xl={6} lg={6}>
                    <FormGroup className="mb-5 mt-5">
                      <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1">
                          <Image
                            src={
                              window.location.origin +
                              "/assets/img/auth/search-icon.svg"
                            }
                          />
                        </InputGroup.Text>
                        <PlacesAutocomplete setLocationData={setLocationData} locationData={locationData} />
                      </InputGroup>
                    </FormGroup>
                    <div
                      style={{
                        height: "50vh",
                        width: "100%",
                        marginBottom: "3em",
                      }}
                    >
                      <GoogleMap
                        zoom={10}
                        center={{
                          lat: locationData.latitude,
                          lng: locationData.longitude,
                        }}
                        google={props.google}
                        mapContainerClassName="map-container"
                        mapContainerStyle={containerStyle}
                      >
                        <Marker
                          position={{
                            lat: locationData.latitude,
                            lng: locationData.longitude,
                          }}
                        />
                      </GoogleMap>
                    </div>
                    <div className="login-btn mt-4">
                      <Button
                        className="default-btn"
                        disabled={
                          !locationData.address ||
                          onboardingUpdate.buttonDisable
                        }
                        onClick={() => handleSubmit()}
                      >
                        {onboardingUpdate.buttonDisablet ? "Loading" : "Submit"}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </>
            ) : (
              "Loading..."
            )}
          </Form>
        </div>
      </div>
    </>
  );
};

export default AddLocation;

const PlacesAutocomplete = ({ setLocationData, locationData }) => {
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete();

  const handleSelect = async (address) => {
    console.log("address", address);
    setValue(address, false);
    clearSuggestions();

    const results = await getGeocode({ address });
    const { lat, lng } = await getLatLng(results[0]);
    setLocationData({
      latitude: lat,
      longitude: lng,
      address: address,
    });
  };

  return (
    <>
      <Combobox className="location-sec" onSelect={handleSelect}>
        <ComboboxInput
          value={value}
          onChange={(e) => setValue(e.target.value)}
          className="combobox-input form-control"
          placeholder={locationData.address ? locationData.address : "Search an address"}
        />
        <ComboboxPopover>
          <ComboboxList className="place-suggestions">
            {status === "OK" &&
              data.map(({ place_id, description }) => (
                <ComboboxOption key={place_id} value={description} />
              ))}
          </ComboboxList>
        </ComboboxPopover>
      </Combobox>
    </>
  );
};
