import React, { useState, useEffect } from "react";
import { Image, Button, Form, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  onboardingCategoriesStart,
  updateOnboardingCategoriesStart,
} from "../../store/slices/OnBoardingSlice";
import Skeleton from "react-loading-skeleton";

const ChooseCategory = (props) => {
  const dispatch = useDispatch();
  const onboardingCategories = useSelector(
    (state) => state.onboard.onboardingCategories
  );
  const updateOnboardingCategories = useSelector(
    (state) => state.onboard.updateOnboardingCategories
  );
  const profile = useSelector((state) => state.user.profile);
  const [selectedCategories, setSeletedCategories] = useState([]);
  const [skipRender, setSkipRender] = useState(true);

  useEffect(() => {
    dispatch(onboardingCategoriesStart());
    if (
      !profile.loading &&
      profile.data.user_categories &&
      profile.data.user_categories.length > 0
    )
      setSeletedCategories(
        profile.data.user_categories.map(
          (category, i) => category.onboarding_category_id
        )
      );
  }, [profile]);

  const addCategory = (categoryId) => {
    setSeletedCategories([...selectedCategories, categoryId]);
  };

  const removeCategory = (categoryId) => {
    setSeletedCategories(
      selectedCategories.filter((cat) => cat !== categoryId)
    );
  };

  const handleSubmit = () => {
    if (selectedCategories.length > 0) {
      const data = JSON.stringify(selectedCategories);
      dispatch(
        updateOnboardingCategoriesStart({
          onboarding_category_id: data.substring(1, data.length - 1),
        })
      );
    }
  };

  useEffect(() => {
    if (
      !skipRender &&
      !updateOnboardingCategories.loading &&
      Object.keys(updateOnboardingCategories.data).length > 0
    ) {
      // props.nextStep();
    }
    setSkipRender(false);
  }, [updateOnboardingCategories]);

  return (
    <>
      {/* <div className="header-back-btn">
        <Button className="back-btn" onClick={() => props.prevStep(2)}>
          <Image
            src={window.location.origin + "/assets/img/back.svg"}
            className="back-btn"
          />
        </Button>
      </div> */}
      <div className="create-link-sec login-auth-sec ">
        <div className="sign-in-sec mt-4">
          <Form>
            <div className="account-tab-content-title-sec create-link-content">
              <h4>Choose Category</h4>
              {selectedCategories.length > 0 ? (
                <p>You choosen {selectedCategories.length} items</p>
              ) : (
                <p>Please choose at least 1 tag for us to know you better!</p>
              )}
            </div>
            <Row className="justify-content-md-center">
              <Col md={10} xl={6} lg={6}>
                <div className="category-list">
                  {onboardingCategories.loading ? (
                    <ul class="list-unstyled">
                      {[...Array(9)].map((i, j) => (
                        <Skeleton
                          className="category-outline-btn"
                          width={j % 3 === 0 ? 250 : 100}
                        />
                      ))}
                    </ul>
                  ) : onboardingCategories.data.onboarding_categories &&
                    onboardingCategories.data.onboarding_categories.length >
                      0 ? (
                    <ul class="list-unstyled">
                      {onboardingCategories.data.onboarding_categories.map(
                        (category, i) => (
                          <>
                            {selectedCategories.includes(
                              category.onboarding_category_id
                            ) ? (
                              <li
                                onClick={() =>
                                  removeCategory(
                                    category.onboarding_category_id
                                  )
                                }
                                className="category-outline-btn active"
                              >
                                <span>
                                  {" "}
                                  <Image
                                    src={
                                      window.location.origin +
                                      "/assets/img/modal-close.svg"
                                    }
                                  />
                                </span>
                                {category.name}
                              </li>
                            ) : (
                              <li
                                onClick={() =>
                                  addCategory(category.onboarding_category_id)
                                }
                                className="category-outline-btn"
                              >
                                {category.name}
                              </li>
                            )}
                          </>
                        )
                      )}
                    </ul>
                  ) : null}
                </div>
                <div className="login-btn mt-4">
                  <Button
                    className="default-btn"
                    disabled={
                      selectedCategories.length === 0 ||
                      updateOnboardingCategories.buttonDisable
                    }
                    onClick={() => handleSubmit()}
                  >
                    {updateOnboardingCategories.buttonDisable
                      ? "Loading"
                      : "Submit"}
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </>
  );
};

export default ChooseCategory;
