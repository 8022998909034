import React, { useState, useEffect } from "react";
import { Row, Col, Image, Container, Dropdown , Button} from "react-bootstrap";
import { Link } from "react-router-dom";


const OtherProfileHashtags = (props) => {

  return (
    <>
      <div className="other-profile-hashtags-sec">
        <div className="other-profile-hashtags-box">
            <div className="other-profile-hashtags-header-sec">
                <div className="other-profile-hashtags-header-title-sec">
                    <Image
                        className="other-profile-hashtags-header-title-icon"
                        src={
                        window.location.origin +
                        "/assets/img/other-profile/user-image-1.png"
                        }
                    />
                    <span># Hashtags</span>
                </div>
                <div className="other-profile-hashtags-more-btn-sec">
                    <Link to="#">
                        More
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            fill="none"
                            viewBox="0 0 6 9"
                            >
                            <path
                                stroke="#000"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.3"
                                d="M1.168 1.42l3.331 3.262-3.331 3.26"
                            ></path>
                        </svg>
                    </Link>
                </div>
            </div>
            <div className="other-profile-hashtags-body-sec">
                <ul className="other-profile-hashtags-list-sec list-unstyled">
                    <li>
                        <Link to="#">
                            #Records
                        </Link>
                    </li>
                    <li>
                        <Link to="#">
                            #Fashion
                        </Link>
                    </li>
                    <li>
                        <Link to="#">
                            #Music
                        </Link>
                    </li>
                    <li>
                        <Link to="#">
                            #Art
                        </Link>
                    </li>
                    <li>
                        <Link to="#">
                            #Design
                        </Link>
                    </li>
                    <li>
                        <Link to="#">
                            #Artificial Intelligence
                        </Link>
                    </li>
                    <li>
                        <Link to="#">
                            #Food
                        </Link>
                    </li>
                    <li>
                        <Link to="#">
                            #BookClubs
                        </Link>
                    </li>
                    <li>
                        <Link to="#">
                            #Films
                        </Link>
                    </li>
                    <li>
                        <Link to="#">
                            #Short
                        </Link>
                    </li>
                    <li>
                        <Link to="#">
                            #Very Very Long Text Here 
                        </Link>
                    </li>
                    <li>
                        <Link to="#">
                            #Long Text Here
                        </Link>
                    </li>
                    <li>
                        <Link to="#">
                            #Films
                        </Link>
                    </li>
                    <li>
                        <Link to="#">
                            #Music
                        </Link>
                    </li>
                    <li>
                        <Link to="#">
                            #Artificial Intelligence
                        </Link>
                    </li>
                    <li>
                        <Link to="#">
                            #Food
                        </Link>
                    </li>
                    <li>
                        <Link to="#">
                            #BookClubs
                        </Link>
                    </li>
                    <li>
                        <Link to="#">
                            #Films
                        </Link>
                    </li>
                    <li>
                        <Link to="#">
                            #Short
                        </Link>
                    </li>
                    <li>
                        <Link to="#">
                            #Films
                        </Link>
                    </li>
                    <li>
                        <Link to="#">
                            #Music
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
      </div>
    </>
  )
}

export default OtherProfileHashtags;
