import { takeLatest, all } from "redux-saga/effects";
import { getCommonSaga } from "../sagaUtils";
import store from "../index";
import {
  linkTreeListSuccess,
  linkTreeListFailure,
  linkTreeSaveSuccess,
  linkTreeSaveFailure,
  linkTreeDeleteSuccess,
  linkTreeDeleteFailure,
  linkTreeStatusUpdateSuccess,
  linkTreeStatusUpdateFailure,
} from "../slices/LinkTreeSlice";

function* linkTreeListAPI(action) {
  yield getCommonSaga({
    apiUrl: "link_trees_list",
    payload: action.payload,
    successNotify: false,
    success: (data) => store.dispatch(linkTreeListSuccess(data)),
    failure: linkTreeListFailure,
  });
}

function* linkTreeSaveAPI(action) {
  yield getCommonSaga({
    apiUrl: "link_tree_save",
    payload: action.payload,
    successNotify: true,
    success: (data) => store.dispatch(linkTreeSaveSuccess(data)),
    failure: linkTreeSaveFailure,
  });
}

function* linkTreeDeleteAPI(action) {
  yield getCommonSaga({
    apiUrl: "link_tree_delete",
    payload: action.payload,
    successNotify: true,
    success: (data) => store.dispatch(linkTreeDeleteSuccess(data)),
    failure: linkTreeDeleteFailure,
  });
}

function* linkTreeStatusUpdateAPI(action) {
  yield getCommonSaga({
    apiUrl: "link_tree_status_update",
    payload: action.payload,
    successNotify: true,
    success: (data) => store.dispatch(linkTreeStatusUpdateSuccess(data)),
    failure: linkTreeStatusUpdateFailure,
  });
}

export default function* LinkTreeSaga() {
  yield all([
    yield takeLatest("linkTree/linkTreeListStart", linkTreeListAPI),
    yield takeLatest("linkTree/linkTreeSaveStart", linkTreeSaveAPI),
    yield takeLatest("linkTree/linkTreeDeleteStart", linkTreeDeleteAPI),
    yield takeLatest("linkTree/linkTreeStatusUpdateStart", linkTreeStatusUpdateAPI),
  ]);
}
