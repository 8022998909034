import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Image,
  Container,
  Dropdown,
  Button,
  Tab,
  Nav,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import OnBoardingHeader from "../OnBoarding/OnBoardingHeader";
import AddLinkCard from "./AddLinkCard";
import AddNewLinkModal from "./AddNewLinkModal";
import configuration from "react-global-configuration";
import CopyToClipboard from "react-copy-to-clipboard";
import { getSuccessNotificationMessage } from "../Helper/NotificationMessage";
import { linkTreeListStart } from "../../store/slices/LinkTreeSlice";
import NoDataFound from "../Helper/NoDataFound";
import LinksSkeleton from "../Account/LinksSkeleton";

const OtherProfileIndex = (props) => {
  const dispatch = useDispatch();

  const profile = useSelector((state) => state.user.profile);

  const [addNewLink, setAddNewLink] = useState(false);

  const [formattedLink, setFormattedLink] = useState(
    configuration.get("configData.frontend_url").replace("https://", "")
  );

  const closeAddNewLinkModal = () => {
    setAddNewLink(false);
  };

  const onCopy = () => {
    getSuccessNotificationMessage("Url copied");
  };

  const links = useSelector((state) => state.linkTree.links);

  useEffect(() => {
    dispatch(linkTreeListStart({ user_id: profile.data.user_id }));
  }, []);

  return (
    <>
      <div className="add-link-sec">
        <div className="add-link-box">
          <Container>
            <Row>
              <Col md="12">
                <div className="account-back-btn-sec">
                  <Link to="/profile" className="account-back-btn">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-chevron-left"
                    >
                      <polyline points="15 18 9 12 15 6"></polyline>
                    </svg>
                    My Profile
                  </Link>
                </div>
                <div className="add-link-item">
                  <div className="add-link-header-sec">
                    <div className="add-link-left-sec">
                      <div className="add-link-user-img-sec">
                        <Image
                          className="add-link-user-img-icon"
                          src={
                            window.location.origin +
                            "/assets/img/other-profile/user-image-1.png"
                          }
                        />
                      </div>
                      <div className="other-profile-user-name-copy-box">
                        <div className="other-profile-user-name-copy-card">
                          <Image
                            className="other-profile-user-name-copy-logo"
                            src={
                              window.location.origin +
                              "/assets/img/hashtagbio-logo.png"
                            }
                          />
                          <p>
                            {formattedLink}
                            {profile.data.username}
                          </p>
                        </div>
                        <div className="other-profile-user-id-copy">
                          <CopyToClipboard
                            text={`${configuration.get(
                              "configData.frontend_url"
                            )}${profile.data.username}`}
                            onCopy={() => onCopy()}
                          >
                            <Image
                              className="other-profile-user-name-copy-logo"
                              src={
                                window.location.origin + "/assets/img/copy.png"
                              }
                            />
                          </CopyToClipboard>
                        </div>
                      </div>
                    </div>
                    <div className="add-link-right-sec">
                      <Button
                        className="add-new-link-btn"
                        onClick={() => setAddNewLink(true)}
                      >
                        <Image
                          className="add-new-link-attach-icon"
                          src={
                            window.location.origin +
                            "/assets/img/add-link/attachment-icon.svg"
                          }
                        />
                        Add New Link
                      </Button>
                    </div>
                  </div>
                  <div className="add-link-body-sec">
                    <div className="add-link-tab-sec">
                      <Tab.Container
                        id="left-tabs-example"
                        defaultActiveKey="links"
                      >
                        <Row className="justify-content-md-center">
                          <Col md={12} xl={9} lg={12}>
                            <Nav variant="pills">
                              <Nav.Item>
                                <Nav.Link eventKey="links">Links</Nav.Link>
                              </Nav.Item>
                              {/* <Nav.Item>
                                        <Nav.Link eventKey="analytics">Analytics</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                        <Nav.Link eventKey="settings">Settings</Nav.Link>
                                        </Nav.Item> */}
                            </Nav>
                          </Col>
                          <Col md={12} xl={9} lg={12}>
                            <Tab.Content>
                              <Tab.Pane eventKey="links">
                                <div className="add-link-card-sec">
                                  <div className="add-link-card-item">
                                    {links.loading ? (
                                      <LinksSkeleton />
                                    ) : Object.keys(links.data).length > 0 &&
                                      links.data.links.length > 0 ? (
                                      links.data.links.map((link) => (
                                        <>
                                          <AddLinkCard link={link} />
                                        </>
                                      ))
                                    ) : (
                                      <NoDataFound />
                                    )}
                                  </div>
                                </div>
                              </Tab.Pane>
                              {/* <Tab.Pane eventKey="analytics">Second tab content</Tab.Pane>
                                        <Tab.Pane eventKey="settings">Second tab content</Tab.Pane> */}
                            </Tab.Content>
                          </Col>
                        </Row>
                      </Tab.Container>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      {addNewLink && (
        <AddNewLinkModal
          addNewLink={addNewLink}
          closeAddNewLinkModal={closeAddNewLinkModal}
          setAddNewLink={setAddNewLink}
        />
      )}
    </>
  );
};

export default OtherProfileIndex;
