import React, { useState } from "react";
import { Container, Image, Navbar, Nav } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getErrorNotificationMessage } from "../../components/Helper/NotificationMessage";
import CustomLazyLoad from "../customComponents/CustomLazyLoad";
const auth0 = window.auth0;

const OnBoardingHeader = (props) => {
  const [btnLoading, setBtnLoading] = useState(false);

  const options = {
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
  };

  const profile = useSelector((state) => state.user.profile);

  const handleLogout = async (values) => {
    setBtnLoading(true);

    const webAuth = await new auth0.WebAuth(options);
    webAuth.logout(
      { returnTo: window.location.origin + "/logout" },
      function (err) {
        setBtnLoading(false);
        console.error(err);
        getErrorNotificationMessage(
          err.error_description ?? "Something went wrong"
        );
      }
    );
  };

  return (
    <>
      <div className="onboarding-header-sec">
        <Container>
          <div className="onboarding-header-card">
            {/* <div className="profile-logo">
              <Image className="auth-logo-img" src={profile.data.picture} />
              <h4>Hi, {profile.data.name}!</h4>
            </div>
            <div className="profile-menu">
              <Link to="/account">Settings</Link>
              <button
                class="default-btn btn btn-primary"
                onClick={handleLogout}
                disabled={btnLoading}
              >
                {btnLoading ? "Loading" : "Logout"}
              </button>
            </div> */}
            <Navbar expand="lg">
              <Navbar.Brand>
                <div className="profile-logo">
                  {/* <Image className="auth-logo-img" src={profile.data.picture} /> */}
                  <CustomLazyLoad
                    src={profile.data.picture}
                    classes={"auth-logo-img"}
                    placeholderSrc={window.location.origin + "/assets/img/loader.svg"}
                  />
                  <h4>Hi, {profile.data.name}!</h4>
                </div>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 0 24 24"
                >
                  <path d="M7 0H4a4 4 0 00-4 4v3a4 4 0 004 4h3a4 4 0 004-4V4a4 4 0 00-4-4zm2 7a2 2 0 01-2 2H4a2 2 0 01-2-2V4a2 2 0 012-2h3a2 2 0 012 2zM7 13H4a4 4 0 00-4 4v3a4 4 0 004 4h3a4 4 0 004-4v-3a4 4 0 00-4-4zm2 7a2 2 0 01-2 2H4a2 2 0 01-2-2v-3a2 2 0 012-2h3a2 2 0 012 2zM20 13h-3a4 4 0 00-4 4v3a4 4 0 004 4h3a4 4 0 004-4v-3a4 4 0 00-4-4zm2 7a2 2 0 01-2 2h-3a2 2 0 01-2-2v-3a2 2 0 012-2h3a2 2 0 012 2zM14 7h3v3a1 1 0 002 0V7h3a1 1 0 000-2h-3V2a1 1 0 00-2 0v3h-3a1 1 0 000 2z"></path>
                </svg>
              </Navbar.Toggle>
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ms-auto">
                  <Nav.Link className="btn-header">
                    <Link to="/account">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="#797878"
                        viewBox="0 0 24 24"
                      >
                        <path d="M1 4.75h2.736a3.728 3.728 0 007.195 0H23a1 1 0 000-2H10.931a3.728 3.728 0 00-7.195 0H1a1 1 0 000 2zM7.333 2a1.75 1.75 0 11-1.75 1.75A1.752 1.752 0 017.333 2zM23 11h-2.736a3.727 3.727 0 00-7.194 0H1a1 1 0 000 2h12.07a3.727 3.727 0 007.194 0H23a1 1 0 000-2zm-6.333 2.75a1.75 1.75 0 111.75-1.75 1.752 1.752 0 01-1.75 1.75zM23 19.25H10.931a3.728 3.728 0 00-7.195 0H1a1 1 0 000 2h2.736a3.728 3.728 0 007.195 0H23a1 1 0 000-2zM7.333 22a1.75 1.75 0 111.75-1.75A1.753 1.753 0 017.333 22z"></path>
                      </svg>
                      Settings
                    </Link>
                  </Nav.Link>
                  <Nav.Link
                    href="#"
                    className="default-btn"
                    onClick={handleLogout}
                    disabled={btnLoading}
                  >
                    {" "}
                    {btnLoading ? "Loading" : "Logout"}
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </div>
        </Container>
      </div>
    </>
  );
};

export default OnBoardingHeader;
