import React, { useEffect, useState } from "react";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import { fetchUserDetailsStart } from "../../store/slices/UserSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import OnBoardingHeader from "../OnBoarding/OnBoardingHeader";
import PageLoaderIndex from "../PageLoader/PageLoaderIndex";

const EmptyLayout = ({ children }) => <>{children}</>;

const MainLayout = ({ children }) => {
  const [firstRender, setFirstRender] = useState(true);
  const profile = useSelector((state) => state.user.profile);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchUserDetailsStart());
  }, []);

  useEffect(() => {
    if (
      !firstRender &&
      !profile.loading &&
      Object.keys(profile.data).length === 0
    ) {
      navigate("/logout");
    }
    setFirstRender(false);
  }, [profile]);

  return (
    <>
      {/* <Header /> */}
      {profile.loading
        ? <PageLoaderIndex />
        : Object.keys(profile.data).length > 0 && (
            <div className="single-account-sec other-profile-sec">
              <OnBoardingHeader />
              <div className="main-wrapper">{children}</div>
            </div>
          )}
      {/* <Footer /> */}
    </>
  );
};

export { MainLayout, EmptyLayout };
