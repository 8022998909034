import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  userAuth0LoginStart,
} from "../../store/slices/UserSlice";
import { getErrorNotificationMessage } from "../../components/Helper/NotificationMessage";
import PageLoaderIndex from "../PageLoader/PageLoaderIndex";
const auth0 = window.auth0;

const exceptionPaths = ["/view-private-key", "/logout"];

const AuthLogin = (props) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth0Login = useSelector((state) => state.user.auth0Login);

  const location = useLocation();

  const [skipRender, setSkipRender] = useState(true);

  const [loginData, setloginData] = useState({
    login_by: process.env.REACT_APP_LOGIN_BY,
    device_token: process.env.REACT_APP_DEVICE_TYPE,
    device_type: process.env.REACT_APP_DEVICE_TOKEN,
  });

  const options = {
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
    redirectUri: window.location.origin + "/auth-login",
    responseType: 'token'
  };

  useEffect(() => {
    console.log("authResult", window.location.hash);
    const webAuth = new auth0.WebAuth(options);
    webAuth.parseHash({ hash: window.location.hash, state: localStorage.getItem('state'), nonce: localStorage.getItem('nonce') }, function(err, authResult) {
      console.log("authResult", authResult, err);
      if (err || authResult == undefined || !authResult.accessToken) {
        getErrorNotificationMessage(
          err.error_description ?? "Something went wrong"
        );
        navigate('/');
        return false;
      }
      localStorage.setItem("accessToken", authResult.accessToken);
      webAuth.client.userInfo(authResult.accessToken, function(err, user) {
        console.log("user", user, err);
        // Now you have the user's information
        if (err || user == undefined || !user) {
          getErrorNotificationMessage(
            err.error_description ?? "Something went wrong"
          );
          navigate('/');
          return false;
        }
        dispatch(userAuth0LoginStart({ 
          ...loginData,
          email: user.email ?? "",
          picture: user.picture,
          name: user.nickname,
          auth0_user_id: user.sub.split("|")[1] ?? "",
          login_by: user.sub.split("|")[0] == "auth0" ? "manual" : user.sub.split("|")[0],
        }));
      });
    });
  }, []);

  useEffect(() => {
    if (!skipRender) {
      if (
        !auth0Login.loading &&
        Object.keys(auth0Login.data).length > 0
      ) {
        if (auth0Login.data.private_passphrase && auth0Login.data.private_passphrase != "") {
          navigate("/view-private-key");
        } else {
          navigate("/profile");
        }
      }
    }
    setSkipRender(false);
  }, [auth0Login]);

  return (
    <div>
      <PageLoaderIndex />
    </div>
  );
};


export default AuthLogin;
