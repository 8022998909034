import React, { useState, useEffect, useRef } from "react";
import {
  Image,
  Button,
  FormGroup,
  InputGroup,
  Form,
  Row,
  Col,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
  isPossiblePhoneNumber,
} from "react-phone-number-input";
import { sendEmailInvitationStart } from "../../store/slices/OnBoardingSlice";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";

const InviteFriends = (props) => {
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const [skipRender, setSkipRender] = useState(true);
  const sendEmailInvitation = useSelector(
    (state) => state.onboard.sendEmailInvitation
  );

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
    mobile: Yup.string().required("Required"),
  });

  const validateMobileNumber = (value) => {
    if (value) {
      if (!isPossiblePhoneNumber(value)) {
        formRef.current.setFieldError("mobile", "Not a Phone number");
        return false;
      } else if (!isValidPhoneNumber(value)) {
        formRef.current.setFieldError("mobile", "Invalid Phone number");
        return false;
      } else {
        formRef.current.setFieldError("mobile", "");
        return true;
      }
    } else {
      formRef.current.setFieldError("mobile", "Required");
      return false;
    }
  };

  const handleSubmit = (values) => {
    const intlNo = formatPhoneNumberIntl(values.mobile);
    const countryCode = intlNo.substring(
      intlNo.indexOf("+") + 1,
      intlNo.indexOf(" ")
    );
    const mobile = intlNo
      .substring(intlNo.indexOf(" "), intlNo.length)
      .replaceAll(" ", "");
    dispatch(
      sendEmailInvitationStart({
        ...values,
        mobile: mobile,
        country_code: countryCode,
      })
    );
  };

  useEffect(() => {
    if (
      !skipRender &&
      !sendEmailInvitation.loading &&
      Object.keys(sendEmailInvitation.data).length > 0
    ) {
      formRef.current.resetForm();
    }
    setSkipRender(false);
  }, [sendEmailInvitation]);

  return (
    <>
      {/* <div className="header-back-btn">
        <Button className="back-btn" onClick={() => props.setStep(6)}>
          <Image
            src={window.location.origin + "/assets/img/back.svg"}
            className="back-btn"
          />
        </Button>
      </div> */}
      <div className="create-link-sec login-auth-sec ">
        <div className="sign-in-sec mt-4">
          <div className="account-tab-content-title-sec create-link-content">
            <h4>Your friends aren't here yet?</h4>
            <p>
              Invite your friends and get 100 points for every invited who
              joined
            </p>
          </div>
          <Row className="justify-content-md-center">
            <Col md={10} xl={6} lg={6}>
              {/* <div className="mail-box-img text-center mb-5">
                <Image
                  src={window.location.origin + "/assets/img/auth/email.svg"}
                />
              </div>
              <div className="invite-link-title">
                <h4>Send invitation via:</h4>
              </div> */}
              <Formik
                initialValues={{
                  email: "",
                  mobile: "",
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                innerRef={formRef}
              >
                {({ errors, values, touched, setFieldValue }) => (
                  <FORM className="auth-form">
                    <FormGroup className="mb-3">
                      <Form.Label>E-mail*</Form.Label>
                      <InputGroup
                        className={`mb-3 ${
                          touched.email && errors.email ? "is-invalid" : ""
                        }`}
                      >
                        <InputGroup.Text id="basic-addon1">
                          <Image
                            src={
                              window.location.origin +
                              "/assets/img/auth/email.svg"
                            }
                          />
                        </InputGroup.Text>
                        <Field
                          className={`form-control ${
                            touched.email && errors.email ? "is-invalid" : ""
                          }`}
                          type="email"
                          placeholder="Enter Your Email Address"
                          name="email"
                        />
                      </InputGroup>
                      <ErrorMessage
                        component={"div"}
                        name="email"
                        className="text-danger small"
                      />
                    </FormGroup>
                    <FormGroup
                      className={`mb-3 ${
                        touched.mobile && errors.mobile ? "is-invalid" : ""
                      }`}
                    >
                      <Form.Label>Phone*</Form.Label>
                      <PhoneInput
                        defaultCountry="IN"
                        placeholder="Enter phone number"
                        value={values.mobile}
                        onChange={(value) => setFieldValue("mobile", value)}
                        onBlur={() => validateMobileNumber(values.mobile)}
                        international
                        countryCallingCodeEditable={false}
                        className={`${
                          touched.mobile && errors.mobile ? "is-invalid" : ""
                        }`}
                      />
                    </FormGroup>
                    <div className="login-btn mt-4">
                      <Button
                        type="submit"
                        disabled={sendEmailInvitation.buttonDisable}
                        className="default-btn"
                      >
                        {sendEmailInvitation.buttonDisable
                          ? " loading"
                          : "Invite"}
                      </Button>
                    </div>

                    {/* <div className="home-page-link mt-5 text-center">
                      <Link to="/profile">Go To Homepage</Link>
                    </div> */}
                  </FORM>
                )}
              </Formik>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default InviteFriends;
