import React, { useEffect, useRef, useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Image,
  Button,
  InputGroup,
  FormGroup,
} from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import "react-phone-number-input/style.css";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { forgotPasswordStart } from "../../store/slices/UserSlice";
import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
  isPossiblePhoneNumber,
} from "react-phone-number-input";
import ForgetPasswordVerificationModal from "./ForgetPasswordVerificationModal";
import { useTranslation } from "react-multi-lang";
import Footer from "../Layout/Footer/Footer";
import { getSuccessNotificationMessage, getErrorNotificationMessage } from "../../components/Helper/NotificationMessage";
const auth0 = window.auth0;

const ForgotPasswordIndex = (props) => {
  const location = useLocation();

  const [value, setValue] = useState();
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState("");
  const [skipRender, setSkipRender] = useState(true);
  const [passwordVerification, setPasswordVerification] = useState(null);
  const [forgotPasswordData, setForgotPasswordData] = useState({
    attribute: "email",
    value: "",
  });
  const [btnLoading, setBtnLoading] = useState(false);

  const options = {
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
  };

  const t = useTranslation("forget_password");

  const formRef = useRef(null);
  const dispatch = useDispatch();

  const forgotPassword = useSelector((state) => state.user.forgotPassword);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email(t('invalid_email')).required(t('required')),
  });

  const mobileValidationSchema = Yup.object().shape({
    mobile: Yup.string().required(t('required')),
  });

  const handleForgotPassword = async (values) => {
    setBtnLoading(true);
    const webAuth = new auth0.WebAuth(options);
    webAuth.changePassword({
      connection: 'Username-Password-Authentication',
      email:   values.email
    }, function (err, resp) {
      if(err){
        console.log(err.message);
        getErrorNotificationMessage(
          err.message ?? "Something went wrong"
        );
      }else{
        console.log(resp);
        getSuccessNotificationMessage(resp ?? "Sent reset pasword link to your mail, please check");
      }
      setBtnLoading(false);
    });
    // setEmail(values.email);
    // setForgotPasswordData({
    //   attribute: "email",
    //   value: values.email,
    // });
    // dispatch(forgotPasswordStart(values));
  };

  const handleForgotPasswordByMobile = (values) => {
    if (validateMobileNumber(values.mobile)) {
      const intlNo = formatPhoneNumberIntl(values.mobile);
      const countryCode = intlNo.substring(
        intlNo.indexOf("+") + 1,
        intlNo.indexOf(" ")
      );
      const mobile = intlNo
        .substring(intlNo.indexOf(" "), intlNo.length)
        .replaceAll(" ", "");
      setForgotPasswordData({
        attribute: "mobile",
        value: mobile,
      });
      dispatch(
        forgotPasswordStart({
          mobile: mobile,
        })
      );
    }
  };

  const validateMobileNumber = (value) => {
    if (value) {
      if (!isPossiblePhoneNumber(value)) {
        formRef.current.setFieldError("mobile", t('incorrect_format'));
        return false;
      } else if (!isValidPhoneNumber(value)) {
        formRef.current.setFieldError("mobile", t('invalid_phone_number'));
        return false;
      } else {
        formRef.current.setFieldError("mobile", "");
        return true;
      }
    } else {
      formRef.current.setFieldError("mobile", t('required'));
      return false;
    }
  };

  useEffect(() => {
    if (
      !skipRender &&
      !forgotPassword.loading &&
      Object.keys(forgotPassword.data).length > 0
    ) {
      setPasswordVerification(true);
    }
    setSkipRender(false);
  }, [forgotPassword]);

  const closePasswordVerificationModal = () => {
    setPasswordVerification(null);
  };

  return (
    <div className="auth-sec">
      <div className="auth-left-sec">
        <div className="auth-left-img-sec">
          <Image
            className="auth-left-img"
            src={window.location.origin + "/assets/img/auth/login-left-img.png"}
          />
        </div>
      </div>
      <div className="auth-right-sec">
        <div className="auth-right-box">
          <div className="auth-right-card">
            <div className="auth-form-sec">
              <Row className="justify-content-md-center">
                <Col md={12} xl={8} lg={8}>
                  <div className="auth-logo-img-sec">
                    <Image
                      className="auth-logo-img"
                      src={window.location.origin + "/assets/img/auth/logo.svg"}
                    />
                  </div>
                  <div className="login-nav-link forgot-password-nav-link">
                    <ul>
                      <li
                        className={
                          location.pathname === "/forgot-password"
                            ? "active"
                            : ""
                        }
                      >
                        <Link to="#">{t("forgot_password")}</Link>
                      </li>
                    </ul>
                  </div>
                  <div className="notes-auth pb-3">
                    {t('forgot_password_para')}
                  </div>
                  {/* <div className="login-nav-link">
                    <ul>
                      <li
                        className={step === 1 ? "active" : ""}
                        onClick={() => setStep(1)}
                      >
                        <Link to="#">{t('email')}</Link>
                      </li>
                      <li
                        className={step === 2 ? "active" : ""}
                        onClick={() => setStep(2)}
                      >
                        <Link to="#">{t('mobile')}</Link>
                      </li>
                    </ul>
                  </div> */}
                  {step === 1 && (
                    <Formik
                      initialValues={{
                        email: "",
                      }}
                      validationSchema={validationSchema}
                      onSubmit={(values) => handleForgotPassword(values)}
                      innerRef={formRef}
                    >
                      {({ errors, values, touched, setFieldValue }) => (
                        <FORM>
                          <div className="sign-in-sec mt-4">
                            <FormGroup className="mb-4">
                              <Form.Label>{t('email')} *</Form.Label>
                              <InputGroup
                                className={`mb-0 ${
                                  touched.email && errors.email
                                    ? "is-invalid"
                                    : ""
                                }`}
                              >
                                <InputGroup.Text>
                                  <Image
                                    src={
                                      window.location.origin +
                                      "/assets/img/auth/email.svg"
                                    }
                                  />
                                </InputGroup.Text>
                                <Field
                                  className={`form-control ${
                                    touched.email && errors.email
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  type="email"
                                  placeholder={t('email')}
                                  name="email"
                                />
                              </InputGroup>
                              <ErrorMessage
                                component={"div"}
                                name="email"
                                className="text-right"
                              />
                            </FormGroup>
                            <div className="login-btn">
                              <Button
                                className="default-btn"
                                type="submit"
                                disabled={btnLoading}
                              >
                                {btnLoading
                                  ? t("loading")
                                  : t("submit") }
                              </Button>
                            </div>
                            <div className="user-auth-link-sec">
                              <ul className="list-unstyled user-auth-link">
                                <li>
                                  <Link to="/">{t('sign_in')}</Link>
                                </li>
                                <li>
                                  <Link to="/register">{t('sign_up')}</Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </FORM>
                      )}
                    </Formik>
                  )}
                  {step === 2 && (
                    <Formik
                      initialValues={{
                        mobile: "",
                      }}
                      validationSchema={mobileValidationSchema}
                      onSubmit={(values) =>
                        handleForgotPasswordByMobile(values)
                      }
                      innerRef={formRef}
                    >
                      {({ errors, values, touched, setFieldValue }) => (
                        <FORM>
                          <div className="sign-in-sec mt-4">
                            <FormGroup className="mb-4">
                              <Form.Label>{t('phone_number')} *</Form.Label>
                              <PhoneInput
                                defaultCountry="IN"
                                placeholder={t('enter_phone_number')}
                                value={values.mobile}
                                onChange={(value) =>
                                  setFieldValue("mobile", value)
                                }
                                onBlur={() =>
                                  validateMobileNumber(values.mobile)
                                }
                                international
                                countryCallingCodeEditable={false}
                                className={`${
                                  touched.mobile && errors.mobile
                                    ? "is-invalid"
                                    : ""
                                }`}
                              />
                              <ErrorMessage
                                component={"div"}
                                name="mobile"
                                className="text-right"
                              />
                            </FormGroup>
                            <div className="login-btn">
                              <Button
                                className="default-btn"
                                type="submit"
                                disabled={forgotPassword.buttonDisable}
                              >
                                {forgotPassword.buttonDisable
                                  ? t("loading")
                                  : t("submit") }
                              </Button>
                            </div>
                            <div className="user-auth-link-sec">
                              <ul className="list-unstyled user-auth-link">
                                <li>
                                  <Link to="/">{t('sign_in')}</Link>
                                </li>
                                <li>
                                  <Link to="/register">{t('sign_up')}</Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </FORM>
                      )}
                    </Formik>
                  )}
                </Col>
              </Row>
            </div>
          </div>
          <Footer />
        </div>
      </div>
      {passwordVerification ? (
        <ForgetPasswordVerificationModal
          passwordVerification={true}
          forgotPasswordData={forgotPasswordData}
          closePasswordVerificationModal={closePasswordVerificationModal}
        />
      ) : null}
    </div>
  );
};

export default ForgotPasswordIndex;
