import { createSlice } from "@reduxjs/toolkit";
import {
  getCommonInitialState,
  getCommonStart,
  getCommonSuccess,
  getCommonFailure,
} from "../utils";

const initialState = {
  verifyMobile: getCommonInitialState(),
  regenerateMobileVerification: getCommonInitialState(),
  onboardingUpdate: getCommonInitialState(),
  usernameValidation: getCommonInitialState(),
  onboardingCategories: getCommonInitialState(),
  updateOnboardingCategories: getCommonInitialState(),
  sendEmailInvitation: getCommonInitialState(),
};

const OnBoardingSlice = createSlice({
  name: "onboard",
  initialState,
  reducers: {
    //Verify Mobile
    verifyMobileStart: (state) => {
      state.verifyMobile = getCommonStart();
    },
    verifyMobileSuccess: (state, action) => {
      state.verifyMobile = getCommonSuccess(action.payload);
    },
    verifyMobileFailure: (state, action) => {
      state.verifyMobile = getCommonFailure(action.payload);
    },

    //Regenarate verification
    regenerateMobileVerificationStart: (state) => {
      state.regenerateMobileVerification = getCommonStart();
    },
    regenerateMobileVerificationSuccess: (state, action) => {
      state.regenerateMobileVerification = getCommonSuccess(action.payload);
    },
    regenerateMobileVerificationFailure: (state, action) => {
      state.regenerateMobileVerification = getCommonFailure(action.payload);
    },

    //OnBoarding update
    onboardingUpdateStart: (state) => {
      state.onboardingUpdate = getCommonStart();
    },
    onboardingUpdateSuccess: (state, action) => {
      state.onboardingUpdate = getCommonSuccess(action.payload);
    },
    onboardingUpdateFailure: (state, action) => {
      state.onboardingUpdate = getCommonFailure(action.payload);
    },

    //Validation Input
    usernameValidationStart: (state) => {
      state.usernameValidation = getCommonStart();
    },
    usernameValidationSuccess: (state, action) => {
      state.usernameValidation = {
        data: {},
        loading: false,
        error: false,
        buttonDisable: false,
        isValid: true,
        isInValid: false,
      };
    },
    usernameValidationFailure: (state, action) => {
      state.usernameValidation = {
        data: {},
        loading: false,
        error: action.payload.error,
        buttonDisable: false,
        isInValid: true,
        isValid: false,
      };
    },

    //Onboarding Categories
    onboardingCategoriesStart: (state) => {
      state.onboardingCategories = getCommonStart();
    },
    onboardingCategoriesSuccess: (state, action) => {
      state.onboardingCategories = getCommonSuccess(action.payload);
    },
    onboardingCategoriesFailure: (state, action) => {
      state.vonboardingCategories = getCommonFailure(action.payload);
    },

    //Update Onboarding Categories
    updateOnboardingCategoriesStart: (state) => {
      state.updateOnboardingCategories = getCommonStart();
    },
    updateOnboardingCategoriesSuccess: (state, action) => {
      state.updateOnboardingCategories = getCommonSuccess(action.payload);
    },
    updateOnboardingCategoriesFailure: (state, action) => {
      state.updateOnboardingCategories = getCommonFailure(action.payload);
    },

    //Send Email Invitation
    sendEmailInvitationStart: (state) => {
      state.sendEmailInvitation = getCommonStart();
    },
    sendEmailInvitationSuccess: (state, action) => {
      state.sendEmailInvitation = getCommonSuccess(action.payload);
    },
    sendEmailInvitationFailure: (state, action) => {
      state.sendEmailInvitation = getCommonFailure(action.payload);
    },
  },
});

export const {
  verifyMobileStart,
  verifyMobileSuccess,
  verifyMobileFailure,
  regenerateMobileVerificationStart,
  regenerateMobileVerificationSuccess,
  regenerateMobileVerificationFailure,
  onboardingUpdateStart,
  onboardingUpdateSuccess,
  onboardingUpdateFailure,
  usernameValidationStart,
  usernameValidationSuccess,
  usernameValidationFailure,
  onboardingCategoriesStart,
  onboardingCategoriesSuccess,
  onboardingCategoriesFailure,
  updateOnboardingCategoriesStart,
  updateOnboardingCategoriesSuccess,
  updateOnboardingCategoriesFailure,
  sendEmailInvitationStart,
  sendEmailInvitationSuccess,
  sendEmailInvitationFailure,
} = OnBoardingSlice.actions;

export default OnBoardingSlice.reducer;
