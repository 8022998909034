import React, { useState, useEffect } from "react";
import { Row, Col, Image, Container, Tab, Nav, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const OtherProfileNFTStoreFront = (props) => {


  return (
    <>
      <div className="other-profile-nft-store-font-sec">
        <div className="other-profile-nft-store-font-box">
          <Tab.Container id="left-tabs-example" defaultActiveKey="nft-storefont">
            <Row>
              <Col sm={12}>
                <div className="other-profile-nft-store-font-header-sec">
                  <Nav variant="pills">
                    <Nav.Item>
                      <Nav.Link eventKey="nft-storefont">
                        <Image
                          className="other-profile-nft-store-font-icon"
                          src={
                            window.location.origin +
                            "/assets/img/other-profile/nft-storefront-icon.png"
                          }
                        />
                        NFT Storefont
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="collections">Collections</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
              </Col>
              <Col sm={12}>
                <Tab.Content>
                  <Tab.Pane eventKey="nft-storefont">
                    <div className="other-profile-nft-store-font-body-sec">
                      <Row className="justify-content-md-center">
                        <Col md={10} lg={8} xl={11}>
                          <div className="other-profile-nft-store-font-card">
                            <div className="other-profile-nft-store-font-img-sec">
                              <Image
                                className="other-profile-nft-store-font-img"
                                src={
                                  window.location.origin +
                                  "/assets/img/other-profile/nft-storefront-img.png"
                                }
                              />
                            </div>
                            <div className="other-profile-nft-store-font-info">
                              <div className="other-profile-nft-store-font-info-top-sec">
                                <h3>Summer Time Happiness</h3>
                                <ul className="list-unstyled other-profile-nft-store-font-info-list">
                                  <li>
                                    <Image
                                      className="list-unstyled other-profile-nft-store-font-info-list-icon"
                                      src={
                                        window.location.origin +
                                        "/assets/img/other-profile/life-style.svg"
                                      }
                                    />
                                    <span>Lifestyle</span>
                                  </li>
                                  <li>
                                    <Image
                                      className="list-unstyled other-profile-nft-store-font-info-list-icon"
                                      src={
                                        window.location.origin +
                                        "/assets/img/other-profile/limited-edition.svg"
                                      }
                                    />
                                    <span>Limited Edition</span>
                                  </li>
                                </ul>
                                <p>Aug 17, 2022, 6:41 PM</p>
                              </div>
                              <div className="other-profile-nft-store-font-info-bottom-sec">
                                <Button className="buy-token">
                                  BUY NOW
                                </Button>
                                <div className="other-profile-nft-store-font-info-bottom-right-sec">
                                  <div className="other-profile-nft-store-font-info-bottom-amount-sec">
                                    <span>0,356$</span>
                                    300
                                    <Image
                                      className="other-profile-nft-store-font-info-bottom-amount-icon"
                                      src={
                                        window.location.origin +
                                        "/assets/img/other-profile/token-icon.svg"
                                      }
                                    />
                                  </div>
                                  <div className="other-profile-nft-store-font-good-deal-sec">
                                    <Image
                                      className="other-profile-nft-store-font-good-deal-icon"
                                      src={
                                        window.location.origin +
                                        "/assets/img/other-profile/good-deal-icon.svg"
                                      }
                                    />
                                    <span>Good Deal</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="other-profile-nft-store-font-latest-mint-pagination-sec">
                            <div className="other-profile-nft-store-font-latest-mint-pagination-left-sec">
                                  <h4>Latest Mints</h4>
                            </div>
                            <div className="other-profile-nft-store-font-latest-mint-pagination-right-sec">
                                <ul className="other-profile-nft-store-font-latest-mint-pagination-list list-unstyled">
                                  <li>
                                    <Link to="#" className="active">
                                        1
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="#">
                                        2
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="#">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        className="feather feather-chevron-right"
                                        viewBox="0 0 24 24"
                                      >
                                        <path d="M9 18L15 12 9 6"></path>
                                      </svg>
                                    </Link>
                                  </li>
                                </ul>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="collections">
                  <div className="other-profile-nft-store-font-body-sec">
                      <Row className="justify-content-md-center">
                        <Col md={10} lg={8} xl={11}>
                          <div className="other-profile-nft-store-font-card">
                            <div className="other-profile-nft-store-font-img-sec">
                              <Image
                                className="other-profile-nft-store-font-img"
                                src={
                                  window.location.origin +
                                  "/assets/img/other-profile/nft-storefront-img.png"
                                }
                              />
                            </div>
                            <div className="other-profile-nft-store-font-info">
                              <div className="other-profile-nft-store-font-info-top-sec">
                                <h3>Summer Time Happiness</h3>
                                <ul className="list-unstyled other-profile-nft-store-font-info-list">
                                  <li>
                                    <Image
                                      className="list-unstyled other-profile-nft-store-font-info-list-icon"
                                      src={
                                        window.location.origin +
                                        "/assets/img/other-profile/life-style.svg"
                                      }
                                    />
                                    <span>Lifestyle</span>
                                  </li>
                                  <li>
                                    <Image
                                      className="list-unstyled other-profile-nft-store-font-info-list-icon"
                                      src={
                                        window.location.origin +
                                        "/assets/img/other-profile/limited-edition.svg"
                                      }
                                    />
                                    <span>Limited Edition</span>
                                  </li>
                                </ul>
                                <p>Aug 17, 2022, 6:41 PM</p>
                              </div>
                              <div className="other-profile-nft-store-font-info-bottom-sec">
                                <Button className="buy-token">
                                  BUY NOW
                                </Button>
                                <div className="other-profile-nft-store-font-info-bottom-right-sec">
                                  <div className="other-profile-nft-store-font-info-bottom-amount-sec">
                                    <span>0,356$</span>
                                    300
                                    <Image
                                      className="other-profile-nft-store-font-info-bottom-amount-icon"
                                      src={
                                        window.location.origin +
                                        "/assets/img/other-profile/token-icon.svg"
                                      }
                                    />
                                  </div>
                                  <div className="other-profile-nft-store-font-good-deal-sec">
                                    <Image
                                      className="other-profile-nft-store-font-good-deal-icon"
                                      src={
                                        window.location.origin +
                                        "/assets/img/other-profile/good-deal-icon.svg"
                                      }
                                    />
                                    <span>Good Deal</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="other-profile-nft-store-font-latest-mint-pagination-sec">
                            <div className="other-profile-nft-store-font-latest-mint-pagination-left-sec">
                                  <h4>Latest Mints</h4>
                            </div>
                            <div className="other-profile-nft-store-font-latest-mint-pagination-right-sec">
                                <ul className="other-profile-nft-store-font-latest-mint-pagination-list list-unstyled">
                                  <li>
                                    <Link to="#" className="active">
                                        1
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="#">
                                        2
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="#">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        className="feather feather-chevron-right"
                                        viewBox="0 0 24 24"
                                      >
                                        <path d="M9 18L15 12 9 6"></path>
                                      </svg>
                                    </Link>
                                  </li>
                                </ul>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </div>
    </>
  )
}

export default OtherProfileNFTStoreFront;
