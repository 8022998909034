import React, { useEffect, useState } from "react";
import { Form, Image, Button, Modal } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";

const PasswordSuccessModal = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Modal
      className="modal-dialog-center password-success-modal"
      size="md"
      centered
      show={props.passwordSuccess}
    >
      <Modal.Body>
        <div className="password-success-card">
          <Image
            className="password-success-tick-icon"
            src={window.location.origin + "/assets/img/success-tick.svg"}
          />
          <h2>Success</h2>
          <p>
            Your password has been set! You can now sign in with your new
            password.
          </p>
          <Link to="#" className="ok-btn" onClick={navigate("/")}>
            Ok
          </Link>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PasswordSuccessModal;
