import React, { useEffect, useState } from "react";
import { Form, Image, Button, Modal } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import OtpInput from "react-otp-input";
import {
  forgotPasswordStart,
  verifyForgotPasswordStart,
} from "../../store/slices/UserSlice";
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Countdown from "react-countdown";

const ForgetPasswordVerificationModal = (props) => {
  const t = useTranslation("phone_verify");
  const navigate = useNavigate();
  const dispatch = useDispatch();

	const [skipRender, setSkipRender] = useState(true);
	const [otp, setOTP] = useState("");
	const [time, setTime] = useState(Date.now());
	const [isTimer, setIsTimer] = useState(true);
  const forgotPassword = useSelector(state => state.user.forgotPassword);
  const verifyForgotPassword = useSelector(state => state.user.verifyForgotPassword);

	const handleChange = (otp) => setOTP(otp);

	const verifyMobileCode = e => {
		e.preventDefault();
		dispatch(verifyForgotPasswordStart({
			[props.forgotPasswordData.attribute]: props.forgotPasswordData.value,
			verification_code: otp,
		}));
	}

	useEffect(() => {
		if (!skipRender && !verifyForgotPassword.loading && Object.keys(verifyForgotPassword.data).length > 0) {
      console.log("verifyForgotPassword", verifyForgotPassword)
			localStorage.setItem("reset_token", verifyForgotPassword.data.token)
			navigate("/reset-password");
		}
		setSkipRender(false);
	}, [verifyForgotPassword]);

	const renderer = ({ hours, minutes, seconds, completed }) => {
		if (completed) {
			setIsTimer(false);
			return <></>;
		} else {
			return (
				<div>
					Resend code in <span style={{ color: "#008fff" }}>{seconds}</span> s
				</div>
			);
		}
	};

	const handleForgotPassword = () => {
    dispatch(forgotPasswordStart({
			[props.forgotPasswordData.attribute]: props.forgotPasswordData.value,
		}));
  };

  return (
    <Modal
      className="modal-dialog-center phone-number-verification"
      size="md"
      centered
      show={true}
      onHide={props.closeNumberVerificationModal}
    >
      <Modal.Body>
        <h2>{t("your_verification_code")}</h2>
        <p>{t("sent_para")}</p>

        <Button
          className="modal-close"
          onClick={() => props.closePhoneVerificationModal()}
        >
          <Image
            className="close-icon"
            src={window.location.origin + "/assets/img/modal-close.svg"}
          />
        </Button>
        <div className="phone-number-verification-sec">
          <Form onSubmit={verifyMobileCode}>
            <div className="verification-item">
              <h4>{t("enter_your_code here")}</h4>
              <OtpInput
                value={otp}
                onChange={handleChange}
                numInputs={6}
                separator={<span>-</span>}
                renderInput={(props) => <input {...props} />}
              />
            </div>
            <div className="verification-btn-sec">
              <span>{t("havent_received_the_code")}</span>
              {isTimer ? (
									<Countdown date={time + 60000} renderer={renderer} />
								) : (
              <Link
                to="#"
                onClick={e => {
                  if (forgotPassword.buttonDisable)
                    e.preventDefault()
                  else {
                    handleForgotPassword()
                    setTime(Date.now());
                    setIsTimer(true);
                  }
                }
                }
                className="resend-link"
              >
                {forgotPassword.buttonDisable ? "Loading" : "Resend"}
              </Link>
                )}
            </div>
            <div className="verify-btn-sec">
              <Button
                type="submit"
                className="default-btn"
                disabled={!otp || otp.length < 6 || verifyForgotPassword.buttonDisable}
              >
                {verifyForgotPassword.buttonDisable ? t("loading") : t("verify")}
              </Button>
            </div>
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ForgetPasswordVerificationModal;
