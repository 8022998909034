import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { setTranslations, setLanguage } from "react-multi-lang";
import {Helmet} from "react-helmet";
import en from "../translations/en.json";
import es from "../translations/es.json";
// Layouts
import { MainLayout, EmptyLayout } from "../Layout";
// Pages
import Page404 from "../Helper/Page404";
import LoginIndex from "../Auth/LoginIndex";
import LandingPage from "../LandingPage/LandingPage";
import RegisterIndex from "../Auth/RegisterIndex";
import ForgotPasswordIndex from "../Auth/ForgotPasswordIndex";
import ResetPasswordIndex from "../Auth/ResetPasswordIndex";
import ViewPrivateKey from "../OnBoarding/ViewPrivateKey";
import OnBoardingIndex from "../OnBoarding/OnBoardingIndex";
import AccountCompletion from "../OnBoarding/AccountCompletion";
import ProfileIndex from "../Profile/ProfileIndex";
import AccountIndex from "../Account/AccountIndex";
import AccountSingleProfileIndex from "../Account/AccountSingleProfileIndex";
import AuthLogin from "../Auth/AuthLogin";
import Logout from "../Auth/Logout";
import AccountOnboardingIndex from "../Account/AccountOnboardingIndex";
import OtherProfileIndex from "../Profile/OtherProfileIndex";
import AddLinkIndex from "../Profile/AddLinkIndex";
import PageLoaderIndex from "../PageLoader/PageLoaderIndex";

setTranslations({ en, es });

const AppRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <Layout {...rest}>
    <Component />
  </Layout>
);

const PrivateRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  ...rest
}) =>
  localStorage.getItem("userId") && localStorage.getItem("accessToken") ? (
    <Layout screenProps={ScreenProps} {...rest}>
      <Component authRoute={true} />
    </Layout>
  ) : (
    <Navigate to={{ pathname: "/" }} />
  );

const Base = () => {
  useEffect(() => {
    let userLanguage = localStorage.getItem("lang")
      ? localStorage.getItem("lang")
      : "en";
    localStorage.setItem("lang", userLanguage);
    setLanguage(userLanguage);
  }, []);

  return (
    <>
    <Helmet>
    <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.10.2/jquery.min.js"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/js-cookie/latest/js.cookie.min.js"></script>
    <script src="https://cdn.auth0.com/js/auth0/9.18/auth0.min.js"></script>
            </Helmet>
      <BrowserRouter>
        <Routes>
          <Route
            path={"/"}
            element={<AppRoute component={LoginIndex} layout={EmptyLayout} />}
          />
          <Route
            path={"/register"}
            element={
              <AppRoute component={RegisterIndex} layout={EmptyLayout} />
            }
          />
          <Route
            path={"/forgot-password"}
            element={
              <AppRoute component={ForgotPasswordIndex} layout={EmptyLayout} />
            }
          />
          <Route
            path={"/reset-password"}
            element={
              <AppRoute component={ResetPasswordIndex} layout={EmptyLayout} />
            }
          />
          {/* <Route
            path={"/onboarding"}
            element={
              <PrivateRoute component={OnBoardingIndex} layout={EmptyLayout} />
            }
          /> */}
          <Route
            path={"/view-private-key"}
            element={
              <PrivateRoute component={ViewPrivateKey} layout={EmptyLayout} />
            }
          />
          <Route
            path={"/account-completion"}
            element={
              <PrivateRoute component={AccountCompletion} layout={EmptyLayout} />
            }
          />
          <Route
            path={"/home"}
            element={<PrivateRoute component={ProfileIndex} layout={MainLayout} />}
          />
          <Route
            path={"/account"}
            element={
              <PrivateRoute component={AccountIndex} layout={MainLayout} />
            }
          />
           <Route
            path={"/profile"}
            element={
              <PrivateRoute component={ProfileIndex} layout={MainLayout} />
            }
          />
          <Route
            path={"/auth-login"}
            element={<AppRoute component={AuthLogin} layout={EmptyLayout} />}
          />
          <Route
            path={"/account-onboarding"}
            element={
              <PrivateRoute component={AccountOnboardingIndex} layout={MainLayout} />
            }
          />
          <Route
            path={"/other-profile"}
            element={
              <PrivateRoute component={OtherProfileIndex} layout={MainLayout} />
            }
          />
          <Route
            path={"/add-link"}
            element={
              <PrivateRoute component={AddLinkIndex} layout={MainLayout} />
            }
          />
           <Route
            path={"/page-loader"}
            element={
              <PrivateRoute component={PageLoaderIndex} layout={MainLayout} />
            }
          />
          <Route
          path={"/logout"}
          element={<AppRoute component={Logout} layout={EmptyLayout} />}
        />
          <Route
            path={"/*"}
            element={<AppRoute component={Page404} layout={EmptyLayout} />}
          />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default Base;
