import React, { useState, useEffect } from "react";
import { Row, Col, Image, Container, Dropdown, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import OnBoardingHeader from "../OnBoarding/OnBoardingHeader";
import configuration from "react-global-configuration";
import ProfileCard from "./ProfileCard";
import OtherProfileNFTStoreFront from "./OtherProfileNFTStoreFront";
import OtherProfileHashtags from "./OtherProfileHashtags";
import OtherProfileMentions from "./OtherProfileMentions";
import ProfileReviewTestimonials from "./ProfileReviewTestimonials";
import ProfileLinkIndex from "./ProfileLinkIndex";
import ProfilePostCard from "./ProfilePostCard";

const ProfileIndex = (props) => {
  return (
    <>
      <div className="other-profile-box">
        <Container>
          <Row>
            <Col md={12} xl={5} lg={12}>
              <ProfileCard />
              <OtherProfileNFTStoreFront />
              <OtherProfileHashtags />
              <OtherProfileMentions />
              <ProfileReviewTestimonials />
            </Col>
            <Col md={12} xl={7} lg={12}>
              <ProfileLinkIndex userId={localStorage.getItem("userId")} />
              <ProfilePostCard />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ProfileIndex;
