import React, { useEffect, useState, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Image,
  Button,
  InputGroup,
  FormGroup,
} from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import PhoneVerificationModal from "./PhoneVerificationModal";
import Footer from "../Layout/Footer/Footer";
import { getErrorNotificationMessage } from "../../components/Helper/NotificationMessage";
const auth0 = window.auth0;

const LoginIndex = () => {
  const t = useTranslation("login");
  const location = useLocation();
  const navigate = useNavigate();
  const login = useSelector((state) => state.user.login);
  const register = useSelector((state) => state.user.register);
  const [showPassword, setShowPassword] = useState(false);
  const [skipRender, setSkipRender] = useState(true);
  const [twoStepAuth, setTwoStepAuth] = useState(null);
  const [numberVerification, setNumberVerification] = useState(null);
  const [loginBtn, setLoginBtn] = useState({
    loading: false,
    content: "",
  });

  const options = {
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
    redirectUri: window.location.origin + "/auth-login",
    responseType: "token",
  };

  const formRef = useRef(null);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email(t("email.invalid")).required(t("email.required")),
    password: Yup.string()
      .required(t("password.required"))
      .matches(/^(?=.*[a-zA-Z0-9])(?=.{6,})/, t("password.invalid")),
  });

  const closePhoneVerificationModal = () => {
    setNumberVerification(null);
  };

  const handleLogin = async (values) => {
    setLoginBtn({
      ...loginBtn,
      loading: true,
    });

    const webAuth = await new auth0.WebAuth(options);
    const state = randomString(10);
    const nonce = randomString(10);
    localStorage.setItem("state", state);
    localStorage.setItem("nonce", nonce);
    webAuth.login(
      {
        responseType: "token",
        username: values.email,
        password: values.password,
        state: state,
        nonce: nonce,
        realm: "Username-Password-Authentication",
        onRedirecting: function (done) {
          console.log("On redirecting..");
          done();
        },
      },
      function (err) {
        setLoginBtn({
          ...loginBtn,
          loading: false,
        });
        console.error(err);
        getErrorNotificationMessage(
          err.error_description ?? "Something went wrong"
        );
      }
    );
  };

  // useEffect(() => {
  //   if (!skipRender && !login.loading && Object.keys(login.data).length > 0) {
  //     formRef.current.resetForm();
  //     if (login.data.onboarding_step == 1) {
  //       setNumberVerification(login.data.mobile);
  //     } else if (login.data.is_two_step_auth_enabled == 1) {
  //       //TwoStep Auth Modal
  //       setTwoStepAuth(login.data.mobile);
  //     } else if (login.data.onboarding_step < 6) {
  //       navigate("/onboarding");
  //     } else {
  //       navigate("/profile");
  //     }
  //   }
  //   setSkipRender(false);
  // }, [login]);

  const handleSocialLogin = (type) => {
    const webAuth = new auth0.WebAuth(options);
    localStorage.setItem("state", "");
    localStorage.setItem("nonce", "");
    var config = { connection: type };
    console.log("config", config);

    webAuth.authorize({
      connection: type,
    });
  };

  // useEffect(() => {
  //   if (
  //     !skipRender &&
  //     !register.loading &&
  //     Object.keys(register.data).length > 0
  //   ) {
  //     formRef.current.resetForm();
  //     if (
  //       register.data.data.user.onboarding_step == 2 &&
  //       register.data.data.private_passphrase
  //     ) {
  //       navigate("/view-private-key");
  //     } else if (register.data.data.user.onboarding_step < 6) {
  //       navigate("/onboarding");
  //     } else {
  //       navigate("/profile");
  //     }
  //   }
  //   setSkipRender(false);
  // }, [register]);

  const randomString = (length) => {
    var charset =
      "0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz+/";
    let result = "";

    while (length > 0) {
      var bytes = new Uint8Array(16);
      var random = window.crypto.getRandomValues(bytes);

      random.forEach(function (c) {
        if (length == 0) {
          return;
        }
        if (c < charset.length) {
          result += charset[c];
          length--;
        }
      });
    }
    return result;
  };

  return (
    <div className="auth-sec">
      <div className="auth-left-sec">
        <div className="auth-left-img-sec">
          <Image
            className="auth-left-img"
            src={window.location.origin + "/assets/img/auth/login-left-img.png"}
          />
        </div>
      </div>
      <div className="auth-right-sec">
        <div className="auth-right-box">
          <div className="auth-right-card">
            <div className="auth-form-sec">
              <Row className="justify-content-md-center">
                <Col md={12} xl={8} lg={10}>
                  <div className="auth-logo-img-sec">
                    <Image
                      className="auth-logo-img"
                      src={window.location.origin + "/assets/img/auth/logo.svg"}
                    />
                  </div>
                  <div className="login-nav-link">
                    <ul>
                      <li className={location.pathname === "/" ? "active" : ""}>
                        <Link to="/">{t("sign_in")}</Link>
                      </li>
                      <li
                        className={
                          location.pathname === "/register" ? "active" : ""
                        }
                      >
                        <Link to="/register">{t("sign_up")}</Link>
                      </li>
                    </ul>
                  </div>
                  <Formik
                    initialValues={{
                      email: "",
                      password: "",
                      remember: "",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values) => handleLogin(values)}
                    innerRef={formRef}
                  >
                    {({ errors, values, touched, setFieldValue }) => (
                      <FORM>
                        <div className="sign-in-sec mt-4">
                          <FormGroup className="mb-4">
                            <Form.Label>{t("email.label")} *</Form.Label>
                            <InputGroup
                              className={`${
                                touched.email && errors.email
                                  ? "is-invalid"
                                  : ""
                              }`}
                            >
                              <InputGroup.Text>
                                <Image
                                  src={
                                    window.location.origin +
                                    "/assets/img/auth/email.svg"
                                  }
                                />
                              </InputGroup.Text>
                              <Field
                                className={`form-control ${
                                  touched.email && errors.email
                                    ? "is-invalid"
                                    : ""
                                }`}
                                type="text"
                                name="email"
                                placeholder={t("email.placeholder")}
                                // autoFocus={true}
                              />
                            </InputGroup>
                            <ErrorMessage
                              component={"div"}
                              name="email"
                              className="text-right"
                            />
                          </FormGroup>
                          <FormGroup className="mb-4">
                            <div className="Forgot-password-link">
                              <Form.Label>{t("password.label")} *</Form.Label>
                              <Link
                                className="text-uppercase"
                                to="/forgot-password"
                              >
                                {t("forgot_password")}
                              </Link>
                            </div>
                            <InputGroup
                              className={`${
                                touched.password && errors.password
                                  ? "is-invalid"
                                  : ""
                              }`}
                            >
                              <InputGroup.Text>
                                <Image
                                  src={
                                    window.location.origin +
                                    "/assets/img/auth/lock.svg"
                                  }
                                />
                              </InputGroup.Text>
                              <Field
                                className={`form-control ${
                                  touched.password && errors.password
                                    ? "is-invalid"
                                    : ""
                                }`}
                                type={showPassword ? "text" : "password"}
                                placeholder={t("password.placeholder")}
                                name="password"
                              />
                              <InputGroup.Text
                                onClick={() => setShowPassword(!showPassword)}
                              >
                                <Image
                                  src={
                                    window.location.origin +
                                    "/assets/img/auth/eye.svg"
                                  }
                                />
                              </InputGroup.Text>
                            </InputGroup>
                            <ErrorMessage
                              component={"div"}
                              name="password"
                              className="text-right"
                            />
                          </FormGroup>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicCheckbox"
                          >
                            <Form.Check
                              type="checkbox"
                              checked={values.remember}
                              onChange={() =>
                                setFieldValue("remember", !values.remember)
                              }
                              label={t("remember_me")}
                            />
                          </Form.Group>
                          <div className="login-btn">
                            <Button
                              className="default-btn"
                              type="submit"
                              disabled={loginBtn.loading}
                            >
                              {loginBtn.loading
                                ? t("login_btn.loading")
                                : t("login_btn.text")}
                            </Button>
                          </div>
                        </div>
                      </FORM>
                    )}
                  </Formik>
                  <div className="sign-up-link">
                    <h4>
                      {t("dont_have_an_account_yet")}
                      <Link to="/register">{t("sign_up")}</Link>
                    </h4>
                  </div>
                  <div className="separtor-or-sec">
                    <span>{t("or")}</span>
                  </div>
                  <ul className="list-unstyled auth-social-link-sec">
                    <li onClick={() => handleSocialLogin('google-oauth2')}>
                      <Image
                        src={
                          window.location.origin + "/assets/img/auth/google.svg"
                        }
                      />
                    </li>
                    <li onClick={() => handleSocialLogin('facebook')}>
                      <Image
                        src={
                          window.location.origin + "/assets/img/auth/facebook.svg"
                        }
                      />
                    </li>
                    <li onClick={() => handleSocialLogin('twitter')}>
                      <Image
                        src={
                          window.location.origin + "/assets/img/auth/twitter.svg"
                        }
                      />
                    </li>
                  </ul>
                </Col>
              </Row>
            </div>
          </div>
          <Footer />
        </div>
      </div>
      {numberVerification ? (
        <PhoneVerificationModal
          mobile={numberVerification}
          closePhoneVerificationModal={closePhoneVerificationModal}
        />
      ) : null}
    </div>
  );
};

export default LoginIndex;
