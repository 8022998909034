import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Image,
  Container,
  Button,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { getSuccessNotificationMessage } from "../Helper/NotificationMessage";
import CopyToClipboard from "react-copy-to-clipboard";

const AccountCompletion = (props) => {
  const navigate = useNavigate();
  const [key, setKey] = useState("");

  useEffect(() => {
    // if (
    //   (Object.keys(props.verifyMobile.data).length > 0 &&
    //     props.verifyMobile.data.private_passphrase) ||
    //   (Object.keys(props.registerInputData.data).length > 0 &&
    //     props.registerInputData.data.data.private_passphrase)
    // ) {
    //   setKey(
    //     Object.keys(props.verifyMobile.data).length > 0
    //       ? props.verifyMobile.data.private_passphrase
    //       : props.registerInputData.data.data.private_passphrase
    //   );
    // } else {
    //   navigate("/");
    // }
  }, []);

  const onCopy = () => {
    getSuccessNotificationMessage("Private key copied");
  };

  const next = () => {
    navigate("/onboarding");
  };

  return (
    <>
      <div className="authentication-sec">
        <Container>
          <div className="autentication-box">
            <Row className="justify-content-center">
              <Col md={6}>
                <div className="account-completion-sec">
                  <div className="sign-in-sec mt-4">
                    <div className="account-completion-img-sec">
                      <Image
                        className="account-completion-img"
                        src={
                            window.location.origin + "/assets/img/account-completion-img.png"
                        }
                      />
                    </div>
                    <div className="view-privte-key-sec">
                      <h3>Congratulations!</h3>

                      <h4>
                        You are now a part of
                        <div className="auth-logo-img-sec">
                            <Image
                            className="auth-logo-img"
                            src={
                                window.location.origin + "/assets/img/auth/logo.svg"
                            }
                            />
                        </div>
                      </h4>
                      <h5>
                        We need more information about you to customize your experience
                      </h5>
                      <p className="private-key-warning">
                        <span>Warning: </span> Never disclose this key. Anyone with your private keys can steal any assets held in your account. Kindly store it in a secure location.
                      </p>
                    </div>
                    <div className="private-key-content">
                      <h5>Copy your key:</h5>
                      <div className="private-key-box">
                        <p>{key}</p>
                        <div className="private-key-btn">
                          <CopyToClipboard text={key} onCopy={() => onCopy()}>
                            <Button>
                              <Image
                                src={
                                  window.location.origin +
                                  "/assets/img/copy-icon.svg"
                                }
                              />
                              <span>Copy</span>
                            </Button>
                          </CopyToClipboard>
                        </div>
                      </div>
                    </div>

                    <div className="login-btn mt-4">
                      <Button className="default-btn" onClick={() => next()}>
                        Let’s Start
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
   )
}

export default AccountCompletion;
